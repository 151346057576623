import React from 'react';
import { IConstructionReview, TConstruction, TConstructionMiningOrders } from '../../../../redux/types/construction.type';
import { useAppSelector } from '../../../../hooks/redux-hooks';
import LoadingSpinner from '../../../../components/widgets/spinner';
import { useGetConstructionQuery } from '../../../../redux/slices/construction_slice';
import { ErrorWidget } from '../../../utils/error_widget';
import BreadCrumbs from '../../../widgets/bread_crumbs';
import editIcon from "../../../../assets/img/icons/edit-5.svg";
import moment from 'moment';
import { formatPrice } from '../../../utils/format_price';
import { renderStars } from '../../../utils/render_star';
import EditProductStatus from '../mining/edit_product_status';


const AdminConstMineProductDetails: React.FC = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const product_id = queryParams.get("product_id");
    const [productItem, setProductItem] = React.useState<TConstruction | any>();
    const [show, setShow] = React.useState(false);
     const handleClose = () => {
        setShow(false);
    };
    
    const handleShow = (mineral: TConstruction) => {
        setProductItem(mineral);
        setShow(true);
    };

     

    const {
      data: product,
      isLoading: isFetching,
      error,
    }: TConstruction | any = useGetConstructionQuery(Number(product_id));
  
    if (isFetching) {
      return (
        <div style={{ marginTop: "20%" }} className="text-center">
          <LoadingSpinner />
        </div>
      );
    }
    if (error) {
      return (
        <ErrorWidget error={error}/>
      );
    }
  return (
    <>
    <EditProductStatus
        product={productItem}
        show={show}
        setShow={setShow}
        handleClose={handleClose}
      />
 
    <div className="content">
    <BreadCrumbs
        title={"Product Details"}
        subTitle={"View/Manage Product Details"}
        button={
        <button onClick={()=> handleShow(product)} className="btn btn-sm btn-info text-white">
            {product?.status} <i className="fa fa-edit"></i>
        </button>
        
          }
      />
      {/* Display Average Rating */}
      <div style={{ marginBottom: '20px' }}>
        <h3>Average Rating: {product.rating_avg}
        </h3>
        <div>
          {renderStars(product.rating_avg, 40)}
        </div>
      </div>
        <div className="row g-0">
          {/* Product Image */}
          <div className="col-md-4">
            <img
              src={`${process.env.REACT_APP_API_URL}${product.thumbnail}`}
              alt={product.productName}
              className="img-fluid rounded"
              style={{ height: '100%', objectFit: 'cover' }}
            />
          </div>

          {/* Product Details */}
          <div className="col-md-8">
            <div className="card-body">
              <h1 className="card-title display-10 text-capitalize"><b>{product.productName}</b></h1>
              <p className="card-text text-muted">{product.description}</p>

              <div className="row mt-4">
                <div className="col-md-6">
                  <p className="mb-2">
                    <strong>Price:</strong> {formatPrice({currency: product.supplier?.company_currency.currency.currency_code, price: product.productPrice})}
                  </p>
                  <p className="mb-2">
                    <strong>Unit:</strong> {product.productUnit}
                  </p>
                  <p className="mb-2">
                    <strong>Category:</strong> {product.category}
                  </p>
                  <p className="mb-2">
                    <strong>Quantity:</strong> {product.quantity}
                  </p>
                  <p className="mb-2">
                    <strong>Frequency:</strong> {product.frequency}
                  </p>
                </div>
                <div className="col-md-6">
                <div className="row">
                    <div className="col-md-6">
                    <p className="mb-2">
                    <strong>Status:</strong>{" "}
                    <span className={`badge ${product.quantity > 1 ? 'bg-success' : 'bg-warning'}`}>
                      {product.status}
                    </span>
                    
                  </p>
                    </div>
                    <div className="col-md-6">
                    <a
                        className="mt-5 ml-4 text-warning p-2 rounded"
                        onClick={() => handleShow(product)}
                    >
                        <img width={30} height={30} src={editIcon} alt="img" />
                    </a>
                    </div>
                  </div>
                  <p className="mb-2">
                    <strong>Rating:</strong>{" "}
                    <span className="text-warning">
                      {product.rating_avg} <i className='fa fa-star'></i>
                    </span>
                  </p>
                  <p className="mb-2">
                    <strong>Product Label:</strong> {product.productLabel}
                  </p>
                  <p className="mb-2">
                    <strong>Product Type:</strong> {product.product_type}
                  </p>
                  <p className="mb-2">
                    <strong>Created At:</strong> {moment(product.created_at).format("YYYY-MM-DD HH:mm a")}
                  </p>
                  <p className="mb-2">
                    <strong>Product ID:</strong> {product.product_unique_id}
                  </p>
                </div>
              </div>

              
            </div>
          </div>
        </div>
        {/* Order Products Section */}
        <div className="mt-4">
                <h3 className="h5"><b>Orders</b></h3>
                {product.construction_product_order.length > 0 ? (
                      <table className="table datanew">
                      <thead>
                        <tr>
                          <th>
                            #
                          </th>
                          <th>Customer Name</th>
                          <th>Reference</th>
                          <th>Date</th>
                          <th>Quantity</th>
                          <th>Paid</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        
                        {product.construction_product_order.map((item: TConstructionMiningOrders, index: number)=>{
                          return <tr key={index}>
                          <td>
                            {index+1}
                          </td>
                          <td className="text-bolds text-capitalize">{item.customer.first_name} {item.customer.last_name}</td>
                          <td>{item.order_unique_id}</td>
                          <td>{moment(item.created_at).format("Do MMM, YYYY")}</td>
                          <td>{item.quantity}</td>
                          <td>{formatPrice({currency: product.supplier?.company_currency.currency.currency_code, price: item.productPrice})}</td>
                          <td>
                            <span className="badges bg-lightgreen text-capitalize">{item.status}</span>
                          </td>
                        </tr>
                        })}
                        
                      </tbody>
                    </table>
                    
                ) : (
                    <div className="text-center">
                    <h4 className="text-muted">No placed orders yet.</h4>
                  </div>
                )}
              </div>

              {/* Product Reviews Section */}
              <div className="mt-4">
                <h3 className="h5"><b>Product Reviews</b></h3>
                {product.construction_product_review.length > 0 ? (
                  <div className="list-group">
                    {product.construction_product_review.map((review: IConstructionReview, index: number) => (
                      <div key={index} className="list-group-item">
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            <strong>{review.user.first_name} {review.user.last_name}</strong>
                            <span className="text-warning ms-2">
                              {renderStars(review.rating, 20)} {review.rating}
                            </span>
                          </div>
                          <small className="text-muted">
                            {moment(review.created_at).format("YYYY-MM-DD HH:mm a")}
                          </small>
                        </div>
                        <p className="mt-2 mb-0">{review.review}</p>
                      </div>
                    ))}
                  </div>
                ) : (
                    <div className="text-center">
                    <h4 className="text-muted">No reviews yet.</h4>
                    </div>
                )}
              </div>
      </div>
    </>
  );
};

export default AdminConstMineProductDetails;