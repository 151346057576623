import { apiSlice } from "../services/apiSlice";
import { TAds } from "../types/ads.type";

export const agricultureAPISlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createAd: builder.mutation<TAds, Partial<TAds>>({
      query(data) {
        return {
          url: `/administrator/v1/everyday/everyday_ads/tech`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["TAds"],
      transformResponse: (result: { agri: TAds }) =>
        result.agri,
    }),
    updateAd: builder.mutation<TAds, Partial<TAds>& Pick<TAds, 'id'>>({
      query: ({ id, ...patch }) => ({
        url: `/administrator/v1/everyday/everyday_ads/tech/${id}/`,
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: ["TAds"],
      transformResponse: (response: { agri: TAds }) =>
        response.agri,
    }),
    getAd: builder.query<TAds, number>({
      query: (id) => ({
        url: `/administrator/v1/everyday/everyday_ads/tech/${id}/`,
      }),
      providesTags: ["TAds"],
    }),
    getAdsList: builder.query<TAds[], any>({
      query: ({status, searchQuery, page}) => `/administrator/v1/everyday/everyday_ads/tech/?status=${status}&search=${searchQuery}&page=${page}`,
    }),
    deleteAd: builder.mutation<TAds, number>({
      query: (id) => ({
        url: `/administrator/v1/everyday/everyday_ads/tech/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["TAds"],
    }),
  }),
});
export const {
  useCreateAdMutation,
  useDeleteAdMutation,
  useGetAdQuery,
  useGetAdsListQuery,
  useUpdateAdMutation
} = agricultureAPISlice;
