import React from "react";
import logo from "../assets/img/logo.jpg";
import { User } from "../redux/types/user";
import { profile } from "../redux/features/authSlice";
import { useRetrieveUserQuery } from "../redux/slices/authApiSlice";
import PageNotFound from "../components/page404";
import { useAppDispatch } from "../hooks/redux-hooks";

const UserRedirect: React.FC = () => {
  const dispatch = useAppDispatch();
  const {
    data: user,
    isLoading,
    isSuccess,
    error,
  }: User | any = useRetrieveUserQuery();

  if (isLoading) {
    return (
      <>
        <center>
          <div style={{ marginTop: "15%", width: "30%" }}>
            <div className="mt-2">
              <img
                src={logo}
                alt=""
                className="img-responsive"
                style={{ height: "150px", borderRadius: "50%" }}
              />
            </div>
          </div>
          <div className="mb-5 mt-3">
            <i className="fa fa-spinner fa-spin fa-4x text-danger"></i>
            <h3 className="text-primary">Redirecting....</h3>
          </div>
        </center>
      </>
    );
  }

  if (error) {
    if (error.status === 401) {
      window.location.href = "/";
    }
    return (
      <>
        <center>
          <div style={{ marginTop: "15%", width: "30%" }}>
            <div className="mt-2">
              <img
                src={logo}
                alt=""
                className="img-responsive"
                style={{ height: "150px", borderRadius: "50%" }}
              />
            </div>
            <h4 className="mt-3 mb-3">An Error occured {error.status} </h4>
          </div>
          <a href="/" className="btn btn-primary">
            Back Home
          </a>
        </center>
      </>
    );
  }
  // React.useEffect(() => {
  //   if (isSuccess && user) {
  //     dispatch(profile(user));
  //   }
  // }, [isSuccess, user]);

  if (isSuccess && user) {
    localStorage.setItem("userInfo", JSON.stringify(user));
    if (user.user_type === "CARHIRE") {
      dispatch(profile(user));
      window.location.href = `/zm/car_dashboard`;
    } else if (user.user_type === "ADMIN") {
      dispatch(profile(user));
      window.location.href = `/zm/dashboard`;
    }  else if (user.user_type === "MINERAL") {
      dispatch(profile(user));
      window.location.href = `/zm/mineral`;
    }  else if (user.user_type === "AGRO") {
      dispatch(profile(user));
      window.location.href = `/zm/agriculture`;
    }  else if (user.user_type === "INVESTMENT") {
      dispatch(profile(user));
      window.location.href = `/zm/investment`;
    }  else if (user.user_type === "REALESTATE") {
      dispatch(profile(user));
      window.location.href = `/zm/accounts/realestate`;
    }  else if (user.user_type === "CONSTRUCTION" || user.user_type === "MINING") {
      dispatch(profile(user));
      window.location.href = `/zm/dashboard/${user?.user_type}`;
    } else if (user.user_type === "FUEL") {
      dispatch(profile(user));
      window.location.href = `/zm/account/${user?.user_type}/dashboard`;
    } else if (user.user_type === "REFERAL" || user.user_type === "CUSTOMER") {
      dispatch(profile(user));
      window.location.href = `/zm/welcome`;
    } else if (user.is_staff || user.user_type === "MANAGER" || user.user_type === "SUPPORTAGENT") {
      dispatch(profile(user));
      window.location.href = `/zm/dashboard`;
    } else {
      // return <Navigate to={`/intercity_travellers_admin`} />;
      return <PageNotFound />;
    }
  }
  return (
    <center>
      <div style={{ marginTop: "15%", width: "30%" }}>
        <div className="mt-2">
          <img
            src={logo}
            alt=""
            className="img-responsive"
            style={{ height: "150px", borderRadius: "50%" }}
          />
        </div>
      </div>
      <div className="mb-5 mt-3">
        <i className="fa fa-spinner fa-spin fa-4x text-danger"></i>
        <h3 className="text-primary">Redirecting....</h3>
      </div>
    </center>
  );
};

export default UserRedirect;
