import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import plusIcon from "../../../../assets/img/icons/plus.svg";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { FormInput } from "../../../../components/molecules/form_input";
import { FormTextarea } from "../../../../components/molecules/form_textarea";
import httpDashboardServices from "../../../services/http.category.services";
import { useAppSelector } from "../../../../hooks/redux-hooks";
// import { useCreateMineralMutation } from "../../../../redux/slices/mineral_slice";
import { fuelTypes } from "../../../utils/fuel_types";

export type DataFormFields = {
  supplier: string;
  fuel_type: string;
  fuel_name: string;
  unit: string;
  fuel_price: string;
  location: string;
  thumbnail: File;
};

interface IProps{
  fetchData: () => void;
}

const AddFuel: React.FC<IProps> = ({fetchData}) => {
  const user = useAppSelector((state) => state.auth.user);
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [file, setFile] = useState<File | any>();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  // const [createMineral, { isLoading }] = useCreateMineralMutation();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<DataFormFields>();


  const onSubmit = handleSubmit(async (data) => {
    setIsLoading(true);
    await httpDashboardServices
      .createFuel({
        supplier: user?.id,
        fuel_name: data.fuel_name,
        fuel_price: data.fuel_price,
        thumbnail: file,
        location: data.location,
        unit: data.unit,
        fuel_type: data.fuel_type,
      })
      .then(() => {
        setIsLoading(false);
        reset();
        fetchData(); // refresh table data after adding a new product
        setShow(false);
        toast.success("Product Created Successfully and has been submitted to the admin for review.");
      })
      .catch((err: any) => {
        setIsLoading(false);
        console.error(err.message);
        toast.error("Failed to add:" + err.data["detail"]);
      });
    // createMineral({
    //   supplier: user?.id,
    //   fuel_name: data.fuel_name,
    //   fuel_price: data.fuel_price,
    //   image: file,
    //   location: data.location,
    //   unit: data.unit,
    //   category: data.category,
    // })
    //   .unwrap()
    //   .then((res) => {
    //     reset();
    //     setShow(false);
    //     toast.success("Product Created Successfully");
    //   })
    //   .catch((err) => {
    //     toast.error("Error: " + err.data["detail"]);
    //   });
  });

  return (
    <>
      <button onClick={handleShow} className="btn btn-added mt-3">
        <img src={plusIcon} alt="img" />
        Add New Fuel Product
      </button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        scrollable
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Fuel Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={onSubmit} encType="multipart/form-data">
            <div className="row">
              <div className="col-md-4">
                <FormInput<DataFormFields>
                  id="fuel_name"
                  type="text"
                  name="fuel_name"
                  disabled={isLoading}
                  label="Product Name"
                  placeholder="Product Name"
                  className="mb-2"
                  register={register}
                  rules={{ required: "Product Name is required." }}
                  errors={errors}
                />
              </div>
              <div className="col-md-4">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">{user?.company_currency.currency.currency_code}</span> {/* Currency sign */}
                  </div>
                  <FormInput<DataFormFields>
                    id="fuel_price"
                    type="number"
                    step="0.01"
                    name="fuel_price"
                    disabled={isLoading}
                    label="Product Price"
                    placeholder="Product Price"
                    className="mb-2 form-control"
                    register={register}
                    rules={{ required: "Product Price is required." }}
                    errors={errors}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <FormInput<DataFormFields>
                  id="productUnit"
                  type="text"
                  step="0.01"
                  name="unit"
                  disabled={isLoading}
                  label="Product Unit"
                  placeholder="Product Unit E.G litre, grams, kg etc"
                  className="mb-2"
                  register={register}
                  rules={{ required: "Product Unit is required." }}
                  errors={errors}
                />
              </div>
              <div className="col-md-6">
                <FormInput<DataFormFields>
                  id="thumbnail"
                  type="file"
                  name="thumbnail"
                  label="Image"
                  disabled={isLoading}
                  accept="image/*"
                  placeholder="Image"
                  className="mb-2"
                  register={register}
                  rules={{
                    required: "Image is required.",
                    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                      if (event.target.files && event.target.files[0]) {
                        setFile(event.target.files[0]);
                      }
                    },
                  }}
                  errors={errors}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="label">Product Category</label>
                <select
                  {...register("fuel_type", { required: true })}
                  name="fuel_type"
                  className={`select text-capitalize form-control ${
                    errors.fuel_type ? "border-danger" : ""
                  }`}
                  id="fuel_type"
                >
                  <option value="">Select Category</option>
                  {fuelTypes.map((item: any, index: number) => {
                    return (
                      <option key={index} value={item}>{item}</option>
                    );
                  })}
                </select>
                {errors.fuel_type && (
                  <p className="text-danger">Category is required.</p>
                )}
              </div>
              <div className="col-md-12">
                <FormInput<DataFormFields>
                  id="location"
                  name="location"
                  disabled={isLoading}
                  label="location"
                  placeholder="Enter location"
                  className="form-control"
                  register={register}
                  rules={{ required: "location is required." }}
                  errors={errors}
                />
              </div>
            </div>

            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button disabled={isLoading} variant="primary" type="submit">
                {isLoading && <Spinner size="sm" />}{" "}
                <i className="fa fa-plus"></i> Create
              </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddFuel;
