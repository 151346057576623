import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { FormInput } from "../../../../../components/molecules/form_input";
import { Spinner } from "react-bootstrap";
import { useUpdateUserMutation } from "../../../../../redux/slices/usersApiSlice";
import { User } from "../../../../../redux/types/user";
import { SelectInput } from "../../../../../components/molecules/selectInput";
import { SubscriptionPackage } from "../../../../../redux/types/subscription.type";
import { useGetSubscriptionListQuery } from "../../../../../redux/slices/subscription_slice";
import { TCountyCurrency } from "../../../../../redux/types/country_currency.type";
import { useGetCurrencyListQuery } from "../../../../../redux/slices/country_currency_slice";

type TProps = {
  title: string;
  accountType: string;
  user: User;
  show: boolean;
  handleClose: () => void;
  setShow: (show: boolean) => void;
};

export type DataFormFields = {
  first_name: string;
  last_name: string;
  email: string;
  company_email: string;
  nationality: string;
  company_name: string;
  phoneNumber: string;
  subscription?: number;
  account_type?: string;
  currency?: number;
  merchant_id?: string;
  commission_fee?: number;
  status: string;
};

const EditAccount: React.FC<TProps> = ({title, accountType, user, show, handleClose, setShow}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<DataFormFields>();

  const [updateUser, { isLoading }] = useUpdateUserMutation();

  const {
      data: currencyList,
      isLoading: fetchingCurrency,
      error: supplierError,
    }: TCountyCurrency | any = useGetCurrencyListQuery({status: "", searchQuery: "", page: 1});
  

  const {
      data: packageList,
      isLoading: fetchingPackages,
      error: packageError,
    }: SubscriptionPackage | any = useGetSubscriptionListQuery({
      status: "ACTIVE",
    });

    if (fetchingPackages || fetchingCurrency) {
      return (
        <div className="text-center">
          <i className="fa fa-spinner fa-spin fa-2x text-danger"></i>
        </div>
      );
    }
  
    if (packageError || supplierError) {
      return (
        <div className="alert alert-danger text-center mt-5" role="alert">
          {packageError.status}
        </div>
      );
    }

  const onSubmit = handleSubmit(async (data) => {
    updateUser({ id: user.id, ...data })
      .unwrap()
      .then(() => {
        setShow(false);
        toast.success("User account updated Successfully");
      })
      .catch((err) => {
        console.error(err.error);
        toast.error("Failed to add:" + err.error);
      });
  });
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        scrollable={true}
        centered={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={onSubmit}>
            <div className="row">
               <div className="col-md-4">
                  <SelectInput
                    id="subscription"
                    label="Subscription"
                    name="subscription"
                    defaultValue={user?.user_subscription?.subscription.id}
                    register={register}
                    rules={{required: false}}
                    errors={errors}
                  >
                    {packageList.map((item: SubscriptionPackage, index: number) =>{
                      return (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      )
                    })}
                  </SelectInput>
                </div>
                <div className="col-md-4">
                  <SelectInput
                    id="currency"
                    label="Currency"
                    name="currency"
                    register={register}
                    rules={{required: false}}
                    errors={errors}
                    defaultValue={user?.company_currency?.currency.id}
                  >
                    {currencyList.results.map((item: TCountyCurrency, index: number)=>{
                      return <option key={index} value={item.id}>{item.country} - {item.currency_code}</option>
                    })}
                  </SelectInput>
                </div>
                <div className="col-md-4">
                  <SelectInput
                    id="account_type"
                    label="Account Type"
                    name="account_type"
                    register={register}
                    rules={{required: false}}
                    errors={errors}
                    defaultValue={user?.account_type}
                  >
                    <option value="REGULAR">Regular</option>
                    <option value="SME">Small Medium Enterprise (SME)</option>
                    <option value="ENTERPRISE">Enterprise</option>
                  </SelectInput>
                </div>
                <div className="col-md-4">
                  <FormInput<DataFormFields>
                    id="company_name"
                    type="text"
                    name="company_name"
                    label="Company Name"
                    disabled={isLoading}
                    defaultValue={user?.company_name}
                    placeholder="Company Name"
                    className="mb-2"
                    register={register}
                    rules={{ required: "Company Name is required." }}
                    errors={errors}
                  />
                </div>
                <div className="col-md-4">
                  <FormInput<DataFormFields>
                    id="nationality"
                    type="text"
                    name="nationality"
                    label="Nationality"
                    disabled={isLoading}
                    defaultValue={user?.nationality}
                    placeholder="Nationality"
                    className="mb-2"
                    register={register}
                    rules={{ required: "Nationality is required." }}
                    errors={errors}
                  />
                </div>
                <div className="col-md-4">
                  <FormInput<DataFormFields>
                    id="merchant_id"
                    type="text"
                    name="merchant_id"
                    label="Mechant ID"
                    disabled={isLoading}
                    defaultValue={user?.merchant_id}
                    placeholder="Mechant ID (from flutterwave admin dashboard)"
                    className="mb-2"
                    register={register}
                    rules={{ required: "Mechant ID is required." }}
                    errors={errors}
                  />
                </div>
                <div className="col-md-4">
                  <FormInput<DataFormFields>
                    id="commission_fee"
                    type="number"
                    name="commission_fee"
                    label="Commision Fee (%)"
                    defaultValue={user?.user_subscription?.commission_fee}
                    disabled={isLoading}
                    placeholder="Commision Fee"
                    className="mb-2"
                    register={register}
                    rules={{ required: "Commision Fee is required." }}
                    errors={errors}
                  />
                </div>
                <div className="col-md-4">
                  <FormInput<DataFormFields>
                    id="first_name"
                    type="text"
                    name="first_name"
                    disabled={isLoading}
                    defaultValue={user?.first_name}
                    label="First Name"
                    placeholder="First Name"
                    className="mb-2"
                    register={register}
                    rules={{ required: "First Name is required." }}
                    errors={errors}
                  />
                </div>
                <div className="col-md-4">
                  <FormInput<DataFormFields>
                    id="last_name"
                    type="text"
                    name="last_name"
                    defaultValue={user?.last_name}
                    disabled={isLoading}
                    label="Last Name"
                    placeholder="Last Name"
                    className="mb-2"
                    register={register}
                    rules={{ required: "Last Name is required." }}
                    errors={errors}
                  />
                </div>
                <div className="col-md-6">
                  <FormInput<DataFormFields>
                    id="company_email"
                    type="text"
                    name="company_email"
                    disabled={isLoading}
                    defaultValue={user?.company_email}
                    label="Company Email"
                    placeholder="Company Email"
                    className="mb-2"
                    register={register}
                    rules={{ required: "Company Email is required." }}
                    errors={errors}
                  />
                </div>
                <div className="col-md-6">
                  <FormInput<DataFormFields>
                    id="phoneNumber"
                    type="text"
                    name="phoneNumber"
                    label="Phone Number"
                    disabled={isLoading}
                    defaultValue={user?.phoneNumber}
                    placeholder="Phone Number"
                    className="mb-2"
                    register={register}
                    rules={{ required: "Phone Number is required." }}
                    errors={errors}
                  />
                </div>
                <div className="col-md-12">
                  <SelectInput
                    id="status"
                    label="Account Status"
                    name="status"
                    register={register}
                    rules={{required: false}}
                    errors={errors}
                    defaultValue={user?.status}
                  >
                    <option value="ACTIVE">Active</option>
                    <option value="INACTIVE">Inactive</option>
                  </SelectInput>
                </div>
            </div>

            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button disabled={isLoading} variant="primary" type="submit">
                {isLoading && <Spinner size="sm" />}{" "}
                <i className="fa fa-upload"></i> Update
              </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditAccount;
