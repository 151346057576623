import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';

const RADIAN = Math.PI / 180;

interface CustomizedLabelProps {
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  percent: number;
  index: number;
}

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}: CustomizedLabelProps) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

interface IProps {
  value: number; // e.g., 10% commission value
  total: number; // total sales
  currency: any
}

const CommissionStats: React.FC<IProps> = ({ total, value, currency }) => {
  // Calculate commission percentage relative to total
  const percentage = (value / total) * 100;
  const remainingPercentage = 100 - percentage;

  // Data for the pie chart
  const data = [
    { name: 'Commission', value: percentage },
    { name: 'Remaining', value: remainingPercentage },
  ];

  // Colors for the pie chart segments
  const colors = ['#FF8042', '#00C49F'];

  return (
    <div style={{ position: 'relative', textAlign: 'center', margin: "0px" }}>
      {/* Increase height to make the pie chart bigger */}

      <ResponsiveContainer width="100%" height={250}>
        <PieChart>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={renderCustomizedLabel}
            // Increase outerRadius for a bigger pie
            outerRadius={100}
            fill="#8884d8"
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
            ))}
          </Pie>

          {/* Move these text labels up/down by adjusting the y-coordinates */}
          <text
            x="50%"
            y="20%"
            textAnchor="middle"
            dominantBaseline="central"
            style={{ fontSize: 14, fill: '#fff' }}
          >
            TOTAL SOLD
          </text>
          <text
            x="50%"
            y="26%"
            textAnchor="middle"
            dominantBaseline="central"
            style={{ fontSize: 16, fontWeight: 'bold', fill: '#fff' }}
          >
            {currency} {total.toFixed(2)}
          </text>
          <text
            x="50%"
            y="70%"
            textAnchor="middle"
            dominantBaseline="central"
            style={{ fontSize: 14, fill: '#fff' }}
          >
            TOTAL COMMISSION
          </text>
          <text
            x="50%"
            y="76%"
            textAnchor="middle"
            dominantBaseline="central"
            style={{ fontSize: 16, fontWeight: 'bold', fill: '#fff' }}
          >
           {currency} {value.toFixed(2)}
          </text>
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default CommissionStats;
