import { useState } from "react";
import { Card, Button, Modal, Container, Row, Col, Form, Spinner } from "react-bootstrap";
import { FaUpload, FaFilePdf, FaFileImage } from "react-icons/fa";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAppSelector } from "../hooks/redux-hooks";
import { toast } from "react-toastify";
import httpProductServices from "./services/http.product.services";
import { apiUrl } from "../redux/services/apiSlice";

const companyDocuments = [
  { id: 1, name: "Company Registration Certificate", required: true },
  { id: 2, name: "Tax Registration Certificate", required: true },
  { id: 3, name: "Tax Clearance", required: false },
  { id: 4, name: "Company Status Report", required: true },
];

const individualDocuments = [
  { id: 5, name: "ID Type (National ID / Passport / Driver's License)", required: true },
  { id: 6, name: "Attach ID", required: true },
  { id: 7, name: "Physical Address", required: true },
  { id: 8, name: "Proof of Address (Utility Bill or Tenancy Agreement)", required: true },
];

interface IProps{
    refetch: () => void;
}
export const DocumentCards: React.FC<IProps> = ({refetch}) => {
  const currenctUser = useAppSelector((state) => state.auth.user);
  const [viewingDoc, setViewingDoc] = useState<number | null>(null);
  const [idType, setIdType] = useState("National ID");
  const [submiting, setSubmiting] = useState(false);
  const userDocs = currenctUser?.user_documents;

  const documents = currenctUser?.account_type === 'REGULAR' ? individualDocuments.map(doc => {
    if (doc.id === 6) {
      return { ...doc, name: `Attach ${idType}` };
    }
    return doc;
  }) : companyDocuments;

  const formik = useFormik({
    initialValues: {
      files: {} as { [key: number]: File | null }, // Track files for each document
    },
    validationSchema: Yup.object({
      files: Yup.object().test(
        "required-files",
        "All required documents must be uploaded",
        (files: { [key: number]: File | null }) => {
          return documents.slice(1).every((doc) => !doc.required || files[doc.id]); // Skip the first card for validation
        }
      ),
    }),
    onSubmit: async (values) => {
      const formData = new FormData();
      const payload: Record<string, File | null> = {};

    //   const payload: Partial<TClientDocs> = {};
      documents.slice(1).forEach((doc) => { // Skip the first card when submitting
        const file = values.files[doc.id];
        if (file) {
          formData.append("files", file);
          formData.append("names", doc.name);
          payload[doc.name] = file;
        }
      });

      try {
        setSubmiting(true);
        httpProductServices.uploadDocuments(formData)
        .then((response) => {
            if (response.status === 201) {
            setSubmiting(false);
            refetch(); // Refetch the data to reflect the new documents
            toast.success("Documents uploaded Successfully and has been submitted to the admin for review.");
            }
        })
        .catch((error: any) => {
            setSubmiting(false);
            toast.error(error.message);
        });
        // createDocument(payload).unwrap()
        //     .then(() => {
        //         toast.success("Data submitted Successfully");
        //     })
        //     .catch((err) => {
        //         toast.error("Error: " + err.error);
        //         console.error("Error uploading files:", err);
        //     });
      } catch (error) {
        console.error("Error uploading files:", error);
      }
    },
  });

  const handleView = (id: number) => {
    setViewingDoc(id);
  };

  const handleFileChange = (id: number, file: File | null) => {
    formik.setFieldValue(`files[${id}]`, file);
  };

  const isAllRequiredUploaded = documents.slice(1).every( // Skip the first card for validation
    (doc) => !doc.required || formik.values.files[doc.id]
  );

  return (
    <Container className="p-1">
      <Row className="g-3">
      {documents.map((doc, index) => {
      const isUploaded = userDocs?.some((e) => e.name === doc.name);
      const uploadedDoc = userDocs?.find((e) => e.name === doc.name);

  return (
    <Col key={doc.id} xs={12} md={6}>
      <Card className="shadow-sm border rounded" style={{ width: "100%" }}>
        <Card.Body>
          <Card.Title>{doc.name}</Card.Title>

          {index === 0 && currenctUser?.account_type === "REGULAR" ? (
            // First card for selecting ID Type
            <Form.Group controlId="idType" className="mb-3">
              <Form.Label>Select ID Type</Form.Label>
              <Form.Control
                as="select"
                value={idType}
                onChange={(e) => setIdType(e.target.value)}
              >
                <option value="National ID">National ID</option>
                <option value="Passport">Passport</option>
                <option value="Driver's License">Driver's License</option>
              </Form.Control>
            </Form.Group>
          ) : (
            <>
              <div className={`text-${isUploaded ? "success" : "danger"} mb-2`}>
                {isUploaded ? "Uploaded" : "Missing"}
              </div>

              <Button
                variant={isUploaded ? "primary" : "outline-secondary"}
                onClick={() => {
                  if (isUploaded) {
                    handleView(doc.id); // Allow viewing uploaded documents
                  } else {
                    // Trigger file input for uploading
                    const fileInput = document.createElement("input");
                    fileInput.type = "file";
                    fileInput.accept = ".pdf,.jpg,.jpeg,.png";
                    fileInput.onchange = (event) => {
                      const file = (event.target as HTMLInputElement).files?.[0] || null;
                      handleFileChange(doc.id, file);
                    };
                    fileInput.click();
                  }
                }}
                className="w-100"
                disabled={!isUploaded && Boolean(formik.values.files[doc.id])} // ✅ Fix: Ensure boolean type
              >
                {isUploaded ? (
                  uploadedDoc?.doc_type === "pdf" ? <FaFilePdf /> : <FaFileImage />
                ) : (
                  <FaUpload />
                )}{" "}
                {isUploaded ? "View" : "Upload"}
              </Button>
              <Modal show={viewingDoc !== null} onHide={() => setViewingDoc(null)} size="lg">
                <Modal.Header closeButton>
                  <Modal.Title>View Document</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                  {viewingDoc !== null && uploadedDoc ? (
                    uploadedDoc.doc_type === "pdf" ? (
                      <iframe
                        src={`${apiUrl}${uploadedDoc.file}`} // Assuming this is the file URL
                        width="100%"
                        height="500px"
                        title="PDF Document"
                      />
                    ) : (
                      <img
                        src={`${apiUrl}${uploadedDoc.file}`} // Assuming this is the file URL
                        alt="Uploaded Document"
                        style={{ maxWidth: "100%", maxHeight: "500px", objectFit: "contain" }}
                      />
                    )
                  ) : (
                    <p>No document found.</p>
                  )}
                </Modal.Body>
              </Modal>

            </>
          )}
        </Card.Body>
      </Card>
    </Col>
  );
})}
      </Row>
      <Button
        variant="success"
        className="mt-3"
        onClick={() => formik.handleSubmit()}
        disabled={!isAllRequiredUploaded || submiting}
      >
       {submiting && <Spinner />} Upload All Documents
      </Button>
    </Container>
  );
};


