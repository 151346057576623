import React from 'react'
import { User } from '../../../../../redux/types/user';
import { useGetUserQuery } from '../../../../../redux/slices/usersApiSlice';
import LoadingSpinner from '../../../../../components/widgets/spinner';
import BreadCrumbs from '../../../../widgets/bread_crumbs';
import editIcon from "../../../../../assets/img/icons/edit-5.svg";
import moment from 'moment';
import EditAccount from '../crud/edit_account';
import { TClientDocs } from '../../../../../redux/types/documents.type';
import { apiUrl } from '../../../../../redux/services/apiSlice';
import { FaFilePdf } from 'react-icons/fa';

const OnboardingDetails: React.FC = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const company_id = queryParams.get("company_id");
    const [show, setShow] = React.useState(false);
    const handleClose = () => {
      setShow(false);
    };
  
    const handleShow = (user: User) => {
      setShow(true);
    };
  
    const {
      data: userDetails,
      isLoading: isFetching,
      error,
    }: User | any = useGetUserQuery(Number(company_id));
  
    if (isFetching) {
      return (
        <div style={{ marginTop: "20%" }} className="text-center">
          <LoadingSpinner />
        </div>
      );
    }
  return (
    <>
    <EditAccount
      title={"Update User Account"}
      accountType={"AGRO"}
      user={userDetails}
      show={show}
      handleClose={handleClose}
      setShow={setShow}
    />
    <div className='content'>
        <BreadCrumbs 
        title={'Company Details'} 
        subTitle={`View ${userDetails?.company_name}`} 
        button={
          <a className="ml-5" onClick={() => handleShow(userDetails)}>
            <span className='btn btn-info mb-3 text-white'>{`${userDetails?.status} Account`}</span><i className='fa fa-edit m-2 fa-3x'></i>
          </a>
        }
        />
        <div className="col-md-12">
        <div className="card">
          <div className="card-body">
            <div className="card-title">
              <h2 className='text-center text-capitalize'>
                <b>{userDetails?.company_name}</b>
              </h2>
            </div>
            <div className="row">
              <div className="col-md-6">
              <h4><b>Company Details</b></h4>
                <p className='text-capitalize'>Nationality: {userDetails?.nationality}</p>
                <p>Email: {userDetails?.company_email}</p>
                <p className='text-capitalize'>Phone: {userDetails?.phoneNumber}</p>
                <p>Date Joined: {moment(userDetails?.created_at).format("YYYY MMM Do HH:mm a")}</p>
              </div>
              {userDetails?.user_subscription !== null ?
              <div className="col-md-6">
                <h4><b>Subscription Details</b></h4>
                <p>Package: {userDetails?.user_subscription?.subscription.name}</p>
                <p>Start Date: {moment(userDetails?.user_subscription?.subscription_start_date).format('YYYY MMM Do')}</p>
                {/* <p>End Date: {moment(user?.user_subscription?.subscription_end_date).format('YYYY-MM-DD')}</p> */}
                <p>Commission Fee: {userDetails?.user_subscription?.commission_fee}%</p>
                <p>Status: {userDetails?.user_subscription?.subscription_status}</p>
              </div> : null }
            </div>
          </div>
        </div>
        <h2 className='text-center mb-4'><b>KYC DOCUMENTS</b></h2>
        <div className="row">
          {userDetails?.user_documents?.length > 0? userDetails?.user_documents.map((doc: TClientDocs, index: number) => {
            return (
              <div className="col-md-4" key={index}>
                <div className="card">
                  <div className="card-body">
                    <div className="card-title text-center">
                      <h5>{doc.name}</h5>
                    </div>
                    <div className="text-center">
                      {doc.file.endsWith(".pdf") ? <a href={`${apiUrl}${doc.file}`} target="_blank" rel="noopener noreferrer">
                        <FaFilePdf size={190} />
                      </a> : <a href={`${apiUrl}${doc.file}`} target="_blank" rel="noopener noreferrer">
                        <img src={`${apiUrl}${doc.file}`} alt="Document" style={{ width: "100%", height: "30vh" }} />
                      </a> }
                      
                      
                    </div>
                  </div>
                </div>
              </div>
            )
          }): <h1><b>NO DOCUMENTS FOUND</b></h1>}
        </div>
      </div>
    </div>
    </>
  )
}

export default OnboardingDetails