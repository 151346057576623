import React from "react";
import MessageBox from "./message_box";
import moment from "moment";
import userPic from "../../../../assets/img/icons/users1.svg";
import { TComplaint } from "../../../../redux/types/complaints.type";
import LoadingSpinner from "../../../../components/widgets/spinner";
import { useGetComplaintListQuery } from "../../../../redux/slices/complaint_slice";

const Complaints: React.FC = () => {
  const [selectedUser, setSelectedUser] = React.useState<TComplaint | null>(null);


  // Handle user click
  const handleUserClick = (user: TComplaint) => {
    setSelectedUser(user);
  };

  const {
      data: complaintList,
      isLoading: loadingUsers,
      error: usersError,
    }: TComplaint | any = useGetComplaintListQuery();

    if (loadingUsers) {
      return (
        <div style={{ marginTop: "20%" }} className="text-center">
          <LoadingSpinner />
        </div>
      );
    }

  return (
    <div className="content">
      <div className="col-lg-12">
        <div className="row chat-window">
          <div className="col-lg-5 col-xl-4 chat-cont-left">
            <div className="card mb-sm-3 mb-md-0 contacts_card flex-fill">
              <div className="card-body contacts_body chat-users-list chat-scroll">
                {complaintList.map((user: TComplaint, index: number) => (
                  <a
                    key={index}
                    href="javascript:void(0);"
                    className={`media d-flex ${selectedUser?.user.id === user.user.id ? 'active' : ''}`}
                    onClick={() => handleUserClick(user)}
                  >
                    <div className="media-img-wrap flex-shrink-0">
                      <div className="avatar avatar-away">
                        <img
                          src={userPic}
                          alt="User Image"
                          className="avatar-img rounded-circle"
                        />
                      </div>
                    </div>
                    <div className="media-body flex-grow-1">
                      <div>
                        <div className="user-name text-capitalize">{user.user.first_name} {user.user.last_name}</div>
                        <div className="user-last-chat">
                          {user.complaint_title}
                        </div>
                      </div>
                      <div>
                        <div className="last-chat-time">
                          {moment(user.created_at).fromNow()}
                        </div>
                      </div>
                    </div>
                  </a>
                ))}
              </div>
            </div>
          </div>

          {selectedUser && (
            <MessageBox
              message={selectedUser}
              selectedUser={selectedUser.user}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Complaints;