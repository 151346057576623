import React from 'react'
import { User } from '../../../../../redux/types/user'
import moment from 'moment';
import CommissionStats from './commission_stats';

interface IProps{
    user: User;
    totalSales: number;
}
const CompanySummary: React.FC<IProps> = ({user, totalSales}) => {
  // Suppose your commission_fee in the database is 10 for 10%, 5 for 5%, etc.
const commissionFee = user.user_subscription?.commission_fee ?? 0; 
// Convert it to a decimal
const commissionDecimal = commissionFee / 100;

// Now calculate the commission amount
const tenPercent: number = totalSales * commissionDecimal;
  return (
    <div className="card">
      <div className="card-body">
        <div className="card-title">
          <h2 className='text-center text-capitalize'>
            <b>{user?.company_name}</b>
          </h2>
        </div>
        <div className="row">
          <div className="col-md-4">
          <h4><b>Company Details</b></h4>
            <p className='text-capitalize'>Nationality: {user?.nationality}</p>
            <p>Email: {user?.company_email}</p>
            <p className='text-capitalize'>Phone: {user?.phoneNumber}</p>
            <p>Date Joined: {moment(user?.created_at).format("YYYY MMM Do HH:mm a")}</p>
          </div>
          {user?.user_subscription !== null ?
          <div className="col-md-4">
            <h4><b>Subscription Details</b></h4>
            <p>Package: {user?.user_subscription?.subscription.name}</p>
            <p>Start Date: {moment(user?.user_subscription?.subscription_start_date).format('YYYY MMM Do')}</p>
            {/* <p>End Date: {moment(user?.user_subscription?.subscription_end_date).format('YYYY-MM-DD')}</p> */}
            <p>Commission Fee: {user?.user_subscription?.commission_fee}%</p>
            <p>Status: {user?.user_subscription?.subscription_status}</p>
          </div> : null }
          <div className='col-md-4'>
          <CommissionStats total={totalSales} value={tenPercent} currency={user.company_currency?.currency.currency_code} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CompanySummary