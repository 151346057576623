import React from 'react';
import { useLocation } from 'react-router-dom';
import user1 from "../../../assets/img/icons/users1.svg";
import sales1 from "../../../assets/img/icons/sales1.svg";
import expense1 from "../../../assets/img/icons/excel.svg";
import product from "../../../assets/img/icons/product.svg";
import purchase1 from "../../../assets/img/icons/purchase1.svg";
import transfer1 from "../../../assets/img/icons/transfer1.svg";

interface SupportAgentLinksProps {
  assignedSystems: string[];
}

const SupportAgentLinks: React.FC<SupportAgentLinksProps> = ({ assignedSystems })=> {
  const location = useLocation();

  return (
    <>
    {/* Investments */}
    {assignedSystems.includes("INVESTMENTS") && (
        <li className="submenu">
          <a href="javascript:void(0);">
            <img src={transfer1} alt="img" />
            <span>Investment</span>
            <span className="menu-arrow"></span>
          </a>
          <ul>
            <li>
              <a className={`${location.pathname === "/zm/dashboard/investment_list" ? "active" : ""}`} href="/zm/dashboard/investment_list">Investment List</a>
            </li>
          </ul>
        </li>
      )}

      {/* People */}
      {assignedSystems.includes("ORGANIZATION") && (
        <>
        <li className="submenu">
          <a href="javascript:void(0);">
            <img src={user1} alt="img" />
            <span>People</span>
            <span className="menu-arrow"></span>
          </a>
          <ul>
            <li>
              <a className={`${location.pathname === "/zm/dashboard/customerlist" ? "active" : ""}`} href="/zm/dashboard/customerlist">Customer List</a>
            </li>
            <li>
              <a className={`${location.pathname === "/zm/dashboard/system_users" ? "active" : ""}`} href="/zm/dashboard/system_users">System Users</a>
            </li>
          </ul>
        </li>
        <li className="submenu">
          <a href="javascript:void(0);">
            <i className='fa fa-users text-muted'></i>
            <span>Organizations</span>
            <span className="menu-arrow"></span>
          </a>
          <ul>
            <li>
            <a className={`${location.pathname === "/zm/dashboard/car_organizations" ? "active" : ""}`} href="/zm/dashboard/car_organizations">Car Companies</a>
            <a className={`${location.pathname === "/zm/dashboard/mineral_companies" ? "active" : ""}`} href="/zm/dashboard/mineral_companies">Mineral Companies</a>
            <a className={`${location.pathname === "/zm/dashboard/agro_companies" ? "active" : ""}`} href="/zm/dashboard/agro_companies">Agro Companies</a>
            <a className={`${location.pathname === "/zm/dashboard/investment_companies" ? "active" : ""}`} href="/zm/dashboard/investment_companies">Investment Companies</a>
            <a className={`${location.pathname === "/zm/dashboard/real_estate_companies" ? "active" : ""}`} href="/zm/dashboard/real_estate_companies">Real-Estate Companies</a>
            <a className={`${location.pathname === "/zm/dashboard/fuel_companies" ? "active" : ""}`} href="/zm/dashboard/fuel_companies">Fuel Companies</a>
            <a className={`${location.pathname === "/zm/dashboard/mining_companies" ? "active" : ""}`} href="/zm/dashboard/mining_companies">Mining Companies</a>
            <a className={`${location.pathname === "/zm/dashboard/construction_companies" ? "active" : ""}`} href="/zm/dashboard/construction_companies">Construction Companies</a>
            </li>
          </ul>
        </li>
        </>
      )}
    {/* Car Rentals */}
      {assignedSystems.includes("CARRENTAL") && (
        
        <li className="submenu">
        <a href="javascript:void(0);">
          <img src={purchase1} alt="img" />
          <span>Car Rentals</span>
          <span className="menu-arrow"></span>
        </a>
        <ul>
          <li>
            <a className={`${location.pathname === "/zm/dashboard/car_brands" ? "active" : ""}`} href="/zm/dashboard/car_brands">Car Brands</a>
          </li>
          <li>
            <a className={`${location.pathname === "/zm/dashboard/car_list" ? "active" : ""}`} href="/zm/dashboard/car_list">Car List</a>
          </li>
        </ul>
      </li>
      )}

      {/* Commodities */}
      {assignedSystems.includes("COMMODITY") && (
        <li className="submenu">
          <a href="javascript:void(0);">
            <img src={product} alt="img" />
            <span>Commodities</span>
            <span className="menu-arrow"></span>
          </a>
          <ul>
            <li>
              <a className={`${location.pathname === "/zm/dashboard/mineral_trading" ? "active" : ""}`} href="/zm/dashboard/mineral_trading">Mineral Trading</a>
            </li>
            <li>
              <a className={`${location.pathname === "/zm/dashboard/agriculture_list" ? "active" : ""}`} href="/zm/dashboard/agriculture_list">Agriculture List</a>
            </li>
            <li>
              <a className={`${location.pathname === "/zm/dashboard/fuel_list" ? "active" : ""}`} href="/zm/dashboard/fuel_list">Fuel List</a>
            </li>
          </ul>
        </li>
      )}

      {/* Real Estate */}
        {assignedSystems.includes("REALESTATE") && (
          <li className="submenu">
            <a href="javascript:void(0);">
              <i className="fa fa-home"></i>
              <span>Real Estate</span>
              <span className="menu-arrow"></span>
            </a>
            <ul>
              <li>
                <a className={`${location.pathname === "/zm/dashboard/real_estate_list" ? "active" : ""}`} href="/zm/dashboard/real_estate_list">Real Estate List</a>
              </li>
            </ul>
          </li>
        )}

        {/* DEALSECURE */}

        {assignedSystems.includes("DEALSECURE") && (
          <li className="submenu">
          <a href="javascript:void(0);">
            <img src={expense1} alt="img" />
            <span>DealSecure</span>
            <span className="menu-arrow"></span>
          </a>
          <ul>
            <li>
            </li>
            <li>
              <a className={`${location.pathname === "/zm/dashboard/escrow_list" ? "active" : ""}`} href="/zm/dashboard/escrow_list">DealSecure List</a>
            </li>
            
          </ul>
        </li>
        )}

        {/* "CONSTRUCTION-MINING" */}

        {assignedSystems.includes("CONSTRUCTION-MINING") && (
          <li className="submenu">
          <a href="javascript:void(0);">
            <img src={sales1} alt="img" />
            <span>Mining/Construction</span>
            <span className="menu-arrow"></span>
          </a>
          <ul>
            <li>
            </li>
            <li>
              <a className={`${location.pathname === "/zm/dashboard/mining_list" ? "active" : ""}`} href="/zm/dashboard/mining_list">Mining List</a>
            </li>
            <li>
              <a className={`${location.pathname === "/zm/dashboard/construction_list" ? "active" : ""}`} href="/zm/dashboard/construction_list">Construction List</a>
            </li>
          </ul>
        </li>
        )}
      {/* Requests */}
      <li className="submenu">
        <a href="javascript:void(0);">
          <img src={sales1} alt="img" />
          <span>Requests</span>
          <span className="menu-arrow"></span>
        </a>
        <ul>
          <li>
            <a className={`${location.pathname === "/zm/dashboard/requests" ? "active" : ""}`} href="/zm/dashboard/requests">View Requests</a>
          </li>
        </ul>
      </li>

      {/* People */}
      <li className="submenu">
        <a href="javascript:void(0);">
          <img src={user1} alt="img" />
          <span>Complaints</span>
          <span className="menu-arrow"></span>
        </a>
        <ul>
          <li>
            <a className={`${location.pathname === "/zm/dashboard/complaints" ? "active" : ""}`} href="/zm/dashboard/complaints">Complaint List</a>
          </li>
        </ul>
      </li>
    </>
  );
};

export default SupportAgentLinks;