import React, { useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useForm, SubmitHandler } from 'react-hook-form';
import { toast } from 'react-toastify';
import { FormInput } from '../../../../components/molecules/form_input';

// Define the type for the form fields
export type SubscriptionPackageFields = {
  name: string;
  applicable_modules: string;
  duration_options: string;
  description: string;
  normal_ad_placements: string;
  promotion: string;
  features: string;
  sms_notifications: boolean;
  website_link_inclusion: boolean;
  email_promotions: boolean;
  personal_manager: boolean;
  insights_report: boolean;
  feedback_tool: boolean;
  additional_phone_number: boolean;
  display_partner_name: boolean;
  // pricing: { [key: string]: string }; // Pricing by country
};

// Define the props for the modal
interface SubscriptionPackageModalProps {
  show: boolean;
  onHide: () => void;
  subPackage?: SubscriptionPackageFields; // Optional: For editing an existing package
  onSubmit: (data: SubscriptionPackageFields) => Promise<void>;
  isLoading: boolean;
}

const SubscriptionPackageModal: React.FC<SubscriptionPackageModalProps> = ({
  show,
  onHide,
  subPackage,
  onSubmit,
  isLoading,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<SubscriptionPackageFields>();

  // Reset form when the modal is shown or the package changes
  useEffect(() => {
    if (subPackage) {
      reset(subPackage); // Pre-fill the form for editing
    } else {
      reset(); // Reset the form for adding
    }
  }, [subPackage, reset]);

  const onFormSubmit: SubmitHandler<SubscriptionPackageFields> = async (data) => {
    try {
      await onSubmit(data);
      toast.success(subPackage ? 'Package updated successfully!' : 'Package added successfully!');
      onHide(); //package Close the modal
    } catch (error: any) {
      toast.error(`Failed to ${subPackage ? 'update' : 'add'} package: ${error.message}`);
    }
  };

  return (
    <Modal size='lg' show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>{subPackage ? 'Edit Package' : 'Add Package'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Form onSubmit={handleSubmit(onFormSubmit)}>
          <div className="row">
            {/* Name */}
          <div className="col-md-4">
          <FormInput<SubscriptionPackageFields>
            id="name"
            type="text"
            name="name"
            disabled={isLoading}
            label="Package Name"
            placeholder="Package Name"
            className="mb-3"
            register={register}
            rules={{ required: 'This field is required.' }}
            errors={errors}
          />
          </div>

          {/* Applicable Modules */}
          <div className="col-md-4">
          <FormInput<SubscriptionPackageFields>
            id="applicable_modules"
            type="text"
            name="applicable_modules"
            disabled={isLoading}
            label="Applicable Modules"
            placeholder="Applicable Modules"
            className="mb-3"
            register={register}
            rules={{ required: 'This field is required.' }}
            errors={errors}
          />
          </div>

          {/* Duration Options */}
          <div className="col-md-4">
          <FormInput<SubscriptionPackageFields>
            id="duration_options"
            type="text"
            name="duration_options"
            disabled={isLoading}
            label="Duration Options"
            placeholder="Duration Options"
            className="mb-3"
            register={register}
            rules={{ required: 'This field is required.' }}
            errors={errors}
          />
          </div>

          {/* Description */}
          <div className="col-md-4">
          <FormInput<SubscriptionPackageFields>
            id="description"
            type="text"
            name="description"
            disabled={isLoading}
            label="Description"
            placeholder="Description"
            className="mb-3"
            register={register}
            rules={{ required: 'This field is required.' }}
            errors={errors}
          />
          </div>

          {/* Normal Ad Placements */}
          <div className="col-md-4">
          <FormInput<SubscriptionPackageFields>
            id="normal_ad_placements"
            type="text"
            name="normal_ad_placements"
            disabled={isLoading}
            label="Normal Ad Placements"
            placeholder="Normal Ad Placements"
            className="mb-3"
            register={register}
            rules={{ required: 'This field is required.' }}
            errors={errors}
          />
          </div>

          {/* Promotion */}
          <div className="col-md-4">
          <FormInput<SubscriptionPackageFields>
            id="promotion"
            type="text"
            name="promotion"
            disabled={isLoading}
            label="Promotion"
            placeholder="Promotion"
            className="mb-3"
            register={register}
            rules={{ required: 'This field is required.' }}
            errors={errors}
          />
          </div>

          {/* Features */}
          <div className="col-md-4">
          <FormInput<SubscriptionPackageFields>
            id="features"
            type="text"
            name="features"
            disabled={isLoading}
            label="Features"
            placeholder="Features"
            className="mb-3"
            register={register}
            rules={{ required: 'This field is required.' }}
            errors={errors}
          />
          </div>

          {/* SMS Notifications */}
          <div className="col-md-4">
          <FormInput<SubscriptionPackageFields>
            id="sms_notifications"
            type="checkbox"
            name="sms_notifications"
            disabled={isLoading}
            label="SMS Notifications"
            placeholder="SMS Notifications"
            className="mb-3"
            register={register}
            rules={{ required: 'This field is required.' }}
            errors={errors}
          />
          </div>

          {/* Website/Social Media Link Inclusion */}
          <div className="col-md-4">
          <FormInput<SubscriptionPackageFields>
            id="website_link_inclusion"
            type="checkbox"
            name="website_link_inclusion"
            disabled={isLoading}
            label="Website/Social Media Link Inclusion"
            placeholder="Website/Social Media Link Inclusion"
            className="mb-3"
            register={register}
            rules={{ required: 'This field is required.' }}
            errors={errors}
          />
          </div>

          {/* Email and Social Media Promotions */}
          <div className="col-md-4">
          <FormInput<SubscriptionPackageFields>
            id="email_promotions"
            type="checkbox"
            name="email_promotions"
            disabled={isLoading}
            label="Email and Social Media Promotions"
            placeholder="Email and Social Media Promotions"
            className="mb-3"
            register={register}
            rules={{ required: 'This field is required.' }}
            errors={errors}
          />
          </div>

          {/* Dedicated Personal Manager */}
          <div className="col-md-4">
          <FormInput<SubscriptionPackageFields>
            id="personal_manager"
            type="checkbox"
            name="personal_manager"
            disabled={isLoading}
            label="Dedicated Personal Manager"
            placeholder="Dedicated Personal Manager"
            className="mb-3"
            register={register}
            rules={{ required: 'This field is required.' }}
            errors={errors}
          />
          </div>

          {/* DealiT Insights Report */}
          <div className="col-md-4">
          <FormInput<SubscriptionPackageFields>
            id="insights_report"
            type="checkbox"
            name="insights_report"
            disabled={isLoading}
            label="DealiT Insights Report"
            placeholder="DealiT Insights Report"
            className="mb-3"
            register={register}
            rules={{ required: 'This field is required.' }}
            errors={errors}
          />
          </div>

          {/* Feedback Management Tool */}
          <div className="col-md-4">
          <FormInput<SubscriptionPackageFields>
            id="feedback_tool"
            type="checkbox"
            name="feedback_tool"
            disabled={isLoading}
            label="Feedback Management Tool"
            placeholder="Feedback Management Tool"
            className="mb-3"
            register={register}
            rules={{ required: 'This field is required.' }}
            errors={errors}
          />
          </div>

          {/* Additional Phone Number */}
          <div className="col-md-4">
          <FormInput<SubscriptionPackageFields>
            id="additional_phone_number"
            type="checkbox"
            name="additional_phone_number"
            disabled={isLoading}
            label="Additional Phone Number"
            placeholder="Additional Phone Number"
            className="mb-3"
            register={register}
            rules={{ required: 'This field is required.' }}
            errors={errors}
          />
          </div>

          {/* Display Partner Company Name in Ads */}
          <div className="col-md-4">
          <FormInput<SubscriptionPackageFields>
            id="display_partner_name"
            type="checkbox"
            name="display_partner_name"
            disabled={isLoading}
            label="Display Partner Company Name in Ads"
            placeholder="Display Partner Company Name in Ads"
            className="mb-3"
            register={register}
            rules={{ required: 'This field is required.' }}
            errors={errors}
          />
          </div>

          {/* Pricing (JSON Field) */}
          <div className="col-md-4">
          <FormInput<SubscriptionPackageFields>
            id="pricing"
            type="text"
            name="display_partner_name"
            disabled={isLoading}
            label="Pricing (JSON)"
            placeholder="Pricing (JSON)"
            className="mb-3"
            register={register}
            rules={{ required: 'Pricing is required.' }}
            errors={errors}
          />
          </div>
          </div>

          <Modal.Footer>
            <Button variant="secondary" onClick={onHide} disabled={isLoading}>
              Close
            </Button>
            <Button type="submit" variant="primary" disabled={isLoading}>
              {isLoading ? 'Saving...' : 'Save Changes'}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default SubscriptionPackageModal;