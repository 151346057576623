import { apiSlice } from "../services/apiSlice";
import { SubscriptionPackage } from "../types/subscription.type";

export const subscriptionSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createSubscription: builder.mutation<SubscriptionPackage, Partial<SubscriptionPackage>>({
      query(data) {
        return {
          url: `/administrator/v1/everyday/subscription-packages/tech/`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["SubscriptionPackage"],
      transformResponse: (result: { item: SubscriptionPackage }) =>
        result.item,
    }),
    updateSubscription: builder.mutation<SubscriptionPackage, Partial<SubscriptionPackage>& Pick<SubscriptionPackage, 'id'>>({
      query: ({ id, ...patch }) => ({
        url: `/administrator/v1/everyday/subscription-packages/tech/${id}/`,
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: ["SubscriptionPackage"],
      transformResponse: (response: { item: SubscriptionPackage }) =>
        response.item,
    }),
    getSubscription: builder.query<SubscriptionPackage, number>({
      query: (id) => ({
        url: `/administrator/v1/everyday/subscription-packages/tech/${id}/`,
      }),
      providesTags: ["SubscriptionPackage"],
    }),
    getSubscriptionList: builder.query<SubscriptionPackage[], any>({
      query: ({status}) => `/administrator/v1/everyday/subscription-packages/tech/?status=${status}`,
    }),
    deleteSubscription: builder.mutation<SubscriptionPackage, number>({
      query: (id) => ({
        url: `/administrator/v1/everyday/subscription-packages/tech/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["SubscriptionPackage"],
    }),
  }),
});
export const {
  useCreateSubscriptionMutation,
  useDeleteSubscriptionMutation,
  useGetSubscriptionListQuery,
  useGetSubscriptionQuery,
  useUpdateSubscriptionMutation
} = subscriptionSlice;
