import { CreateUserResponse, SocialAuthArgs, User } from "../types/user";
import { apiSlice } from "../services/apiSlice";

const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCompaniesList: builder.query<User[], any>({
      query: ({user_type, status}) => `/userprofile/v1/everyday/admin_user_accounts/tech/?user_type=${user_type}&status=${status}`,
      transformResponse: (res: User[]) =>
        res.sort((a, b) => b.id - a.id),
        providesTags: (result) =>
        result ? result.map(({ id }) => ({ type: 'User', id })) : ['User'],
      // keepUnusedDataFor: 5,
    }),

    getSystemUserList: builder.query<User[], void>({
      query: () => `/userprofile/v1/everyday/system_user_accounts/tech/?user_type=ADMIN&user_type=MANAGER&user_type=SUPPORTAGENT`,
      transformResponse: (res: User[]) =>
        res.sort((a, b) => b.id - a.id),
        providesTags: (result) =>
        result ? result.map(({ id }) => ({ type: 'User', id })) : ['User'],
      // keepUnusedDataFor: 5,
    }),
    retrieveUser: builder.query<User, void>({
      query: () => "/auth/users/me/",
    }),

    socialAuthenticate: builder.mutation<CreateUserResponse, SocialAuthArgs>({
      query: ({ provider, state, code }) => ({
        url: `/o/${provider}/?state=${encodeURIComponent(
          state
        )}&code=${encodeURIComponent(code)}`,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }),
    }),

    login: builder.mutation<User, any>({
      query: ({ email, password }) => ({
        url: "/userprofile/v1/everyday/auth/jwt/token/tech",
        method: "POST",
        body: { email, password },
      }),
    }),
    register: builder.mutation<User, Partial<any>>({
      query(data) {
        // console.log(data);
        
        return {
          url: `/userprofile/v1/users/`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["User"],
      transformResponse: (result: { user: User }) =>
        result.user,
    }),
    verify: builder.mutation({
      query: ({token}) => ({
        url: "/auth/jwt/verify/",
        method: "POST",
        body: token,
      }),
    }),
    refresh: builder.mutation({
      query({token}) {
        return {
          url: "/auth/jwt/refresh/",
          method: "POST",
          body: token,
        }
      },
    }),
    logout: builder.mutation({
      query: () => ({
        url: "/logout/",
        method: "POST",
      }),
    }),

    activation: builder.mutation({
      query: ({ uid, token }) => ({
        url: "/users/activation/",
        method: "POST",
        body: { uid, token },
      }),
    }),

    resetPassword: builder.mutation({
      query: (email) => ({
        url: "userprofile/v1/password/reset_password/",
        method: "POST",
        body: { email },
      }),
    }),
    resetPasswordConfirm: builder.mutation({
      query: ({ uid, token, new_password, re_new_password }) => ({
        url: "userprofile/v1/password/reset_password_confirm/",
        method: "POST",
        body: { uid, token, new_password, re_new_password },
      }),
    }),
  }),
});

export const {
  useRetrieveUserQuery,
  useSocialAuthenticateMutation,
  useLoginMutation,
  useRegisterMutation,
  useVerifyMutation,
  useRefreshMutation,
  useLogoutMutation,
  useActivationMutation,
  useResetPasswordMutation,
  useResetPasswordConfirmMutation,
  useGetSystemUserListQuery,
  useGetCompaniesListQuery,
} = authApiSlice;
