import React from "react";
import { PropertyModel } from "../../../../models/product_model";
import { toast } from "react-toastify";
import { Button, Modal, Spinner } from "react-bootstrap";
import { useDeleteRealEstateMutation } from "../../../../redux/slices/property/propertySlice";

interface PaymentProps {
  property: PropertyModel | any;
  show: boolean;
  handleClose: () => void;
  setShow: (show: boolean) => void;
}

const DeletePayment: React.FC<PaymentProps> =({ property, show, handleClose, setShow })=> {
  const [deleteProperty, { isLoading: deleting},] = useDeleteRealEstateMutation();

  const onDelete = () => {
    deleteProperty(property.id)
      .unwrap()
      .then((res) => {
        setShow(false);
        toast.success("Property Deleted Successfully");
      })
      .catch((err) => {
        toast.error("An error occurred try again");
      });
  };
  return (
    <>
    <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <h3 className="text-center mt-3 mb-3">Are you sure you want to delete <br />
          <b className="text-capitalize">{property?.propertyName}</b></h3>
            <p className="text-danger text-center text-justify">
              This action can't be undone and all the information and
              products associated with this property will be delete and can't be retrieved
        </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" disabled={deleting} onClick={handleClose}>
            Close
          </Button>
          <Button disabled={deleting} onClick={onDelete} variant="danger">
            {deleting && <Spinner size="sm" />} Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DeletePayment;
