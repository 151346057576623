import React from "react";
import { User } from "../../../../../redux/types/user";
import { useGetUserQuery } from "../../../../../redux/slices/usersApiSlice";
import LoadingSpinner from "../../../../../components/widgets/spinner";
import BreadCrumbs from "../../../../widgets/bread_crumbs";
import editIcon from "../../../../../assets/img/icons/edit-5.svg";
import moment from "moment";
import { TInvestment } from "../../../../../redux/types/investment.type";
import { useGetInvestmentListQuery } from "../../../../../redux/slices/investment_slice";
import { formatPrice } from "../../../../utils/format_price";
import CompanySummary from "./summary";
import EditAccount from "../crud/edit_account";

const InvestmentCompanyDetails: React.FC = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const company_id = queryParams.get("company_id");

  const [show, setShow] = React.useState(false);
  const [user, setUser] = React.useState<User | any>();

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = (user: User) => {
    setUser(user);
    setShow(true);
  };
    

  const {
    data: userDetails,
    isLoading: isFetching,
    error,
  }: User | any = useGetUserQuery(Number(company_id));

  const {
    data: projects,
    isLoading,
  }: TInvestment | any = useGetInvestmentListQuery({
    user: company_id,
    status: "",
    search: "",
    page: 1,
  });
  if (isFetching || isLoading) {
    return (
      <div style={{ marginTop: "20%" }} className="text-center">
        <LoadingSpinner />
      </div>
    );
  }
  const findDayDifference = (date1: any, date2: any) => {
    // always round down
    return Math.floor(Math.abs(date1 - date2) / (1000 * 60 * 60 * 24));
  };
  return (
    <div className="content">
      {error ? (
        <center>
          <div className="alert alert-info text-white">
            Error Loading Application
          </div>
        </center>
      ) : (
        <>
        <EditAccount
            title={"Update User Account"}
            accountType={"INVESTMENT"}
            user={userDetails}
            show={show}
            handleClose={handleClose}
            setShow={setShow}
          />
          <BreadCrumbs
            title={"Company Details"}
            subTitle={`View ${userDetails?.company_name}`}
            button={
              <a className="ml-5" onClick={() => handleShow(user)}>
                <img width={40} height={40} src={editIcon} alt="img" />
              </a>
            }
            />
            <CompanySummary user={userDetails} totalSales={projects.total_sales} />
            {/* <div className="row">
              <div className="col-md-12">
                <h3>Monthly Sales</h3>
                <BranchCharts width={1000} height={250} data={monthlyData} />
              </div>
            </div> */}
          <div className="col-md-12">
            <div className="card mb-5 mb-md-0">
              <h4 className="m-3 pr-2 text-capitalize">
                <b>Projects For {userDetails?.company_name}</b>
              </h4>
              {projects.results.length === 0 ? (
                <center>
                  <div
                    className="text-center alert alert-info mt-3"
                    role="alert"
                    style={{ width: "fit-content" }}
                  >
                    <h1 className="mt-3">NO PROJECTS FOUND</h1>
                  </div>
                </center>
              ) : (
                <div className="card-body table-responsive">
                   <table className="table datanew">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Project Name</th>
                      <th>Project Category</th>
                      <th>Goal Amount</th>
                      <th>Raised</th>
                      <th>Days Remaining</th>
                      <th>Created On</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {projects.results.map((item: TInvestment, index: number) => {
                      return (
                        <>
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td className="text-capitalized">
                              {item.project_title.substring(0, 20)}...
                            </td>
                            <td className="text-uppercase">
                              <i className="fa fa-bookmark"></i>{" "}
                              {item.project_category}
                            </td>
                            <td>
                              {formatPrice({currency: item.user.company_currency.currency.currency_code, price: item.project_amount})}
                            </td>
                            <td>
                              {formatPrice({currency: item.user.company_currency.currency.currency_code, price: item.total_donated_avg})}
                            </td>
                            <td>
                              {moment().format("YYYY-MM-D") === item.project_days || moment().format("YYYY-MM-D") >
                                item.project_days ? (
                                <h6 className="text-warning">
                                  <i className="fa fa-heart"></i> Closed
                                </h6>
                              ) : (
                                <small className="text-capitalize text-dark">
                                  {findDayDifference(
                                    new Date(
                                      moment().format(item.project_days)
                                    ),
                                    new Date(moment().format("YYYY-MM-D"))
                                  )}{" "}
                                  day(s) remaining
                                </small>
                              )}{" "}
                            </td>
                            <td>
                              {moment(item.created_at).format("Do MMMM, YYYY")}
                            </td>
                            <td>
                              <a
                                className="me-3"
                                href={`/zm/dashboard/project_details/?project_id=${item.id}`}
                              >
                                <i className="fa fa-arrow-right"></i>
                              </a>
                              
                            </td>
                          </tr>

                        </>
                      );
                    })}
                  </tbody>
                </table>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default InvestmentCompanyDetails;
