import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useAppSelector } from "../../../hooks/redux-hooks";
import { useGetRealEstateQuery, useUpdateRealEstateMutation } from "../../../redux/slices/property/propertySlice";
import { PropertyModel } from "../../../models/product_model";
import LoadingSpinner from "../../../components/widgets/spinner";
import { ErrorWidget } from "../../utils/error_widget";
import { FormInput } from "../../../components/molecules/form_input";
import { SelectInput } from "../../../components/molecules/selectInput";
import { FormTextarea } from "../../../components/molecules/form_textarea";
import BreadCrumbs from "../../widgets/bread_crumbs";
import EditPropertyImages from "./edit_property_images";

const EditProperty: React.FC = () => {
  const userDetails = useAppSelector((state) => state.auth.user)
  const queryParams = new URLSearchParams(window.location.search);
  const property_id = queryParams.get("property");
  const [updateFuel, { isLoading: updatingProperty }] = useUpdateRealEstateMutation();
  const { data: property, isLoading, isError, error, refetch }: PropertyModel | any = useGetRealEstateQuery(Number(property_id));
  const { register, handleSubmit, setValue, formState: { errors } } = useForm();
  const [files, setFiles] = useState<string[]>([]);
  const [utilities, setUtilities] = useState(property?.propertyUtilities || []);
  const [interialDetails, setInterialDetails] = useState(property?.propertyInteriorDetails || []);
  const [outDoorDetails, setOutDoorDetails] = useState(property?.propertyOutdoorDetails || []);
  const [amenities, setAmenities] = useState(property?.propertyAmenities || []);

  useEffect(() => {
    if (property) {
      setValue('propertyName', property.propertyName);
      setValue('propertyLabel', property.propertyLabel);
      setValue('propertyType', property.propertyType);
      setValue('propertyPrice', property.propertyPrice);
      setValue('bedrooms', property.bedrooms);
      setValue('bathrooms', property.bathrooms);
      setValue('yearBuilt', property.yearBuilt);
      setValue('squareFeet', property.squareFeet);
      setValue('garages', property.garages);
      setValue('propertyAddress', property.propertyAddress);
      setValue('propertyCity', property.propertyCity);
      setValue('propertyCountry', property.propertyCountry);
      setValue('zipCode', property.zipCode);
      setValue('propertyDescription', property.propertyDescription);
      setValue('contactName', property.contactName);
      setValue('contactEmail', property.contactEmail);
      setValue('phoneNumber', property.contactPhone);
      setFiles(property.property_image || []);
    }
  }, [property, setValue]);


  const handleFormSubmit = (data: any) => {
    // Handle form submission for editing
    console.log('Form Data:', data);
    // Add your update logic here
    updateFuel({id: property_id,...data}).unwrap()
      .then((response) => {
        toast.success("Property Updated Successfully");
      })
      .catch((error: any) => {
        toast.error(error.message);
      });
  };
 
  if (isLoading) {
    return (
      <div style={{ marginTop: "20%" }} className="text-center">
        <LoadingSpinner />
      </div>
    );
  }
  if (isError) {
    console.log(error);
    return (
      <div style={{ marginTop: "20%" }} className="alert alert-danger" role="alert">
        <ErrorWidget error={error} />
      </div>
    );
  }
  return (
    <div className="content">
      <BreadCrumbs
        title={"Edit Property"}
        subTitle={"Manage/Edit Property"}
      />
      {/* <!-- ============================ User Dashboard ================================== --> */}
      <section className="gray pt-0 pb-5">
        <div className="col-xl-12 col-lg-12 col-md-12">
          <div className="dashboard-body">
            <div className="dashboard-wraper">
              <div className="row">
                {/* <!-- Submit Form --> */}
                <div className="col-lg-12 col-md-12">
                <form onSubmit={handleSubmit(handleFormSubmit)}>
      <div className="submit-page">
        {/* Basic Information */}
        <div className="frm_submit_block">
          <h3>Basic Information</h3>
          <div className="frm_submit_wrap">
            <div className="row">
              <div className="col-md-4">
                <FormInput
                  id="propertyName"
                  type="text"
                  name="propertyName"
                  label="Property Title"
                  disabled={updatingProperty}
                  placeholder="Property Title"
                  register={register}
                  rules={{ required: "Property Name is required!" }}
                  errors={errors}
                />
              </div>
              <div className="col-md-4">
                <SelectInput
                  id="propertyLabel"
                  name="propertyLabel"
                  label="Property Label"
                  disabled={updatingProperty}
                  register={register}
                  rules={{ required: "Property Label is required!" }}
                  errors={errors}
                >
                  <option value="">Select Property Label</option>
                  <option value="rent">For Rent</option>
                  <option value="sale">For Sale</option>
                </SelectInput>
              </div>
              <div className="col-md-4">
                <SelectInput
                  disabled={updatingProperty}
                  id="propertyType"
                  name="propertyType"
                  label="Property Type"
                  register={register}
                  rules={{ required: "Property Type is required!" }}
                  errors={errors}
                >
                  <option value="">Select Property Type</option>
                  <option value="houses">Houses</option>
                  <option value="apartments">Apartment</option>
                  <option value="villas">Villas</option>
                  <option value="commercials">Commercial</option>
                  <option value="offices">Offices</option>
                </SelectInput>
              </div>
              <div className="col-md-2">
                <FormInput
                  id="propertyPrice"
                  type="number"
                  name="propertyPrice"
                  label="Price"
                  disabled={updatingProperty}
                  placeholder="Property Price"
                  register={register}
                  rules={{ required: "Property Price is required!" }}
                  errors={errors}
                />
              </div>
              {/* Select Input for Bedrooms */}
              <div className="col-md-2">
                <SelectInput
                  id="bedrooms"
                  name="bedrooms"
                  label="Bedrooms"
                  register={register}
                  disabled={updatingProperty}
                  rules={{ required: "Bedrooms is required!" }}
                  errors={errors}
                >
                  <option value="">Select Number of Bedrooms</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </SelectInput>
              </div>

              {/* Select Input for Bathrooms */}
              <div className="col-md-2">
                <SelectInput
                  id="bathrooms"
                  name="bathrooms"
                  label="Bathrooms"
                  register={register}
                  disabled={updatingProperty}
                  rules={{ required: "Bathrooms is required!" }}
                  errors={errors}
                >
                  <option value="">Select Number of Bathrooms</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </SelectInput>
              </div>
              <div className="col-md-2">
                <FormInput
                  id="yearBuilt"
                  type="number"
                  name="yearBuilt"
                  label="Year Built"
                  placeholder="Year Built"
                  disabled={updatingProperty}
                  register={register}
                  rules={{ required: "Year Built is required!" }}
                  errors={errors}
                />
              </div>
              <div className="col-md-2">
                <FormInput
                  id="squareFeet"
                  type="number"
                  name="squareFeet"
                  label="Square Feet"
                  placeholder="Square Feet"
                  disabled={updatingProperty}
                  register={register}
                  rules={{ required: "Square Feet is required!" }}
                  errors={errors}
                />
              </div>
              <div className="col-md-2">
                <FormInput
                  id="garages"
                  type="number"
                  name="garages"
                  disabled={updatingProperty}
                  label="Garages"
                  placeholder="Number of Garages"
                  register={register}
                  rules={{ required: "Garages is required!" }}
                  errors={errors}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Location */}
        <div className="frm_submit_block">
          <h3>Location</h3>
          <div className="frm_submit_wrap">
            <div className="row">
              <div className="col-md-3">
                <FormInput
                  id="propertyAddress"
                  type="text"
                  disabled={updatingProperty}
                  name="propertyAddress"
                  label="Address"
                  placeholder="Address"
                  register={register}
                  rules={{ required: "Address is required!" }}
                  errors={errors}
                />
              </div>
              <div className="col-md-3">
                <FormInput
                  id="propertyCity"
                  type="text"
                  name="propertyCity"
                  label="City"
                  placeholder="City"
                  disabled={updatingProperty}
                  register={register}
                  rules={{ required: "City is required!" }}
                  errors={errors}
                />
              </div>
              <div className="col-md-3">
                <FormInput
                  id="propertyCountry"
                  type="text"
                  name="propertyCountry"
                  label="State"
                  placeholder="State"
                  register={register}
                  disabled={updatingProperty}
                  rules={{ required: "State is required!" }}
                  errors={errors}
                />
              </div>
              <div className="col-md-3">
                <FormInput
                  id="zipCode"
                  type="text"
                  name="zipCode"
                  label="Zip Code"
                  placeholder="Zip Code"
                  disabled={updatingProperty}
                  register={register}
                  rules={{ required: "Zip Code is required!" }}
                  errors={errors}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Detailed Information */}
        {property.propertyType === "lands" || property.propertyType ==="farms" ? null: 
          <div className="frm_submit_block">
            <h3>Detailed Information</h3>
            <div className="frm_submit_wrap">
              {/* Utilities */}
              <div className="detailed-information">
                <h3>Utilities</h3>
                <div className="float-right pull-right">
                  <button
                    type="button"
                    onClick={() => setUtilities([...utilities, { name: "" }])}
                    className="btn btn-sm btn-primary"
                  >
                    Add More <i className="fa fa-circle-plus"></i>
                  </button>
                </div>
                <div className="row">
                  {utilities.map((value: any, index: number) => (
                    <div key={index} className="form-group col-md-4">
                      <label>Item {index + 1}</label>
                      <input
                        type="text"
                        value={value.name}
                        disabled={updatingProperty}
                        onChange={(e) => {
                          const newUtilities = [...utilities];
                          newUtilities[index].name = e.target.value;
                          setUtilities(newUtilities);
                        }}
                        className="form-control"
                      />
                      <div className="float-right">
                        <button
                          type="button"
                          onClick={() => {
                            const newUtilities = [...utilities];
                            newUtilities.splice(index, 1);
                            setUtilities(newUtilities);
                          }}
                          className="btn btn-sm btn-danger"
                        >
                          Remove <i className="fa fa-minus-plus"></i>
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {/* Interior Details */}
              <div className="detailed-information">
                <h3>Interior Details</h3>
                <div className="float-right pull-right">
                  <button
                    type="button"
                    onClick={() => setInterialDetails([...interialDetails, { name: "" }])}
                    className="btn btn-sm btn-primary"
                  >
                    Add More <i className="fa fa-circle-plus"></i>
                  </button>
                </div>
                <div className="row">
                  {interialDetails.map((value: any, index: number) => (
                    <div key={index} className="form-group col-md-4">
                      <label>Item {index + 1}</label>
                      <input
                        type="text"
                        disabled={updatingProperty}
                        value={value.name}
                        onChange={(e) => {
                          const newInteriorDetails = [...interialDetails];
                          newInteriorDetails[index].name = e.target.value;
                          setInterialDetails(newInteriorDetails);
                        }}
                        className="form-control"
                      />
                      <div className="float-right">
                        <button
                          type="button"
                          onClick={() => {
                            const newInteriorDetails = [...interialDetails];
                            newInteriorDetails.splice(index, 1);
                            setInterialDetails(newInteriorDetails);
                          }}
                          className="btn btn-sm btn-danger"
                        >
                          Remove <i className="fa fa-minus-plus"></i>
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {/* Outdoor Details */}
              <div className="detailed-information">
                <h3>Outdoor Details</h3>
                <div className="float-right pull-right">
                  <button
                    type="button"
                    onClick={() => setOutDoorDetails([...outDoorDetails, { name: "" }])}
                    className="btn btn-sm btn-primary"
                  >
                    Add More <i className="fa fa-circle-plus"></i>
                  </button>
                </div>
                <div className="row">
                  {outDoorDetails.map((value: any, index: number) => (
                    <div key={index} className="form-group col-md-4">
                      <label>Item {index + 1}</label>
                      <input
                        type="text"
                        value={value.name}
                        disabled={updatingProperty}
                        onChange={(e) => {
                          const newOutdoorDetails = [...outDoorDetails];
                          newOutdoorDetails[index].name = e.target.value;
                          setOutDoorDetails(newOutdoorDetails);
                        }}
                        className="form-control"
                      />
                      <div className="float-right">
                        <button
                          type="button"
                          onClick={() => {
                            const newOutdoorDetails = [...outDoorDetails];
                            newOutdoorDetails.splice(index, 1);
                            setOutDoorDetails(newOutdoorDetails);
                          }}
                          className="btn btn-sm btn-danger"
                        >
                          Remove <i className="fa fa-minus-plus"></i>
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {/* Amenities */}
              <div className="detailed-information">
                <h3>Amenities</h3>
                <div className="float-right pull-right">
                  <button
                    type="button"
                    onClick={() => setAmenities([...amenities, { name: "" }])}
                    className="btn btn-sm btn-primary"
                  >
                    Add More <i className="fa fa-circle-plus"></i>
                  </button>
                </div>
                <div className="row">
                  {amenities.map((value: any, index: number) => (
                    <div key={index} className="form-group col-md-4">
                      <label>Item {index + 1}</label>
                      <input
                        type="text"
                        disabled={updatingProperty}
                        value={value.name}
                        onChange={(e) => {
                          const newAmenities = [...amenities];
                          newAmenities[index].name = e.target.value;
                          setAmenities(newAmenities);
                        }}
                        className="form-control"
                      />
                      <div className="float-right">
                        <button
                          type="button"
                          onClick={() => {
                            const newAmenities = [...amenities];
                            newAmenities.splice(index, 1);
                            setAmenities(newAmenities);
                          }}
                          className="btn btn-sm btn-danger"
                        >
                          Remove <i className="fa fa-minus-plus"></i>
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {/* Property Description */}
              <div className="row">
                <FormTextarea
                  id="propertyDescription"
                  name="propertyDescription"
                  label="Property Description"
                  disabled={updatingProperty}
                  placeholder="Property Description"
                  register={register}
                  rules={{ required: "Property Description is required!" }}
                  errors={errors}
                />
              </div>
            </div>
          </div>
        }

        {/* Contact Information */}
        <div className="frm_submit_block">
          <h3>Contact Information</h3>
          <div className="frm_submit_wrap">
            <div className="row">
              <div className="col-md-4">
                <FormInput
                  id="contactName"
                  type="text"
                  disabled={updatingProperty}
                  name="contactName"
                  label="Name"
                  placeholder="Contact Name"
                  register={register}
                  rules={{ required: "Contact Name is required!" }}
                  errors={errors}
                />
              </div>
              <div className="col-md-4">
                <FormInput
                  id="contactEmail"
                  type="email"
                  name="contactEmail"
                  label="Email"
                  placeholder="Contact Email"
                  disabled={updatingProperty}
                  register={register}
                  rules={{ required: "Contact Email is required!" }}
                  errors={errors}
                />
              </div>
              <div className="col-md-4">
                <FormInput
                  id="phoneNumber"
                  type="text"
                  name="phoneNumber"
                  label="Phone"
                  placeholder="Contact Number"
                  disabled={updatingProperty}
                  register={register}
                  rules={{ required: "Phone Number is required!" }}
                  errors={errors}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Submit Button */}
        <div className="form-group col-lg-12 col-md-12 text-center">
          <button
            className="btn btn-primary"
            type="submit"
          >
            Update Property
          </button>
        </div>
      </div>
    </form>
    {/* Gallery */}
    <div className="frm_submit_block">
          <h3>Gallery</h3>
          <div className="frm_submit_wrap">
            <EditPropertyImages propertyID={property_id} images={property?.property_image} fetchData={refetch} />
          </div>
        </div>

                </div>
              </div>
            </div>
            {/* <!-- row --> */}
          </div>
        </div>
      </section>
      {/* <!-- ============================ User Dashboard End ================================== --> */}
    </div>
  );
};

export default EditProperty;
