import React from "react";
import { User } from "../../../../../redux/types/user";
import { useGetUserQuery } from "../../../../../redux/slices/usersApiSlice";
import LoadingSpinner from "../../../../../components/widgets/spinner";
import BreadCrumbs from "../../../../widgets/bread_crumbs";
import editIcon from "../../../../../assets/img/icons/edit-5.svg";
import moment from "moment";
import { TMineralOrder } from "../../../../../redux/types/mineral_order.type";
import { useGetMineralOrderListQuery } from "../../../../../redux/slices/mineral_order_slice";
import CompanySummary from "./summary";
import EditAccount from "../crud/edit_account";
import Pagination from "../../../../pagination/pagination_items";
import { TMineral } from "../../../../../redux/types/mineral.type";
import { useGetMineralListQuery } from "../../../../../redux/slices/mineral_slice";
import { formatPrice } from "../../../../utils/format_price";

const MineralCompanyDetails: React.FC = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const company_id = queryParams.get("company_id");
  const [show, setShow] = React.useState(false);
  const [user, setUser] = React.useState<User | any>();
  const [query, setQuery] = React.useState("")
  const [currentPage, setCurrentPage] = React.useState(1);
  const [fromDate, setFromDate] = React.useState("");
  const [toDate, setToDate] = React.useState("");

  // Handler for date filter change
  const onDateFilter = (fromDate: string, toDate: string) => {
    setFromDate(fromDate);
    setToDate(toDate);
    // Call your API or perform filtering here
    console.log("Filtering from:", fromDate, "to:", toDate);
  };
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setQuery(newValue);
  }

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = (user: User) => {
    setUser(user);
    setShow(true);
  };

  const {
    data: userDetails,
    isLoading: isFetching,
    error,
  }: User | any = useGetUserQuery(Number(company_id));

  const {
    data: minerals,
    isLoading,
  }: TMineralOrder | any = useGetMineralListQuery({
    supplier: company_id, 
    status: "ACTIVE", 
    page: currentPage, 
    searchQuery: query,
    fromDate: fromDate,
    toDate:   toDate,
  });
  if (isFetching || isLoading) {
    return (
      <div style={{ marginTop: "20%" }} className="text-center">
        <LoadingSpinner />
      </div>
    );
  }
  return (
    <div className="content">
      {error ? (
        <center>
          <div className="alert alert-info text-white">
            Error Loading Application
          </div>
        </center>
      ) : (
        <>
        <EditAccount
            title={"Update User Account"}
            accountType={"MINERAL"}
            user={userDetails}
            show={show}
            handleClose={handleClose}
            setShow={setShow}
          />
          <BreadCrumbs
            title={"Company Details"}
            subTitle={`View ${userDetails?.company_name}`}
            button={
              <a className="ml-5" onClick={() => handleShow(user)}>
                <img width={40} height={40} src={editIcon} alt="img" />
              </a>
            }
            />
            <CompanySummary user={userDetails} totalSales={minerals.total_sales} />
            {/* <div className="row">
              <div className="col-md-12">
                <h3>Monthly Sales</h3>
                <BranchCharts width={1000} height={250} data={monthlyData} />
              </div>
            </div> */}
          <div className="col-md-12">
            <div className="card mb-5 mb-md-0">
              <h4 className="m-3 pr-2 text-capitalize">
                <b>Mineral Products For {userDetails?.company_name}</b>
              </h4>
              {minerals.results.length === 0 ? (
                <center>
                  <div
                    className="text-center alert alert-info mt-3"
                    role="alert"
                    style={{ width: "fit-content" }}
                  >
                    <h1 className="mt-3">NO MINERAL PRODUCTS FOUND</h1>
                  </div>
                </center>
              ) : (
                <div className="card-body table-responsive">
                   <div className="table-responsive">
                    <table className="table datanew">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Product</th>
                          <th>Type</th>
                          <th>Unit</th>
                          <th>Product Price</th>
                          <th>Sales</th>
                          <th>Status</th>
                          <th>Created On</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {minerals.results.map((item: TMineral, index: number) => {
                          
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td className="text-capitalize">
                                <div className="row">
                                  <div className="col-md-4 mr-0 pr-0">
                                    <a href="">
                                      <img
                                        className="product-imgs rounded"
                                        src={`${item.image}`}
                                        alt={item.productName}
                                      />
                                    </a>
                                  </div>
                                  <div className="col-md-8 ml-0 pl-0">
                                    <a href="">
                                      {item.productName.substring(0, 10)}
                                    </a>
                                  </div>
                                </div>
                              </td>
                              <td className="text-capitalize">
                                {item.category.categoryName}
                              </td>
                              <td className="text-capitalize">{item.unit}</td>
                              <td>{formatPrice({currency: item.supplier.company_currency.currency.currency_code, price: item.productPrice})}</td>
                              <td>{item.mineral_order_product.length}</td>
                              <td className="text-capitalize">{item.status}</td>
                              <td>
                                {moment(item.created_at).format("Do MMMM, YYYY")}
                              </td>
                              <td>
                                  <span>
                                    <a className="me-3" href={`/zm/dashboard/mineral_transactions/?mineral=${item.id}`}>
                                      <i className="fa fa-arrow-right"></i>
                                    </a>
                                  </span>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    <Pagination
                      currentPage={currentPage}
                      lastPage={minerals.total_pages}
                      maxLength={7}
                      setCurrentPage={setCurrentPage}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default MineralCompanyDetails;
