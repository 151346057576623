export const orderStatus = [
    "PENDING",
    "CONFIRMED",
    "REJECT",
    "CANCELLED",
]

export const AdStatusMap = {
    PENDING: "Pending",
    ACTIVE: "Active",
    INACTIVE: "Inactive",
  } as const;
  
  export type AdStatusType = keyof typeof AdStatusMap;
  