import React from "react";
import edit from "../assets/img/icons/edit-set.svg";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { useAppSelector } from "../hooks/redux-hooks";
import {useGetUserQuery, useUpdateUserMutation} from "../redux/slices/usersApiSlice";
import { User } from "../redux/types/user";
import BreadCrumbs from "./widgets/bread_crumbs";
import { FormInput } from "../components/molecules/form_input";
import { DocumentCards } from "./client_documents";

export type DataFormFields = {
  first_name: string;
  last_name: string;
  email: string;
  company_email: string;
  nationality: string;
  company_name: string;
  phone_number?: string;
  phoneNumber: string;
  subscription?: number;
  account_type?: string;
  currency?: number;
  merchant_id?: string;
  commission_fee?: number;
  company_address?: string;
};

const CompanyProfile: React.FC = () => {
  const profileData = useAppSelector((state) => state.auth.user);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<DataFormFields>();

  const {
    data: userData,
    isLoading: gettingUser,
    isSuccess: gettingUserSuccess,
    refetch,
    error,
  }: User | any = useGetUserQuery(Number(profileData?.id));
  const [updateUser, { isLoading, isSuccess }] = useUpdateUserMutation();

  if (gettingUser) {
    return (
      <div style={{ marginTop: "20%" }} className="text-center">
        <i className="fa fa-spinner fa-spin fa-5x text-danger"></i>
      </div>
    );
  }

  if (gettingUserSuccess) {
    console.log(userData);
    
    localStorage.setItem("userInfo", JSON.stringify(userData));
  }

  if (error) {
    return (
      <div
        style={{ marginTop: "50%" }}
        className="alert alert-danger mt-5 text-center"
        role="alert"
      >
        {error}
      </div>
    );
  }

  const onSubmit = handleSubmit(async (data) => {
    updateUser({ id: Number(profileData?.id), ...data })
      .unwrap()
      .then(() => {
        toast.success("Details Updated Successfully");
      })
      .catch((err) => {
        toast.error("Failed to add:" + err.error);
      });
  });

  return (
    <>
      <div className="content">
      <BreadCrumbs
        title={"Profile"}
        subTitle={"Manage Your Profile"}
      />

        <div className="card">
          <div className="card-body">
            <div className="profile-set">
              <div className="profile-head"></div>
              <div className="profile-top">
                <div className="profile-content">
                  <div className="profile-contentimg">
                    <img
                      src={
                        userData.user_profile?.profile_picture === undefined
                          ? "https://intercitytravellers.com/media/profilepic/user.png"
                          : userData.user_profile?.profile_picture
                      }
                      alt="img"
                      id="blah"
                    />
                    <div className="profileupload">
                      <input type="file" id="imgInp" />
                      <a href="javascript:void(0);">
                        <img src={edit} alt="img" />
                      </a>
                    </div>
                  </div>
                  <div className="profile-contentname">
                    <h2 className="text-capitalize">
                      {userData.company_name}
                    </h2>
                    <small>{userData.email}</small>
                    <h4>Update Your Photo and Personal Details.</h4>
                  </div>
                </div>
              </div>
            </div>
            {/* EDIT FORM */}
            <Form.Group as={Col} md="12">
              <form onSubmit={onSubmit}>
                <h3><b>Personal Details</b></h3>
                <div className="row">
                  <div className="col-md-6">
                    <FormInput<DataFormFields>
                      id="first_name"
                      type="text"
                      name="first_name"
                      defaultValue={userData?.first_name}
                      disabled={isLoading}
                      label="First Name"
                      placeholder="First Name"
                      className="mb-2"
                      register={register}
                      rules={{ required: "First Name is required." }}
                      errors={errors}
                    />
                  </div>
                  <div className="col-md-6">
                    <FormInput<DataFormFields>
                      id="last_name"
                      type="text"
                      name="last_name"
                      defaultValue={userData?.last_name}
                      disabled={isLoading}
                      label="Last Name"
                      placeholder="Last Name"
                      className="mb-2"
                      register={register}
                      rules={{ required: "Last Name is required." }}
                      errors={errors}
                    />
                  </div>
                  <div className="col-md-6">
                    <FormInput<DataFormFields>
                      id="phoneNumber"
                      type="text"
                      name="phoneNumber"
                      defaultValue={userData?.phoneNumber}
                      disabled={isLoading}
                      label="Phone Number"
                      placeholder="Phone Number"
                      className="mb-2"
                      register={register}
                      rules={{ required: "Phone Number is required." }}
                      errors={errors}
                    />
                  </div>
                  <div className="col-md-6">
                    <FormInput<DataFormFields>
                      id="email"
                      type="email"
                      name="email"
                      defaultValue={userData?.email}
                      disabled={isLoading}
                      readOnly
                      label="Email"
                      placeholder="Email"
                      className="mb-2"
                      register={register}
                      rules={{ required: "Email is required." }}
                      errors={errors}
                    />
                  </div>
                </div>
                <h3><b>Company Details</b></h3>
                <div className="row">
                <div className="col-md-4">
                    <FormInput<DataFormFields>
                      id="company_name"
                      type="text"
                      name="company_name"
                      defaultValue={userData?.company_name}
                      disabled={isLoading}
                      label="Company Name"
                      placeholder="Company Name"
                      className="mb-2"
                      register={register}
                      rules={{ required: "Company Name is required." }}
                      errors={errors}
                    />
                  </div>
                  <div className="col-md-4">
                    <FormInput<DataFormFields>
                      id="company_address"
                      type="text"
                      name="company_address"
                      defaultValue={userData?.user_profile?.company_address}
                      disabled={isLoading}
                      label="Company Address"
                      placeholder="Company Address"
                      className="mb-2"
                      register={register}
                      rules={{ required: "Company Address is required." }}
                      errors={errors}
                    />
                  </div>
                  <div className="col-md-4">
                    <FormInput<DataFormFields>
                      id="company_email"
                      type="text"
                      name="company_email"
                      defaultValue={userData?.company_email}
                      disabled={isLoading}
                      label="Company E-mail"
                      placeholder="Company E-mail"
                      className="mb-2"
                      register={register}
                      rules={{ required: "Company E-mail is required." }}
                      errors={errors}
                    />
                  </div>
                  <div className="col-md-4">
                    <FormInput<DataFormFields>
                      id="phone_number"
                      type="text"
                      name="phone_number"
                      defaultValue={userData?.user_profile?.phone_number}
                      disabled={isLoading}
                      label="Company Phone Number"
                      placeholder="Company Phone Number"
                      className="mb-2"
                      register={register}
                      rules={{ required: "Company Phone Number is required." }}
                      errors={errors}
                    />
                  </div>
                  <div className="col-md-4">
                    <FormInput<DataFormFields>
                      id="nationality"
                      type="text"
                      name="nationality"
                      label="Nationality"
                      disabled={isLoading}
                      defaultValue={userData?.nationality}
                      placeholder="Nationality"
                      className="mb-2"
                      register={register}
                      rules={{ required: "Nationality is required." }}
                      errors={errors}
                    />
                  </div>
                  <div className="col-md-4">
                    <FormInput<DataFormFields>
                      id="account_type"
                      type="text"
                      name="account_type"
                      label="Account Type"
                      disabled={isLoading}
                      readOnly
                      defaultValue={userData?.account_type}
                      placeholder="Account Type"
                      className="mb-2"
                      register={register}
                      rules={{ required: false }}
                      errors={errors}
                    />
                  </div>
                  <div className="col-md-6">
                    <FormInput<DataFormFields>
                      id="subscription"
                      type="text"
                      name="subscription"
                      label="Current Subscription"
                      disabled={isLoading}
                      readOnly
                      defaultValue={userData?.user_subscription?.subscription.name}
                      placeholder="Current Subscription"
                      className="mb-2"
                      register={register}
                      rules={{ required: false }}
                      errors={errors}
                    />
                  </div>
                  <div className="col-md-6">
                    <FormInput<DataFormFields>
                      id="currency"
                      type="text"
                      name="currency"
                      label="Current Currency"
                      disabled={isLoading}
                      readOnly
                      defaultValue={`${userData?.company_currency.currency.currency_name} - ${userData?.company_currency.currency.currency_code}`}
                      placeholder="Current Currency"
                      className="mb-2"
                      register={register}
                      rules={{ required: false }}
                      errors={errors}
                    />
                  </div>
                </div>

                <DocumentCards refetch={refetch} />
                
                <div className="ms-auto text-center">
                  <button type="submit" className="btn btn-submit me-2">
                    {isLoading && (<Spinner />)}{" "}
                    Update Account
                  </button>
                </div>
              </form>
            </Form.Group>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyProfile;
