import React, { useRef } from "react";
import moment from "moment";
import { useGetFuelListQuery } from "../../../../redux/slices/fuel_slice";
import { TFuelModel } from "../../../../redux/types/fuel_mode.type";
import LoadingSpinner from "../../../../components/widgets/spinner";
import Pagination from "../../../pagination/pagination_items";
import { ErrorWidget } from "../../../utils/error_widget";
import Filters from "../../widgets/filters";
import { formatPrice } from "../../../utils/format_price";
import { User } from "../../../../redux/types/user";
import { useGetCompaniesListQuery } from "../../../../redux/slices/authApiSlice";

const FuelList: React.FC = () => {
  const divRef = useRef<HTMLDivElement>(null);
  const [query, setQuery] = React.useState("")
  const [currentPage, setCurrentPage] = React.useState(1);
  const [fromDate, setFromDate] = React.useState("");
  const [toDate, setToDate] = React.useState("");
  const [supplier, setSupplier] = React.useState("")
    const [status, setStatus] = React.useState<string>("");

  // Handler for date filter change
  const onDateFilter = (fromDate: string, toDate: string) => {
    setFromDate(fromDate);
    setToDate(toDate);
    // Call your API or perform filtering here
    console.log("Filtering from:", fromDate, "to:", toDate);
  };
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setQuery(newValue);
  }

    const onStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
      const newValue = e.target.value;
      setStatus(newValue);
    }
  
    const onChangeSupplier = (e: React.ChangeEvent<HTMLSelectElement>) => {
      const newValue = e.target.value;
      setSupplier(newValue);
    }
   
  
  
    const {
      data: usersList,
      isLoading: loadingUsers,
      error: usersError,
    }: User | any = useGetCompaniesListQuery({ user_type: "FUEL" });
    
  const {
    data: fuelData,
    isLoading,
    error,
  }: TFuelModel | any = useGetFuelListQuery({
    supplier: supplier, 
    status: status, 
    page: currentPage, 
    searchQuery: query,
    fromDate: fromDate,
    toDate:   toDate,
  });

  if (isLoading || loadingUsers) {
    return (
      <div style={{ marginTop: "20%" }} className="text-center">
        <LoadingSpinner />
      </div>
    );
  }
  return (
    <div className="content">
      <div className="page-header">
        <div className="page-title">
          <h4>
            <b>Fuel List</b>
          </h4>
          <h6>Manage your Fuels</h6>
        </div>
        <div className="page-btn">
          <a
            href="/zm/dashboard/pending_fuel_list"
            className="btn btn-added"
          >
            Pending Requests <i className="fa fa-arrow-right m-2"></i>
          </a>
      </div>
      </div>
      <div className="card">
        <div className="card" id="">
          <div className="card-body pb-0">
          <Filters divRef={divRef} showSearch={false} query={query} onSearchChange={onChange} onDateFilter={onDateFilter} />
            <div className="row">
              <div className="col-lg col-sm-6 col-12">
                <div className="form-group">
                  <input type="text"
                    value={query}
                    name="search_agro_list"
                    id="search_agro_list"
                    onChange={onChange} placeholder="Search Products" />
                </div>
              </div>
              <div className="col-lg col-sm-6 col-12">
                <div className="form-group">
                  <select onChange={onChangeSupplier} name="supplier__id" className="select form-control">
                    <option value="">Choose Supplier</option>
                    {usersList.map((item: User, index: number)=>{
                      return <option key={index} value={item.id}>{item.company_name}</option>
                    })}
                  </select>
                </div>
              </div>
              <div className="col-lg col-sm-6 col-12">
                <div className="form-group">
                <select onChange={onStatusChange} className="select form-control">
                    <option value="">Choose Status</option>
                    <option value="ACTIVE">Active</option>
                    <option value="PENDING">Pending</option>
                    <option value="UNAVAILABLE">Unavailable</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-body">
          {error ? (
           <ErrorWidget error={error}/>
          ) : fuelData.results.length === 0 ? (
            <center className="p-3">
              <i className="fa fa-calendar-day fa-5x"></i>
              <h4>
                <b>No Data Found</b>
              </h4>
            </center>
          ) : (
            <div ref={divRef} className="table-responsive">
              <table className="table datanew">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Supplier</th>
                    <th>Name</th>
                    <th>Reference</th>
                    <th>Amount</th>
                    <th>Quantity</th>
                    <th>Sales</th>
                    <th>Status</th>
                    <th>Created On</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {fuelData.results.map((item: TFuelModel, index: number) => (
                    <tr key={index}>
                      <td>{index+1}</td>
                      <td className="text-capitalize">{item.supplier.company_name}</td>
                      <td className="text-capitalize">{item.fuel_name}</td>
                      <td>{item.product_unique_id}</td>
                      <td>{formatPrice({currency: item.supplier.company_currency.currency.currency_code, price: item.fuel_price})}/{item.unit}</td>
                      <td>{item.quantity}</td>
                      <td>{item.fuel_order_product.length}</td>
                      <td>{item.status}</td>
                      <td>{moment(item.created_at).format("Do MMMM, YYYY")}</td>
                      <td>
                        <a
                          href={`/zm/dashboard/fuel_details/?fuel_id=${item.id}`}
                        >
                          <i className="fa fa-arrow-right"></i>
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination
                currentPage={currentPage}
                lastPage={fuelData.total_pages}
                maxLength={7}
                setCurrentPage={setCurrentPage}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FuelList;
