
import { NotificationModel } from "../../models/notification_model";
import { PropertyModel } from "../../models/product_model";
import { RequestsModel } from "../../models/requests_model";
import { User } from "../../redux/types/user";
import http from "./http.common";
import httpFiles from "./http.files";

class ProductDataService {
  // ADS HTTP SERVICES
  async createAd(data: any) {
    let form_data = new FormData();

    form_data.append("titleName", data.titleName);
    form_data.append("description", data.description);
    form_data.append("website", data.website);
    form_data.append("facebook", data.facebook || ""); // Avoid null issues
    form_data.append("twitter", data.twitter || "");
    form_data.append("instagram", data.instagram || "");
    form_data.append("status", data.status);
    form_data.append("ad_type", data.ad_type)

    if (data.image && data.image.length > 0) {
        form_data.append("image", data.image[0]); 
    }

    return await httpFiles.post<any>(
        "/administrator/v1/everyday/everyday_ads/tech/", 
        form_data
    );
}

  async updateAd(data: any) {
    let form_data = new FormData();
    form_data.append("titleName", data.titleName);
    form_data.append("description", data.description);
    form_data.append("website", data.website);
    form_data.append("facebook", data.facebook);
    form_data.append("twitter", data.twitter);
    form_data.append("instagram", data.instagram);
    form_data.append("status", data.status);
    form_data.append("ad_type", data.ad_type)
    if (data.image && data.image.length > 0) {
      form_data.append("image", data.image[0]); 
  }
    return await httpFiles.patch<any>(`/administrator/v1/everyday/everyday_ads/tech/${data.id}/`, form_data);
  }
  // PROPERTIES HTTP SERVICES
  async getProperties({supplier, status, searchQuery, page}: any) {
    return await http.get<Array<PropertyModel>>(
      `/realestate/v1/everyday/admin_properties/tech/?search=${searchQuery}&page=${page}&supplier=${supplier}&status=${status}`
    );
  }

  // GET RENTED OR SOLD PROPERTIES
  async rentedSoldProperties(uid: any) {
    return await http.get<Array<PropertyModel>>(
      `/realestate/v1/everyday/rented_sold_properties/${uid}/tech`
    );
  }
  // get property by id
  async get(id: string) {
    return await http.get<PropertyModel>(`/realestate/v1/property/${id}/api`);
  }
  // create a new property
  async createProperty(data: any) {
    return await httpFiles.post<any>("/realestate/v1/everyday/admin_properties/tech/", data);
  }

  async submitPropertyImages(data: any) {
    return await httpFiles.post<any>(
      `/realestate/v1/everyday/property_image/tech/`,
      data
    );
  }

  async updatePropertyImages(data: any) {
    return await httpFiles.post<any>(
      `/realestate/v1/everyday/property_image/tech/`,
      data
    );
  }
  // update property
  async updatePropety(data: PropertyModel, id: any) {
    return await http.patch<any>(`/realestate/v1/property/${id}/api`, data);
  }
  // ACTIVATE PROPERTY
  async activateProperty(property: PropertyModel) {
    const body = {
      status: "active",
      propertyReviews: property.propertyReviews,
    };
    return await http.patch<PropertyModel>(
      `/realestate/v1/everyday/update_property_views/${property.id}/tech`,
      JSON.stringify(body)
    );
  }
  // INACTIVATE PROPERTY
  async inactivateProperty(id: any) {
    return await http.patch<any>(`/realestate/v1/property/${id}/inactivate/`);
  }
  // GET ALL PROPERTIES
  async getAllProperties() {
    return await http.get<Array<PropertyModel>>(`/realestate/v1/everyday/admin/tech/`);
  }
  // GET ALL PROPERTIES
  // delete property
  async deletePropery(id: any) {
    return await http.delete<any>(`/realestate/v1/everyday/admin/tech/${id}/`);
  }
  // delete all categories
  async deleteAllUserProperties() {
    return await http.delete<any>(`/realestate/v1/property`);
  }
  // fetch property by property name
  async getPropertyByLabel(label: string, uid: any) {
    return await http.get<PropertyModel[]>(
      `/realestate/v1/everyday/property_label/${label}/${uid}/tech`
    );
  }

  // fetch user notifications
  async getUserNotifications(uid: number) {
    return await http.get<Array<NotificationModel>>(
      `/realestate/v1/everyday/notifications/${uid}/tech/`
    );
  }

  // fetch user requests
  async getUserRequest(uid: number) {
    return await http.get<Array<RequestsModel>>(
      `/realestate/v1/everyday/user_requests/${uid}/tech/`
    );
  }

  async changeUserPassword(body: any, uid?: string) {
    return await http.patch(`/users/v1/update_settings/${uid}`, body);
  }
  // UPDATE USER
  async updateUserDetails(data: any, uid: any) {
    let form_data = new FormData();
    form_data.append("user", uid);
    form_data.append("firstName", data.firstName);
    form_data.append("lastName", data.lastName);
    form_data.append("nrc", data.nrc);
    form_data.append("country", data.country);
    form_data.append("city", data.city);
    form_data.append("zipCode", data.zipCode);
    form_data.append("address", data.address);
    form_data.append("facebookUrl", data.facebookUrl);
    form_data.append("instagramUrl", data.instagramUrl);
    form_data.append("twitterUrl", data.twitterUrl);
    form_data.append("linkedIn", data.linkedIn);
    form_data.append("phoneNumber", data.phoneNumber);
    return await http.patch(
      `/users/v1/everyday/update_profile/${uid}/tech`,
      form_data
    );
  }
  // ADD USER
  async submitUserDetails(data: any, uid: any) {
    let form_data = new FormData();
    form_data.append("user", uid);
    form_data.append("firstName", data.firstName);
    form_data.append("lastName", data.lastName);
    form_data.append("nrc", data.nrc);
    form_data.append("country", data.country);
    form_data.append("city", data.city);
    form_data.append("zipCode", data.zipCode);
    form_data.append("address", data.address);
    form_data.append("facebookUrl", data.facebookUrl);
    form_data.append("instagramUrl", data.instagramUrl);
    form_data.append("twitterUrl", data.twitterUrl);
    form_data.append("linkedIn", data.linkedIn);
    form_data.append("phoneNumber", data.phoneNumber);

    return await http.post(
      `/users/v1/everyday/update_profile/${uid}/tech`,
      form_data
    );
  }
  // logout user
  async logOut(body: any) {
    return await http.get(`/users/v1/logout`, body);
  }

  // get refresh token

  async refreshToken() {
    const body = {
      refresh: localStorage.getItem("refresh"),
    };
    return await http.post(`/users/v1/refresh_token`, JSON.stringify(body));
  }

  // GET USER
  async getUserDetails(form_data: any) {
    return await http.get<User>(`/users/v1/me`, form_data);
  }

  // INITIALIZE PAYMENT
  async initializePayment(
    phoneNumber: string,
    serviceName: string,
    orderId: any
  ) {
    const body = {
      serviceName: serviceName,
      phoneNumber: phoneNumber,
      order_id: orderId,
    };

    return await http.post(
      "api/v1/everyday/initialize_payment_request/tech",
      JSON.stringify(body)
    );
  }

  // UPLOAD AND UPDATE DOCUMENT
  async uploadDocuments(data: any) {
    return await httpFiles.post<any>("/userprofile/v1/everyday/client_documents/tech/", data);
  }
  async updateDocuments(data: any) {
    return await httpFiles.patch<any>("/userprofile/v1/everyday/client_documents/tech/", data);
  }
}

export default new ProductDataService();
