import React from 'react';
import LoadingSpinner from '../../../../components/widgets/spinner';
import { useGetMineralQuery } from '../../../../redux/slices/mineral_slice';
import { ErrorWidget } from '../../../utils/error_widget';
import BreadCrumbs from '../../../widgets/bread_crumbs';
import editIcon from "../../../../assets/img/icons/edit-5.svg";
import moment from 'moment';
import { IMineralReview, TMineral } from '../../../../redux/types/mineral.type';
import { formatPrice } from '../../../utils/format_price';
import { TMineralOrder } from '../../../../redux/types/mineral_order.type';
import { renderStars } from '../../../utils/render_star';
import EditMineralStatus from './edit_mineral_status';

const MineralProductDetails: React.FC = () => {
  const [show, setShow] = React.useState(false);
  const [mineralItem, setMineralItem] = React.useState<TMineral | any>();

  const queryParams = new URLSearchParams(window.location.search);
  const product_id = queryParams.get("product_id");

  const {
    data: product,
    isLoading: isFetching,
    error,
  } = useGetMineralQuery(Number(product_id));

  const handleClose = () => {
      setShow(false);
    };
  
    const handleShow = (mineral: TMineral) => {
      setMineralItem(mineral);
      setShow(true);
    };

  if (isFetching) {
    return (
      <div style={{ marginTop: "20%" }} className="text-center">
        <LoadingSpinner />
      </div>
    );
  }

  if (error) {
    return <ErrorWidget error={error} />;
  }

  if (!product) {
    return <div>No product found.</div>;
  }

  return (
    <>
      <EditMineralStatus
        mineral={mineralItem}
        show={show}
        setShow={setShow}
        handleClose={handleClose}
      />

      <div className="content">
        <BreadCrumbs
          title={"Mineral Product Details"}
          subTitle={"View/Manage Mineral Product Details"}
          button={
            <a
                className="mt-5 ml-4 btn btn-sm btn-info text-white p-2 rounded"
                onClick={() => handleShow(product)}
            >
               {product?.status} <img src={editIcon} alt="img" />
            </a>
        }
        />

        {/* Display Average Rating */}
        <div style={{ marginBottom: '20px' }}>
          <h3>Average Rating: {product.rating_avg}
          </h3>
          <div>
            {renderStars(product.rating_avg, 40)}
          </div>
        </div>

        <div className="row g-0">
          {/* Product Image */}
          <div className="col-md-4">
            <img
              src={`${process.env.REACT_APP_API_URL}${product.image}`}
              alt={product.productName}
              className="img-fluid rounded"
              style={{ height: '100%', objectFit: 'cover' }}
            />
          </div>

          {/* Product Details */}
          <div className="col-md-8">
            <div className="card-body">
              <h1 className="card-title display-10 text-capitalize">
                <b>{product.productName}</b>
              </h1>
              <p className="card-text text-muted">{product.description}</p>

              <div className="row mt-4">
                <div className="col-md-6">
                  <p className="mb-2">
                    <strong>Price:</strong> {formatPrice({currency: product.supplier?.company_currency.currency.currency_code, price: product.productPrice})}
                  </p>
                  <p className="mb-2">
                    <strong>Unit:</strong> {product.unit}
                  </p>
                  <p className="mb-2">
                    <strong>Category:</strong> {product.category.categoryName}
                  </p>
                  <p className="mb-2">
                    <strong>Status:</strong>{" "}
                    <span className={`badge ${product.status === 'Available' ? 'bg-success' : 'bg-warning'}`}>
                      {product.status}
                    </span>
                  </p>
                </div>
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-6">
                      <p className="mb-2">
                        <strong>Mineral ID:</strong> {product.mineral_id}
                      </p>
                    </div>
                    <div className="col-md-6">
                      <a
                        className="mt-5 ml-4 text-warning p-2 rounded"
                        onClick={() => handleShow(product)}
                      >
                        <img width={30} height={30} src={editIcon} alt="Edit" />
                      </a>
                    </div>
                  </div>
                  <p className="mb-2">
                    <strong>Total Sales:</strong> {product.mineral_order_product.length}
                  </p>
                  <p className="mb-2">
                    <strong>Created At:</strong> {moment(product.created_at).format("YYYY-MM-DD HH:mm a")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        
      </div>
    </>
  );
};

export default MineralProductDetails;