import { apiSlice } from "../services/apiSlice";
import { TComplaint } from "../types/complaints.type";

export const complaintSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createComplaint: builder.mutation<TComplaint, Partial<TComplaint>>({
      query(data) {
        return {
          url: `/support/v1/everyday/complaint_support/tech/`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["TComplaint"],
      transformResponse: (result: { item: TComplaint }) =>
        result.item,
    }),
    updateComplaint: builder.mutation<TComplaint, Partial<TComplaint>& Pick<TComplaint, 'id'>>({
      query: ({ id, ...patch }) => ({
        url: `/support/v1/everyday/complaint_support/tech/${id}/`,
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: ["TComplaint"],
      transformResponse: (response: { item: TComplaint }) =>
        response.item,
    }),
    getComplaint: builder.query<TComplaint, number>({
      query: (id) => ({
        url: `/support/v1/everyday/complaint_support/tech/${id}/`,
      }),
      providesTags: ["TComplaint"],
    }),
    getComplaintList: builder.query<TComplaint[], void>({
      query: () => `/support/v1/everyday/complaint_support/tech/`,
      keepUnusedDataFor: 5,
    }),
    deleteComplaint: builder.mutation<TComplaint, number>({
      query: (id) => ({
        url: `/support/v1/everyday/complaint_support/tech/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["TComplaint"],
    }),
  }),
});
export const {
  useCreateComplaintMutation,
  useDeleteComplaintMutation,
  useGetComplaintListQuery,
  useGetComplaintQuery,
  useUpdateComplaintMutation,
} = complaintSlice;
