import React, { useRef } from "react";
import moment from "moment";
import { useGetFuelQuery } from "../../../../redux/slices/fuel_slice";
import {
  IFuelReview,
  TFuelModel,
  TFuelOrderModel,
} from "../../../../redux/types/fuel_mode.type";
import BreadCrumbs from "../../../widgets/bread_crumbs";
import Filters from "../../widgets/filters";
import LoadingSpinner from "../../../../components/widgets/spinner";
import { ErrorWidget } from "../../../utils/error_widget";
import FuelOrderChart from "./stats";
import { formatPrice } from "../../../utils/format_price";
import { renderStars } from "../../../utils/render_star";

const FuelDeals: React.FC = () => {
  const divRef = useRef<HTMLDivElement>(null);
  const queryParams = new URLSearchParams(window.location.search);
  const fuel_id = queryParams.get("fuel_id");
  const [query, setQuery] = React.useState("")
  const [fromDate, setFromDate] = React.useState("");
    const [toDate, setToDate] = React.useState("");
  
    // Handler for date filter change
    const onDateFilter = (fromDate: string, toDate: string) => {
      setFromDate(fromDate);
      setToDate(toDate);
      // Call your API or perform filtering here
      console.log("Filtering from:", fromDate, "to:", toDate);
    };
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setQuery(newValue);
  }

  const {
    data: fuel,
    isLoading,
    error,
  }: TFuelModel | any = useGetFuelQuery(Number(fuel_id));

  if (isLoading) {
    return (
      <div style={{ marginTop: "20%" }} className="text-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="content">
      <BreadCrumbs
        title={"Fuel Details"}
        subTitle={"View/Manage Fuel Details"}
        button={
          <a href={`/zm/dashboard/fuel_product_details/?product_id=${fuel_id}`} className="btn btn-sm btn-info text-white">
          {fuel?.status} <i className="fa fa-arrow-right"></i>
        </a>
        }
      />
      <div className="card">
        <div className="card-body">
        <Filters divRef={divRef} query={query} onSearchChange={onChange} onDateFilter={onDateFilter}/>
          {error ? <ErrorWidget error={error}/>: <> 
          
          <div className="mb-2">
        <h3 className="mt-3 mb-2 text-center text-capitalize">
          <span className="text-primary">{fuel?.fuel_name}</span>
        </h3>
        <div className="text-center text-capitalize">
          <h4><b>{fuel?.supplier.company_name}</b></h4>
        </div>
        <FuelOrderChart transaction_id={fuel_id} />
        <h1>Transactions ({fuel?.fuel_order_product.length}) </h1>
      </div>
      {fuel?.fuel_order_product.length === 0 ? (
        <div className="alert alert-info text-center">
          <h3>No Transactions Found</h3>
        </div>
      ) : (
        <div ref={divRef} className="table-responsive mt-4">
          <table className="table datanew">
            <thead>
              <tr>
                <th>#</th>
                <th>Customer</th>
                <th>Amount</th>
                <th>Quantity</th>
                <th>Status</th>
                <th>Payment</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {fuel.fuel_order_product.map(
                (item: TFuelOrderModel, index: number) => {
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td className="text-capitalize">
                        {item.customer.first_name} {item.customer.last_name}
                      </td>
                      <td>{formatPrice({currency: item.supplier.company_currency.currency.currency_code, price: item.productPrice})}</td>
                      <td>{item.quantity}/{item.unit}</td>
                      <td>
                      <span className={`badges ${item.status !== "CONFIRMED" ? "bg-lightred" : "bg-lightgreen"}`}>
                        {item.status}
                      </span>
                    </td>
                    <td>
                      <span className="badges bg-lightgreen">
                        Paid
                      </span>
                    </td>
                      <td>{moment(item.created_at).format("Do MMMM, YYYY")}</td>
                    </tr>
                  );
                }
              )}
            </tbody>
          </table>
        </div>
      )}</>}
      {/* Product Reviews Section */}
      <div className="mt-4">
          <h3 className="h5"><b>Product Reviews</b></h3>
          {fuel.fuel_product_review.length > 0 ? (
            <div className="list-group">
              {fuel.fuel_product_review.map((review: IFuelReview, index: number) => (
                <div key={index} className="list-group-item">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <strong>{review.user.first_name} {review.user.last_name}</strong>
                      <span className="text-warning ms-2">
                        {renderStars(review.rating, 20)} {review.rating}
                      </span>
                    </div>
                    <small className="text-muted">
                      {moment(review.created_at).format("YYYY-MM-DD HH:mm a")}
                    </small>
                  </div>
                  <p className="mt-2 mb-0">{review.review}</p>
                </div>
              ))}
            </div>
          ) : (
            <div className="text-center">
              <h4 className="text-muted">No reviews yet.</h4>
            </div>
          )}
        </div>
        </div>
      </div>
    </div>
  );
};

export default FuelDeals;
