import React from 'react'
import { User } from '../../../../../redux/types/user';
import { useGetUserQuery } from '../../../../../redux/slices/usersApiSlice';
import LoadingSpinner from '../../../../../components/widgets/spinner';
import BreadCrumbs from '../../../../widgets/bread_crumbs';
import editIcon from "../../../../../assets/img/icons/edit-5.svg";
import { useGetAdminCarsQuery } from '../../../../../redux/slices/admin_hire_slice';
import EditAccount from '../crud/edit_account';
import CompanySummary from './summary';
import { ICarRental } from '../../../../../redux/types/car_rental.type';
import moment from 'moment';
import Pagination from '../../../../pagination/pagination_items';

const CarCompanyDetails: React.FC = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const company_id = queryParams.get("company_id");
    const [show, setShow] = React.useState(false);
    const [user, setUser] = React.useState<User | any>();
    const [query, setQuery] = React.useState("")
    const [currentPage, setCurrentPage] = React.useState(1);
    const [fromDate, setFromDate] = React.useState("");
    const [toDate, setToDate] = React.useState("");

    const handleClose = () => {
      setShow(false);
    };
  
    const handleShow = (user: User) => {
      setUser(user);
      setShow(true);
    };
  
    const {
      data: userDetails,
      isLoading: isFetching,
      error,
    }: User | any = useGetUserQuery(Number(company_id));

    const {
      data: carList,
      isLoading,
    }: ICarRental | any = useGetAdminCarsQuery({
      company: company_id,
      searchQuery: query, 
      page: currentPage, 
      status: '', 
      label: "",
      fromDate: fromDate,
      toDate: toDate,
    });
    if (isFetching || isLoading) {
      return (
        <div style={{ marginTop: "20%" }} className="text-center">
          <LoadingSpinner />
        </div>
      );
    }
  return (
    <>
    <EditAccount
        title={"Update User Account"}
        accountType={"CARHIRE"}
        user={userDetails}
        show={show}
        handleClose={handleClose}
        setShow={setShow}
      />
    <div className='content'>
        <BreadCrumbs 
        title={'Company Details'} 
        subTitle={`View ${userDetails?.company_name}`} 
        button={
          <a className="ml-5" onClick={() => handleShow(user)}>
            <img width={40} height={40} src={editIcon} alt="img" />
          </a>
        }
        />
        <CompanySummary user={userDetails} totalSales={carList.total_sales} />
        {/* <div className="row">
          <div className="col-md-12">
            <h3>Monthly Sales</h3>
            <BranchCharts width={1000} height={250} data={monthlyData} />
          </div>
        </div> */}
        <div className="col-md-12">
        
        <div className="card mb-5 mb-md-0">
        <h4 className='m-3 pr-2 text-capitalize'><b>Cars For {userDetails?.company_name}</b></h4>
          {carList.results.length === 0 ? (
            <center>
              <div
                className="text-center alert alert-info mt-3"
                role="alert"
                style={{ width: "fit-content" }}
              >
                <h1 className="mt-3">NO CARS FOUND</h1>
              </div>
            </center>
          ) : (
            <div className="table-responsive dataview">
              <table className="table datatable">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Car</th>
                    <th>Company</th>
                    <th>Brand Name</th>
                    <th>Sales</th>
                    <th>Status</th>
                    <th>Posted Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {carList.results.map((item: ICarRental, index: number)=>{
                    return <tr>
                    <td>{index + 1}</td>
                    
                    <td className="productimgname">
                      <a className="product-img" href={`/zm/dashboard/car_details/?car_id=${item.id}`}>
                        <img
                        className='rounded'
                          src={`${item.car_images[0].image}`}
                          alt="product"
                        />
                      </a>
                      <a href={`/zm/dashboard/car_details/?car_id=${item.id}`}>{item.car_model.substring(0, 20)}{item.car_model.length > 20 ? "...": ""}</a>
                    </td>
                    <td>
                      <a className='text-capitalize' href="javascript:void(0);">{item.user.company_name}</a>
                    </td>
                    <td className='text-capitalize'>{item.car_brand.brand_name}</td>
                    <td>{item.car_user_rental.length}</td>
                    <td>{item.status}</td>
                    <td>{moment(item.created_at).format("Do MMM YYYY")}</td>
                    <td>
                      <a href={`/zm/dashboard/car_details/?car_id=${item.id}`}><i className='fa fa-arrow-right'></i></a>
                      <a className='m-3' href={`/zm/dashboard/car_transactions/?car_id=${item.id}`}><i className='fa fa-eye'></i></a>
                    </td>
                  </tr>
                  })}
                </tbody>
              </table>
              <div className="mt-4">
                <Pagination
                  currentPage={currentPage}
                  lastPage={carList.total_pages??0}
                  maxLength={7}
                  setCurrentPage={setCurrentPage}
                />
                </div>
            </div>
          )}
        </div>
      </div>
    </div>
    </>
  )
}

export default CarCompanyDetails