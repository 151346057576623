import React, { useRef } from "react";
import { IMineralReview, TMineral } from "../../../../redux/types/mineral.type";
import LoadingSpinner from "../../../../components/widgets/spinner";
import { useGetMineralQuery } from "../../../../redux/slices/mineral_slice";
import BreadCrumbs from "../../../widgets/bread_crumbs";
import { TMineralOrder } from "../../../../redux/types/mineral_order.type";
import moment from "moment";
import Filters from "../../widgets/filters";
import MineralOrderChart from "./stats";
import { ErrorWidget } from "../../../utils/error_widget";
import { renderStars } from "../../../utils/render_star";

const MineralTransactions: React.FC = () => {
  const divRef = useRef<HTMLDivElement>(null);
  const queryParams = new URLSearchParams(window.location.search);
  const mineral_id = queryParams.get("mineral");
  const [query, setQuery] = React.useState("")
  
  const [fromDate, setFromDate] = React.useState("");
    const [toDate, setToDate] = React.useState("");
  
    // Handler for date filter change
    const onDateFilter = (fromDate: string, toDate: string) => {
      setFromDate(fromDate);
      setToDate(toDate);
      // Call your API or perform filtering here
      console.log("Filtering from:", fromDate, "to:", toDate);
    };
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setQuery(newValue);
  }
  
  const {
      data: mineral,
      isLoading: isFetching,
      error,
    }: TMineral | any = useGetMineralQuery(Number(mineral_id));
  
    if (isFetching) {
      return (
        <div style={{ marginTop: "20%" }} className="text-center">
          <LoadingSpinner />
        </div>
      );
    }
    if (error) {
      return (
        <center>
          <ErrorWidget error={error}/>
        </center>
      );
    }

  return (
    <div className="content">
      <BreadCrumbs
        title={"Mineral Transactions"}
        subTitle={"View/Manage Mineral Transactions"}
        button={
          <a href={`/zm/dashboard/mineral_product_details/?product_id=${mineral_id}`} className="btn btn-sm btn-info text-white">
          {mineral?.status} <i className="fa fa-arrow-right"></i>
        </a>
        }
      />
      
      <div className="card">
        <div className="card-body">
        <Filters divRef={divRef} query={query} onSearchChange={onChange} onDateFilter={onDateFilter} />
        <div className='mb-2'>
            <h3 className="mt-3 mb-2 text-center text-capitalize">
              <span className="text-primary">{mineral?.productName}</span>
            </h3>
            <div className="text-center text-capitalize">
            <h4>{mineral?.supplier.company_name}</h4>
            </div>
            <MineralOrderChart transaction_id={mineral_id} />
            <h1>Transactions ({mineral?.mineral_order_product.length}) </h1>
        </div>
        {mineral?.mineral_order_product.length === 0 ? <div className="alert alert-info text-center">
            <h3>No Transactions Found</h3>
        </div>:
        <div ref={divRef} className="table-responsive dataview">
            <table className="table datatable">
                <thead>
                    <tr>
                    <th>#</th>
                    <th>Client</th>
                    <th>Product Amount</th>
                    <th>Amount</th>
                    <th>Unit</th>
                    <th>Quantity</th>
                    <th>Received</th>
                    <th>Status</th>
                    <th>Payment</th>
                    <th>Date</th>
                    {/* <th>Action</th> */}
                    </tr>
                </thead>
                <tbody>
                    {mineral?.mineral_order_product.map((item: TMineralOrder, index: number)=>{
                    return <tr key={index}>
                    <td>{index + 1}</td>
                    <td className="productimgname">
                        <a href={`/zm/dashboard/car_details/?car_id=${item.id}`}>{item.customer.first_name}{item.customer.last_name}</a>
                    </td>
                    <td>{Number(mineral?.productPrice).toLocaleString()}</td>
                    <td>{Number(item.productPrice).toLocaleString()}</td>
                    <td>{item.unit}</td>
                    <td>{item.quantity}</td>
                    <td>{item.customer_recieved ? "Yes": "No"}</td>
                    <td>
                      <span className={`badges ${item.status !== "CONFIRMED" ? "bg-lightred" : "bg-lightgreen"}`}>
                        {item.status}
                      </span>
                    </td>
                    <td>
                      <span className="badges bg-lightgreen">
                        Paid
                      </span>
                    </td>
                    <td>{moment(item.created_at).format("Do MMM YYYY")}</td>
                    {/* <td>
                      <a href={`/zm/dashboard/car_details/?car_id=${item.id}`}><i className='fa fa-arrow-right'></i></a>
                    </td> */}
                    </tr>
                    })}
                </tbody>
            </table>
        </div>
        }
        {/* Product Reviews Section */}
        <div className="mt-4">
          <h3 className="h5"><b>Product Reviews</b></h3>
          {mineral.mineral_product_review?.length > 0 ? (
            <div className="list-group">
              {mineral.mineral_product_review.map((review: IMineralReview, index: number) => (
                <div key={index} className="list-group-item">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <strong>{review.user.first_name} {review.user.last_name}</strong>
                      <span className="text-warning ms-2">
                        {renderStars(review.rating, 20)} {review.rating}
                      </span>
                    </div>
                    <small className="text-muted">
                      {moment(review.created_at).format("YYYY-MM-DD HH:mm a")}
                    </small>
                  </div>
                  <p className="mt-2 mb-0">{review.review}</p>
                </div>
              ))}
            </div>
          ) : (
            <div className="text-center">
              <h4 className="text-muted">No reviews yet.</h4>
            </div>
          )}
        </div>
        </div>
      </div>
    </div>
  );
};

export default MineralTransactions;
