import React from "react";
import DashboardStats from "./dashboard/welcome_stats";
import { useGetCompaniesListQuery } from "../../../redux/slices/authApiSlice";
import { User } from "../../../redux/types/user";
import { TAgroOrder } from "../../../redux/types/agro_order.type";
import { TMineralOrder } from "../../../redux/types/mineral_order.type";
import { ICarBooking } from "../../../redux/types/car_booking.type";
import {
  useWelcomeAgroQuery,
  useWelcomeCarBookingQuery,
  useWelcomeInvestmentDonationsQuery,
  useWelcomeMineralOrdersQuery,
} from "../../../redux/slices/admin_welcome_slice";
import { TInvestmentDonation } from "../../../redux/types/investment_donations.type";
import AgroOrdersChart from "./agriculture/stats";
import MineralOrderChart from "./minerals/stats";
import FuelOrderChart from "./fuels/stats";
import MiningConstructionOrderChart from "./construction/stats";

const Dashboard: React.FC = () => {
  const {
    data: agroList,
    isLoading,
    error,
  }: User | any = useGetCompaniesListQuery({ user_type: "AGRO", status: ""});
  const { data: mineralList, isLoading: fetchingMinerals }: User | any =
    useGetCompaniesListQuery({
      user_type: "MINERAL",
      status: ""
    });
  const { data: rentalList, isLoading: fetchingRentals }: User | any =
    useGetCompaniesListQuery({
      user_type: "CARHIRE",
      status: ""
    });
  const { data: investmentList, isLoading: fetchingInvestments }: User | any =
    useGetCompaniesListQuery({
      status: "",
      user_type: "INVESTMENT",
    });
  const { data: estateList, isLoading: fetchingEstates }: User | any =
    useGetCompaniesListQuery({
      user_type: "REALESTATE",
      status: ""
    });
  const { data: customers, isLoading: fetchingCustomers }: User | any =
    useGetCompaniesListQuery({
      user_type: "CUSTOMER",
      status: ""
    });

  //CONFIRMED AGRO ORDERS
  const { data: confirmedAgro, isLoading: isFetchingAgro }: TAgroOrder | any =
    useWelcomeAgroQuery();

  // CONFIRMED MINERAL ORDERS
  const {
    data: confirmedMinerals,
    isLoading: isFetchingMinerals,
  }: TMineralOrder | any = useWelcomeMineralOrdersQuery();

  // CONFIRMED CAR RENTALS ALES
  const { data: confirmCars, isLoading: fetchingCars }: ICarBooking | any =
    useWelcomeCarBookingQuery();
  // INVESTMENT Donations
  const {
    data: investmentDonations,
    isLoading: fetchingDonations,
  }: TInvestmentDonation | any = useWelcomeInvestmentDonationsQuery();
  return (
    <>
      <div className="content">
        {isLoading ||
        fetchingMinerals ||
        fetchingRentals ||
        fetchingInvestments ||
        fetchingEstates ||
        fetchingCustomers ||
        isFetchingAgro ||
        isFetchingMinerals ||
        fetchingCars ||
        fetchingDonations ? (
          <center>
            <div className="text-center">
              <i className="fa fa-spinner fa-spin fa-5x text-danger"></i>
            </div>
          </center>
        ) : error ? (
          <div className="text-center">
            <i style={{ fontSize: "50px" }} className="ti-info"></i>
            <h2>Loading Application Error</h2>
          </div>
        ) : (
          <DashboardStats
            agroCompanies={agroList.length}
            mineralCompanies={mineralList.length}
            rentalCompanies={rentalList.length}
            investmentCompanies={investmentList.length}
            estateCompanies={estateList.length}
            customers={customers.length}
            confirmedAgro={confirmedAgro}
            confirmedMinerals={confirmedMinerals}
            confirmedCars={confirmCars}
            investmentDonations={investmentDonations}
          />
        
        )}
        <div className="mt-5 mb-5 row">
          <div className="col-md-6">
            <h3>Agriculture Sales</h3>
            <AgroOrdersChart/>
          </div>
          <div className="col-md-6">
            <h3>Mineral Sales</h3>
            <MineralOrderChart />
          </div>
          <div className="col-md-6">
            <h3>Fuel Sales</h3>
            <FuelOrderChart />
          </div>
          <div className="col-md-6">
            <h3>Mining & Construction Sales</h3>
            <MiningConstructionOrderChart />
          </div>
        </div>
        
        {/* <Calendar /> */}
      </div>
     
    </>
  );
};

export default Dashboard;
