import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query";
import { setAuth, logout } from "../features/authSlice";
import { Mutex } from "async-mutex";

const mutex = new Mutex();

export const apiUrl = process.env.REACT_APP_API_URL;

const baseQuery = fetchBaseQuery({
  baseUrl: apiUrl,
  credentials: "same-origin",
  prepareHeaders: (headers) => {
    const accessToken = localStorage.getItem("access");
    if (accessToken) {
      headers.set("authorization", `JWT ${accessToken}`);
      // headers.set("Content-Type", "multipart/form-data");
      headers.set("Content-Type", "application/json");
    }else{
      headers.set("Content-Type", "application/json");
    }

    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      const refreshToken = JSON.stringify({
        refresh: localStorage.getItem("refresh"),
      });
      try {
        const refreshResult = await baseQuery(
          {
            url: "/auth/jwt/refresh/",
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${localStorage.getItem("access")}`,
            },
            body: {refresh: refreshToken}
          },
          api,
          extraOptions
        );
        if (refreshResult.data) {
          api.dispatch(setAuth());
          result = await baseQuery(args, api, extraOptions);
        } else {
          api.dispatch(logout());
        }
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["tag", "TAgriculture", "TFuelModel", 
    "TFuelOrderModel", "TDealSecureTransactionModel", 
    "PropertyModel", "TInvestment", "TInvestmentWithdraw", 
    "TInvestmentDonation", "TAgroOrder", "ICarBrand", 
    "User", "ICarImages", "IUserProfile", "TComplaint",
    "TConstruction", "TMineralCagetory", "TMineral", 
    "TMineralOrder",  "ICarRental", "ICarBooking", 
    "IUserProfile", "NotificationModel", "RequestsModel", 
    "CreateRequestsModel", "TConstructionMiningOrders", 
    "TCountyCurrency", "IMessage", "SubscriptionPackage",
    "IPropertyPayments", "ICarViewRequest", "TAds", "TClientDocs"],
  endpoints: (builder) => ({}),
});
