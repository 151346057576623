import React, { useState } from 'react';
import plusIcon from "../../../../assets/img/icons/plus.svg";
import editIcon from "../../../../assets/img/icons/edit-5.svg";
import BreadCrumbs from '../../../widgets/bread_crumbs';
import LoadingSpinner from '../../../../components/widgets/spinner';
import { TAds } from '../../../../redux/types/ads.type';
import { useGetAdsListQuery } from '../../../../redux/slices/ads_slice';
import AdModal from './create_update_add';
import { ErrorWidget } from '../../../utils/error_widget';
import Pagination from '../../../pagination/pagination_items';
import httpDashboardServices from "../../../services/http.product.services";
import { toast } from 'react-toastify';

const AdsList: React.FC = () => {
  const [query, setQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1);
  const [status, setStatus] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAd, setSelectedAd] = useState<TAds | null>(null); // Store the ad being edited

  const onStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => setStatus(e.target.value);
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => setQuery(e.target.value);

  const { data: adsList, isLoading: fetchingData, refetch, error }: TAds | any = useGetAdsListQuery({
    status, 
    searchQuery: query, 
    page: currentPage,
  });

  // Open modal for adding a new ad
  const handleAddAd = () => {
    setSelectedAd(null); // No ad selected (creating a new one)
    setIsModalOpen(true);
  };

  // Open modal for editing an existing ad
  const handleEditAd = (ad?: TAds) => {
    setSelectedAd(ad || null);
    setIsModalOpen(true);
  };

  // Close modal
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedAd(null);
  };

  // Handle form submission
  const handleSubmitAd = async (formData: any) => {
    if (selectedAd) {
      // ✅ Include ID in the update request
    const updatedData = { ...formData, id: selectedAd.id };
      await httpDashboardServices.updateAd(updatedData)
        .then(() => {
          setIsLoading(false);
          refetch();
          handleCloseModal();
          toast.success("Ad Updated Successfully");
        })
        .catch((err: any) => {
          setIsLoading(false);
          console.error(err.message);
          toast.error("Error :" + err.message);
        });
    } else {
      // Call API to create ad
      await httpDashboardServices.createAd(formData)
        .then(() => {
          setIsLoading(false);
          refetch();
          handleCloseModal();
          toast.success("Ad Created Successfully");
        })
        .catch((err: any) => {
          setIsLoading(false);
          console.error(err.message);
          toast.error("Error :" + err.message);
        });
    }
    
  };

  if (fetchingData) {
    return (
      <div style={{ marginTop: "20%" }} className="text-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <>
      {/* Ad Modal */}
      <AdModal 
        isOpen={isModalOpen} 
        onClose={handleCloseModal} 
        onSubmit={handleSubmitAd} 
        isLoading={isLoading}
        initialData={selectedAd || undefined} 
      />

      <div className='content'>
        <BreadCrumbs
          title="Ads List"
          subTitle="Manage Ads Products"
          button={<button className="btn btn-added" onClick={handleAddAd}>
           <img src={plusIcon} alt="img" /> Create an Ad
            </button>}
        />

        {/* List of Ads */}
        <div className='card'>
          <div className="card-body">
          {error ? (
            <ErrorWidget error={error}/>
          ) : adsList.results.length === 0 ? <div className='alert alert-info text-center'>
            <i className='fa fa-ad fa-5x'></i>
            <h3><b>No Ads Found</b></h3>
          </div> :
          <>
          
            <div className="row">
              {
                adsList?.results?.map((ad: TAds) => (
                  <div key={ad.id} className="col-md-3">
                    <img style={{width: "100%", height: "20vh"}} src={ad.image} alt={ad.titleName} className='img-fluid rounded' />
                    <small className='text-capitalize'>{ad.titleName}</small>
                    <p>{ad.status}</p>
                    <button className="btn btn-sm btn-primary" onClick={() => handleEditAd(ad)}>
                    <img className='text-white' src={editIcon} alt="img" /> Edit
                      </button>
                  </div>
                ))
              }
            </div>
          
          <Pagination
              currentPage={currentPage}
              lastPage={adsList.total_pages}
              maxLength={7}
              setCurrentPage={setCurrentPage}
            />
          </>
          }
          </div>
          
        </div>
      </div>
    </>
  );
};

export default AdsList;
