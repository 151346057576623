import React from "react";
import { useGetInvestmentQuery } from "../../../../redux/slices/investment_slice";
import { TInvestment } from "../../../../redux/types/investment.type";
import { TInvestmentDonation } from "../../../../redux/types/investment_donations.type";
import moment from "moment";
import InvestmentStats from "./investment_stats";
import BreadCrumbs from "../../../widgets/bread_crumbs";
import LoadingSpinner from "../../../../components/widgets/spinner";
import { formatPrice } from "../../../utils/format_price";
import EditInvestmentStatus from "./edit_mineral_status";

const InvestmentDetails: React.FC = () => {
  const [show, setShow] = React.useState(false);
    const [investmentItem, setInvestmentItem] = React.useState<TInvestment | any>();
  const queryParams = new URLSearchParams(window.location.search);
  const project_id = queryParams.get("project_id");
    const handleClose = () => {
      setShow(false);
    };
  
    const handleShow = (mineral: TInvestment) => {
      setInvestmentItem(mineral);
      setShow(true);
    };
  const {
    data: investment,
    isLoading,
    error,
  }: TInvestment | any = useGetInvestmentQuery(Number(project_id));

  if (isLoading) {
    return (
      <div style={{ marginTop: "20%" }} className="text-center">
        <LoadingSpinner />
      </div>
    );
  }

  const findDayDifference = (date1: any, date2: any) => {
    // always round down
    return Math.floor(Math.abs(date1 - date2) / (1000 * 60 * 60 * 24));
  };

  return (
    <div className="content">
      <BreadCrumbs
        title={"Investment Details"}
        subTitle={"View/Manage Investment Details"}
      />
      <EditInvestmentStatus
        investment={investmentItem}
        show={show}
        setShow={setShow}
        handleClose={handleClose}
      />
      {error ? <div className="alert alert-danger">{error.message}</div> : <>
      <button onClick={()=> handleShow(investment)} className="btn btn-sm btn-info text-white mb-3">
          {investment?.status} <i className="fa fa-edit"></i>
        </button>
        <div className="row">
        <div className="col-md-3">
          <img
            className="img-responsive rounded"
            style={{width: "100%", height: "150px"}}
            src={`${process.env.REACT_APP_API_URL}${investment?.thumbnail}`}
            alt={investment.project_title}
          />
          <h3 className="text-capitalize">
            <b>{investment.project_title}</b>
          </h3>
        </div>
        <div className="col-md-9">
          <div className="row">
            <div className="col-md-4 col-sm-6 col-xs-12 mb-3">
              <h3>Organizer</h3>
              <h5 className="text-capitalize">
                <b>{investment.project_organizer}</b>
              </h5>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12 mb-3">
              <h3>Beneficiary</h3>
              <h5 className="text-capitalize">
                <b>{investment.project_beneficiary}</b>
              </h5>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12 mb-3">
              <h3>Category</h3>
              <h5 className="text-uppercase">
                <b>{investment.project_category}</b>
              </h5>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12 mb-3">
              <h3>Raising Go</h3>
              <h5 className="text-uppercase">
                <b>{formatPrice({currency: investment.user.company_currency.currency.currency_code, price: investment.project_amount})}</b>
              </h5>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12 mb-3">
              <h3>Raised</h3>
              <h5 className="text-uppercase">
                <b>
                  {formatPrice({currency: investment.user.company_currency.currency.currency_code, price: investment.total_donated_avg})}
                </b>
              </h5>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12 mb-3">
              <h3>Country</h3>
              <h5 className="text-capitalize">
                <b>{investment.country}</b>
              </h5>
            </div>
            <div className="text-center">
              {moment().format("YYYY-MM-D") === investment.project_days ||
              moment().format("YYYY-MM-D") > investment.project_days ? (
                <h2 className="text-warning">
                  <b><i className="fa fa-heart"></i> Closed</b>
                </h2>
              ) : (
                <h2 className="text-capitalize text-dark">
                  <b>
                  {findDayDifference(
                    new Date(moment().format(investment.project_days)),
                    new Date(moment().format("YYYY-MM-D"))
                  )}{" "}
                  day(s) remaining
                  </b>
                </h2>
              )}
              <div style={{height: '180px'}}>
              <InvestmentStats total={investment.project_amount} value={investment.total_donated_avg} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="project-details">
        <h5 className="mt-3"><b>Project Description</b></h5>
        <blockquote>
        <p>{investment.project_description}</p>
      </blockquote>
      </div>
      <div className="table-responsive mt-4">
        {investment.donations.length === 0 ? (
          <center className="p-3">
            <i className="fa fa-calendar-day fa-5x"></i>
            <h4>
              <b>No Data Found</b>
            </h4>
          </center>
        ) : (
          <>
            <h2>
              <b>Project Donations</b>
            </h2>
            <table className="table datanew">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Sponsor</th>
                  <th>E-mail</th>
                  <th>Phone Number</th>
                  <th>Amount</th>
                  <th>Date</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {investment.donations.map(
                  (item: TInvestmentDonation, index: number) => {
                    return (
                      <tr>
                        <td>{index + 1}</td>
                        <td className="text-capitalize">
                          {item.first_name} {item.last_name}
                        </td>
                        <td>{item.email}</td>
                        <td>{item.phone_no}</td>
                        <td>
                          {formatPrice({currency: investment.user.company_currency.currency.currency_code, price: item.donated_amount})}
                        </td>
                        <td>
                          {moment(item.created_at).format("Do MMMM, YYYY")}
                        </td>
                        <td>
                          <div className="status-toggle d-flex justify-content-between align-items-center">
                            <input
                              type="checkbox"
                              id="user1"
                              className="check"
                              checked={true}
                            />
                            <label htmlFor="user1" className="checktoggle">
                              checkbox
                            </label>
                          </div>
                        </td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
          </>
        )}
      </div>
      </>}
    </div>
  );
};

export default InvestmentDetails;
