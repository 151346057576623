import { apiSlice } from "../services/apiSlice";
import { TConstruction } from "../types/construction.type";

export const constructionAPISlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createConstruction: builder.mutation<TConstruction, Partial<TConstruction>>({
      query(data) {
        return {
          url: `/administrator/v1/everyday/admin_construction/tech/`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["TConstruction"],
      transformResponse: (result: { item: TConstruction }) =>
        result.item,
    }),
    updateConstruction: builder.mutation<TConstruction, Partial<TConstruction>& Pick<TConstruction, 'id'>>({
      query: ({ id, ...patch }) => ({
        url: `/administrator/v1/everyday/admin_construction/tech/${id}/`,
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: ["TConstruction"],
      transformResponse: (response: { item: TConstruction }) =>
        response.item,
    }),
    getConstruction: builder.query<TConstruction, number>({
      query: (id) => ({
        url: `/administrator/v1/everyday/admin_construction/tech/${id}/`,
      }),
      providesTags: ["TConstruction"],
    }),
    getConstructionList: builder.query<TConstruction[], any>({
      query: ({searchQuery, product_type, page, supplier, fromDate, toDate, status}) => `/administrator/v1/everyday/admin_construction/tech/?search=${searchQuery}&product_type=${product_type}&page=${page}&supplier__id=${supplier}&from_date=${fromDate}&to_date=${toDate}&status=${status}`,
      // transformResponse: (res: TConstruction[]) =>
      //   res.sort((a, b) => b.id - a.id),
      //   providesTags: (result) =>
      //   result ? result.map(({ id }) => ({ type: 'TConstruction', id })) : ['TConstruction'],
      // keepUnusedDataFor: 5,
    }),
    deleteConstruction: builder.mutation<TConstruction, number>({
      query: (id) => ({
        url: `/administrator/v1/everyday/admin_construction/tech/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["TConstruction"],
    }),
  }),
});
export const {
  useCreateConstructionMutation,
  useDeleteConstructionMutation,
  useGetConstructionListQuery,
  useGetConstructionQuery,
  useUpdateConstructionMutation,
} = constructionAPISlice;
