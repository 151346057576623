import moment from 'moment';
import React from 'react'
import { User } from '../../../../redux/types/user';
import userPic from "../../../../assets/img/icons/users1.svg";
import { TComplaint } from '../../../../redux/types/complaints.type';
import { useForm } from 'react-hook-form';
import { useGetComplaintQuery, useUpdateComplaintMutation } from '../../../../redux/slices/complaint_slice';
import { useAppSelector } from '../../../../hooks/redux-hooks';
import { Spinner } from 'react-bootstrap';


interface MessageBoxProps {
  message: TComplaint;
  selectedUser: User;
}

const MessageBox: React.FC<MessageBoxProps> = ({ message, selectedUser }) => {
  const user = useAppSelector((state)=> state.auth.user);
  const [updateComplaint, { isLoading }] = useUpdateComplaintMutation();
    const {
      data: messageList,
      isLoading: isFetching,
      error,
      refetch,
    }: TComplaint | any = useGetComplaintQuery(message.id);
  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [message]);

    const {
      register,
      handleSubmit,
      reset,
      formState: { errors },
    } = useForm();
  
    const onSubmit = handleSubmit(async (data) => {

      const response = {
        user: selectedUser.id,
        agent: user?.id,
        complaint_text: message.complaint_text,
        complaint_title: message.complaint_title,
        location_city: message.location_city,
        location_state: message.location_state,
      }
      
      // updateComplaint(response).unwrap()
      //   .then(() => {
      //     reset();
      //     refetch();
      //   }).catch(() => {});
    });

    if (isFetching) {
      return (
        <center>
          <Spinner />
        </center>
      );
    }

  return (
    <div className="col-lg-7 col-xl-8 chat-cont-right">
      <div className="card mb-0">
        <div className="card-header msg_head">
          <div className="d-flex bd-highlight">
            <a
              id="back_user_list"
              href="javascript:void(0)"
              className="back-user-list"
            >
              <i className="fas fa-chevron-left"></i>
            </a>
            <div className="img_cont">
              <img
                className="rounded-circle user_img"
                src={userPic}
                alt={selectedUser.first_name}
              />
            </div>
            <div className="user_info">
              <span>
                <strong id="receiver_name">{selectedUser.first_name} {selectedUser.last_name}</strong>
              </span>
              <p className="mb-0">Messages</p>
            </div>
          </div>
        </div>
        <div className="card-body msg_card_body chat-scroll">
          <ul className="list-unstyled">
              <li
                className={`media ${messageList.user.id.toString() === 'currentUser' ? 'sent' : 'received'} d-flex`}
              >
                <div className="avatar flex-shrink-0">
                  <img
                    src={userPic}
                    alt="User Image"
                    className="avatar-img rounded-circle"
                  />
                </div>
                <div className="media-body flex-grow-1">
                  <div className="msg-box">
                    <div>
                      <p>{messageList.complaint_text}</p>
                      {messageList.complaint_image && (
                        <div className="chat-msg-attachments">
                          <div className="chat-attachment">
                            <img
                              src={messageList.complaint_image}
                              alt="Attachment"
                              className="img-fluid"
                            />
                            <a href="" className="chat-attach-download">
                              <i className="fas fa-download"></i>
                            </a>
                          </div>
                        </div>
                      )}
                      <ul className="chat-msg-info">
                        <li>
                          <div className="chat-time">
                            <span>{moment(messageList.created_at).format('h:mm A')}</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
          </ul>
        </div>
        <div className="card-footer">
          <form onSubmit={onSubmit}>
            <div className="input-group">
              <input
                className={`form-control type_msg mh-auto empty_check`}
                placeholder="Type your message..."
              />
              <button className="btn btn-primary btn_send">
               {isLoading ? <Spinner />: <i className="fa fa-paper-plane" aria-hidden="true"></i>} 
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default MessageBox;
