import React from "react";
import { useAppSelector } from "../../../hooks/redux-hooks";
import DashboardAvatar from "../../widgets/dashboard_avatar";
import DashboardFooter from "../../widgets/dsahboard_footer";
import CommonLinks from "./CommonLinks";
import AdminLinks from "./AdminLinks";
import ManagerLinks from "./ManagerLinks";
import SupportAgentLinks from "./SupportAgentLinks";
import { SYSTEMS_TO_USE_CHOICES } from "../../utils/system_access";

const SideBar: React.FC = () => {
const user = useAppSelector((state) => state.auth.user);

// Get the user's assigned systems
const assignedSystems = user?.system_access.map((item) => item.system_name) || [];

// Check if the user has access to all systems
const hasAllSystems = assignedSystems.length === SYSTEMS_TO_USE_CHOICES.length;

  

  return (
    <>
<div className="sidebar" id="sidebar">
      <div className="sidebar-inner slimscroll">
        <div id="sidebar-menu" className="sidebar-menu">
          <DashboardAvatar />
          <ul>
            {/* Common Links (always visible) */}
            <CommonLinks />

            {/* Role-Specific Links */}
            {assignedSystems.length === 0 && user?.user_type !== "ADMIN" ? (
              // Fallback UI if no systems are assigned
              <li>
                <span>No systems assigned. Please contact your administrator.</span>
              </li>
            ) : (
              // Show links based on assigned systems
              <>
                {hasAllSystems || user?.user_type === "ADMIN" ? (
                  // If the user has access to all systems, show all links
                  <>
                    <AdminLinks assignedSystems={SYSTEMS_TO_USE_CHOICES} />
                    {/* <ManagerLinks assignedSystems={SYSTEMS_TO_USE_CHOICES} />
                    <SupportAgentLinks assignedSystems={SYSTEMS_TO_USE_CHOICES} /> */}
                  </>
                ) : (
                  // Otherwise, filter links based on assigned systems
                  <>
                    {/* {user?.user_type ==="ADMIN" && <AdminLinks assignedSystems={assignedSystems} />} */}
                    {user?.user_type ==="MANAGER" && <ManagerLinks assignedSystems={assignedSystems} />}
                    {user?.user_type ==="SUPPORTAGENT" && <SupportAgentLinks assignedSystems={assignedSystems} />}
                  </>
                )}
              </>
            )}
          </ul>
          {/* <DashboardFooter /> */}
        </div>
      </div>
    </div>
    </>
  );
};

export default SideBar;
