import React from "react";
import { ICarRental } from "../../../../redux/types/car_rental.type";
import { useAppSelector } from "../../../../hooks/redux-hooks";
import LoadingSpinner from "../../../../components/widgets/spinner";
import { useGetAdminCarsQuery } from "../../../../redux/slices/admin_hire_slice";
import Pagination from "../../../pagination/pagination_items";
import { ErrorWidget } from "../../../utils/error_widget";
import CreateCarDeal from "./create_car_deal";

const CarDealsList: React.FC = ()=> {
  const currentUser = useAppSelector((state) => state.auth.user);
  const [showDetailsModal, setShowDetailsModal] = React.useState(false);
  const [details, setDetails] = React.useState<ICarRental | null>(null);
  const [status, setStatus] = React.useState("")
  const [query, setQuery] = React.useState("")
  const [currentPage, setCurrentPage] = React.useState(1);
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setQuery(newValue);
  }
  // DETAILS MODAL
  const handleShow = (item: ICarRental) => {
    setDetails(item);
    setShowDetailsModal(true);
  };
  const handleCloseDetailsModel = () => {
    setShowDetailsModal(false);
  };
  const currentUserSubscription = currentUser?.user_subscription != null && currentUser?.user_subscription?.subscription.name.includes("Free");
  
  const {
    data: cars,
    isLoading,
    refetch,
    error,
  }: ICarRental | any = useGetAdminCarsQuery({
    company: currentUser?.id,
    status: status,
    searchQuery: query, 
    page: currentPage,
    label: "SALE"
  });

  if (isLoading) {
    return (
      <div style={{ marginTop: "10%" }} className="text-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="content">
      <div className="card">
        <div className="card-body">
        <div className="page-header">
          <div className="page-title">
            <h4>Car Deals List</h4>
            <h6>Manage your Car Deals {currentUserSubscription}</h6>

          </div>
          <div className="page-btn">
         {currentUser?.status === "INACTIVE" ? <span>
            Your ACCOUNT is <span className="text-danger">inactive</span>. Please contact your administrator to activate your ACCOUNT.
            </span> :currentUserSubscription && cars.results.length !== 3 ? 
         <CreateCarDeal fetchData={refetch} user_id={currentUser?.id} />: 
         <span className="alert alert-info text-danger">
          You can't create more than 3 cars with your current subscription plan.
          </span>} 
        </div>
        </div>
        <div className="row">
          {error ? (
            <ErrorWidget error={error} />
          ) : cars.results.length === 0 ? (
            <center>
              <div className="alert alert-info custom-length" role="alert">
                <i className="fa fa-car fa-5x"></i>
                <h1>No Cars Found</h1>
              </div>
            </center>
          ) : (
            <>
              <div className="col-md-6">
               <div className="form-group">
               <label htmlFor="search">Search</label>
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search by car model"
                  value={query}
                  onChange={onChange}
                />
               </div>
              </div>
              <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="status">Status:</label>
                <select
                  className="form-control"
                  id="status"
                  value={status}
                  onChange={e => setStatus(e.target.value)}
                >
                  <option value="">All</option>
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </select>
              </div>
            </div>
            {cars.results.map((car: ICarRental, index: number) => (
              <div key={index} className="col-md-3 mb-3">
              <div className="img">
                <img
                style={{height: '200px', width: '100%'}}
                  className="img-fluid rounded"
                  src={`${car.car_images[0]?.image}`}
                  alt=""
                />
              </div>
              <div className="about-info">
                <h6>
                  <span className="text-capitalize"> {car.car_model.substring(0, 25)}... </span>
                </h6>
                <div className="row">
                  <div className="col-md-4">
                    <p>Seats:</p>
                    <p>Music:</p>
                    <p>Brand:</p>
                  </div>
                  <div className="col-md-8">
                    <p className="text-capitalize">{car.seats} seats</p>
                    <p>{car.music_types}</p>
                    <p className="text-uppercase">
                      {car.car_brand.brand_name}
                    </p>
                  </div>
                </div>
                <div className="pull-right mb-3">
                  <a
                    href={`/zm/car_dashboard/car_details/?car_id=${car.id}`}
                    className="btn btn-sm btn-info">
                    <i className="fa fa-eye"></i> Details
                  </a>
                  <a
                    href={`/zm/car_dashboard/edit_car/?car_id=${car.id}`}
                    className="btn btn-sm btn-primary">
                    <i className="fa fa-edit"></i> Edit
                  </a>
                </div>
              </div>
            </div>
            ))
          }
            <Pagination
              currentPage={currentPage}
              lastPage={cars.total_pages}
              maxLength={7}
              setCurrentPage={setCurrentPage}
            />
            </>
            
          )}
        </div>
        </div>
      </div>
      
    </div>
  );
}

export default CarDealsList;
