import React from "react";
import searchIcon from "../../../../assets/img/icons/search.svg";
import pdf from "../../../../assets/img/icons/pdf.svg";
import excel from "../../../../assets/img/icons/excel.svg";
import printer from "../../../../assets/img/icons/printer.svg";
import editIcon from "../../../../assets/img/icons/edit.svg";
import LoadingSpinner from "../../../../components/widgets/spinner";
import { TCountyCurrency } from "../../../../redux/types/country_currency.type";
import { useGetCurrencyListQuery } from "../../../../redux/slices/country_currency_slice";
import { ErrorWidget } from "../../../utils/error_widget";
import Pagination from "../../../pagination/pagination_items";
import CreateCountryCurrency from "./crud/create_currency";
import EditCountryCurrency from "./crud/edit_currency";

const CurrencySettings: React.FC = () => {
  const [show, setShow] = React.useState(false);
  const [currency, setCurrency] = React.useState<TCountyCurrency | any>();
  const [query, setQuery] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [currentPage, setCurrentPage] = React.useState(1);
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setQuery(newValue);
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = (currency: TCountyCurrency) => {
    setCurrency(currency);
    setShow(true);
  };

  const {
    data: currencyList,
    isLoading,
    error,
  }: TCountyCurrency | any = useGetCurrencyListQuery({
    page: currentPage,
    searchQuery: query,
    status: status,
  });

  if (isLoading) {
    return (
      <div style={{ marginTop: "20%" }} className="text-center">
        <LoadingSpinner />
      </div>
    );
  }
  return (
    <div className="content">
      <div className="page-header">
        <div className="page-title">
          <h4>Currency Settings</h4>
          <h6>Manage Currency Settings</h6>
        </div>
        <div className="page-btn">
          <CreateCountryCurrency />
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          {error ? (
            <ErrorWidget error={error} />
          ) : currencyList.results.length === 0 ? (
            <center className="p-3">
              <i className="fa fa-money fa-5x"></i>
              <h4>
                <b>No Data Found</b>
              </h4>
            </center>
          ) : (
            <>
              <div className="table-responsive mb-4">
                <table className="table datanew">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Country</th>
                      <th>Currency name</th>
                      <th>Currency code</th>
                      <th>Currency symbol</th>
                      <th>Active Users</th>
                      <th>Status</th>
                      <th className="text-end">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currencyList.results.map((item: TCountyCurrency, index: number) => {
                        return (
                          <>
                            <tr key={index}>
                              <td>{index+1}</td>
                              <td className="text-capitalize">{item.country}</td>
                              <td className="text-capitalize">{item.currency_name}</td>
                              <td className="text-uppercase">{item.currency_code}</td>
                              <td className="text-uppercase">{item.currency_symbol}</td>
                              <td>{item.company_count}</td>
                              <td>
                                <div className="status-toggle d-flex justify-content-between align-items-center">
                                  <input
                                    type="checkbox"
                                    id="user1"
                                    className="check"
                                    checked={item.isActive}
                                  />
                                  <label
                                    htmlFor="user1"
                                    className="checktoggle"
                                  >
                                    checkbox
                                  </label>
                                </div>
                              </td>
                              <td className="text-end">
                                <span>
                                  <a
                                    className="me-3"
                                    onClick={() => handleShow(item)}
                                  >
                                    <img src={editIcon} alt="img" />
                                  </a>
                                </span>
                              </td>
                            </tr>
                            <EditCountryCurrency
                              currency={currency === null ? item : currency}
                              show={show}
                              handleClose={handleClose}
                              setShow={setShow}
                            />
                          </>
                        );
                      }
                    )}
                  </tbody>
                </table>
              </div>
              <Pagination
                currentPage={currentPage}
                lastPage={currencyList.total_pages}
                maxLength={7}
                setCurrentPage={setCurrentPage}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CurrencySettings;
