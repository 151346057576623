import React from 'react'
import { User } from '../../../../../redux/types/user';
import { useGetUserQuery } from '../../../../../redux/slices/usersApiSlice';
import LoadingSpinner from '../../../../../components/widgets/spinner';
import BreadCrumbs from '../../../../widgets/bread_crumbs';
import editIcon from "../../../../../assets/img/icons/edit-5.svg";
import moment from 'moment';
import { formatPrice } from '../../../../utils/format_price';
import CompanySummary from './summary';
import EditAccount from '../crud/edit_account';
import { useGetAgricultureListQuery } from '../../../../../redux/slices/agriculture_slice';
import { TAgriculture } from '../../../../../redux/types/agriculture.type';
import Pagination from '../../../../pagination/pagination_items';

const AgroCompanyDetails: React.FC = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const company_id = queryParams.get("company_id");
    const [show, setShow] = React.useState(false);
    const [user, setUser] = React.useState<User | any>();
    const [query, setQuery] = React.useState("")
    const [currentPage, setCurrentPage] = React.useState(1);
  
    const [fromDate, setFromDate] = React.useState("");
      const [toDate, setToDate] = React.useState("");
    
      // Handler for date filter change
      const onDateFilter = (fromDate: string, toDate: string) => {
        setFromDate(fromDate);
        setToDate(toDate);
        // Call your API or perform filtering here
        console.log("Filtering from:", fromDate, "to:", toDate);
      };
  
    const handleClose = () => {
      setShow(false);
    };
  
    const handleShow = (user: User) => {
      setUser(user);
      setShow(true);
    };
  
    const {
      data: userDetails,
      isLoading: isFetching,
      error,
    }: User | any = useGetUserQuery(Number(company_id));

    const {
        data: agricultureData,
        isLoading: fetchingConfirmed,
      }: TAgriculture | any = useGetAgricultureListQuery({
        supplier: company_id, 
        status: "", 
        searchQuery: query, 
        page: currentPage,
        fromDate: fromDate,
        toDate:   toDate,
      });
  
    if (isFetching || fetchingConfirmed) {
      return (
        <div style={{ marginTop: "20%" }} className="text-center">
          <LoadingSpinner />
        </div>
      );
    }
  return (
    <>
    <EditAccount
      title={"Update User Account"}
      accountType={"AGRO"}
      user={userDetails}
      show={show}
      handleClose={handleClose}
      setShow={setShow}
    />
    <div className='content'>
        <BreadCrumbs 
        title={'Company Details'} 
        subTitle={`View ${userDetails?.company_name}`} 
        button={
          <a className="ml-5" onClick={() => handleShow(user)}>
            <img width={40} height={40} src={editIcon} alt="img" />
          </a>
        }
        />
        <CompanySummary user={userDetails} totalSales={agricultureData.total_sales} />
        {/* <div className="row">
          <div className="col-md-12">
            <h3>Monthly Sales</h3>
            <BranchCharts width={1000} height={250} data={monthlyData} />
          </div>
        </div> */}
        <div className="col-md-12">
            
        <div className="card mb-5 mb-md-0">
        <h4 className='m-3 pr-2 text-capitalize'><b>Product For {userDetails?.company_name} </b></h4>
          {agricultureData.results.length === 0 ? (
            <center>
              <div
                className="text-center alert alert-info mt-3"
                role="alert"
                style={{ width: "fit-content" }}
              >
                <h1 className="mt-3">NO PRODUCTS FOUND</h1>
              </div>
            </center>
          ) : (
            <div className="card-body table-responsive">
              <table className="table table-hover">
                <thead>
                  <tr>
                      <th>#</th>
                      <th>Supplier Name</th>
                      <th>Product</th>
                      <th>Sales</th>
                      <th>Reference</th>
                      <th>Product Price</th>
                      <th>Quantity</th>
                      <th>Status</th>
                      <th>Date</th>
                      <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {agricultureData.results.map((item: TAgriculture, index: number) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td className="text-bolds text-capitalize">
                          {item.supplier.first_name}{" "}
                          {item.supplier.last_name}
                        </td>
                        <td className="text-bolds text-capitalize">
                          {item.productName.substring(0, 12)}...
                        </td>
                        <td>{item.agri_order_product.length}</td>
                        <td>{item.product_unique_id}</td>
                        <td>{formatPrice({currency: item.supplier.company_currency.currency.currency_code, price: item.productPrice})}</td>
                        <td>{item.quantity}</td>
                        <td>
                          <span className="badges bg-lightgreen">
                            {item.status}
                          </span>
                        </td>
                        <td>
                          {moment(item.created_at).format("DD MMMM YYYY")}
                        </td>
                        <td>
                          {user?.user_type !== "ADMIN" ? (
                            <span className="">
                              <a className="me-3" href={`/zm/dashboard/agro_transactions/?agro_id=${item.id}`}>
                                <i className="fa fa-arrow-right"></i>
                              </a>
                            </span>
                          ) : (
                            <span>

                            </span>
                          )}
                        </td>
                      </tr>
                    );
                  }
                )}
                </tbody>
              </table>
              <Pagination
                currentPage={currentPage}
                lastPage={agricultureData.total_pages}
                maxLength={7}
                setCurrentPage={setCurrentPage}
              />
            </div>
          )}
        </div>
      </div>
    </div>
    </>
  )
}

export default AgroCompanyDetails