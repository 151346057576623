import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Modal, Button, Form } from "react-bootstrap";
import { FormInput } from "../../../../components/molecules/form_input";
import { SelectInput } from "../../../../components/molecules/selectInput";
import { FormTextarea } from "../../../../components/molecules/form_textarea";

export type DataFormFields = {
  titleName?: string;
  description?: string;
  website?: string;
  status: string;
  facebook?: string;
  twitter?: string;
  instagram?: string;
  ad_type: string;
  image?: File;
}

interface AdModalProps {
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
  onSubmit: (data: DataFormFields) => void;
  initialData?: Partial<any>;
}

const AdModal: React.FC<AdModalProps> = ({ isOpen, onClose, onSubmit, initialData }) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<DataFormFields>({
    defaultValues: initialData || {},
  });

  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false)

  // ✅ Automatically populate fields when `initialData` changes
  useEffect(() => {
    if (initialData) {
      setValue("titleName", initialData.titleName);
      setValue("description", initialData.description);
      setValue("website", initialData.website);
      setValue("facebook", initialData.facebook);
      setValue("twitter", initialData.twitter);
      setValue("instagram", initialData.instagram);
      setValue("status", initialData.status);
      setValue("ad_type", initialData.ad_type);
      
      if (typeof initialData.image === "string") {
        setImagePreview(initialData.image); // Set image preview if URL is available
      } else {
        setImagePreview(null);
      }
    } else {
      reset(); // Reset form if creating a new ad
      setImagePreview(null);
    }
  }, [initialData, setValue, reset]);

  // ✅ Handle image file selection
  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    
    if (file) {
      setValue("image", file); // Store file in form
      
      // Make sure we're passing a valid File object
      // const objectUrl = URL.createObjectURL(file);
      // setImagePreview(objectUrl);
      
      // Optional: Clean up the object URL when no longer needed
      // return () => URL.revokeObjectURL(objectUrl);
    }
  };
  

  const handleFormSubmit = handleSubmit(async (data) => {
    // Set loading state before submission
    setIsLoading(true);
    
    try {
      // Submit data but don't reset yet
      await onSubmit(data);
      
      // Reset form only after successful submission
      reset();
      
      // Additional success handling can go here
      setIsLoading(false);
    } catch (error) {
      // Handle errors if needed
      console.error("Form submission failed:", error);
      setIsLoading(false);
      // Don't reset the form on error so the user can try again
    } finally {
      // Optionally reset loading state here if you're not doing it in onSubmit
      setIsLoading(false);
    }
  });

  return (
    <Modal 
    show={isOpen} 
    onHide={onClose} 
    scrollable
    size="lg"
    centered>
      <Modal.Header closeButton>
        <Modal.Title>{initialData ? "Edit Ad" : "Add New Ad"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleFormSubmit}>
          <div className="row">
            {/* Ad Title" */}
          <div className="col-md-4">
            <FormInput<DataFormFields>
              id="titleName"
              type="text"
              name="titleName"
              label="Ad Title (optional)"
              disabled={isLoading}
              placeholder="Ad Title (optional)"
              register={register}
              rules={{ required: false }}
              errors={errors}
            />
          </div>
          
          
          
          {/* Website Link */}
          <div className="col-md-4">
            <FormInput<DataFormFields>
              id="website"
              type="url"
              name="website"
              disabled={isLoading}
              label="Website Link (optional)"
              placeholder="https://example.com"
              register={register}
              rules={{ required: false }}
              errors={errors}
            />
          </div>
          
          {/* Social Media Links */}
          <div className="col-md-4">
            <FormInput<DataFormFields>
              id="facebook"
              type="url"
              name="facebook"
              disabled={isLoading}
              label="Facebook Link (Optional)"
              placeholder="https://facebook.com/yourpage"
              register={register}
              rules={{ required: false }}
              errors={errors}
            />
          </div>
          <div className="col-md-4">
            <FormInput<DataFormFields>
              id="twitter"
              type="url"
              disabled={isLoading}
              name="twitter"
              label="Twitter Link (Optional)"
              placeholder="https://twitter.com/yourhandle"
              register={register}
              rules={{ required: false }}
              errors={errors}
            />
          </div>
          <div className="col-md-4">
            <FormInput<DataFormFields>
              id="instagram"
              type="url"
              disabled={isLoading}
              name="instagram"
              label="Instagram Link (Optional)"
              placeholder="https://instagram.com/yourprofile"
              register={register}
              rules={{ required: false }}
              errors={errors}
            />
          </div>
          
          <div className="col-md-4">
            <SelectInput 
              register={register} 
              label="Status" 
              disabled={isLoading}
              defaultValue={initialData?.status}
              errors={errors} 
              rules={{ required: "Status is required." }}
              name="status"
              children={
                <>
                <option value="ACTIVE">Active</option>
                <option value="INACTIVE">Inactive</option>
                <option value="PENDING">Pending</option>
                </>
              }
            className="form-control text-capitalize"/>
          </div>
          <div className="col-md-6">
            <SelectInput 
              register={register} 
              label="Ad Placement" 
              disabled={isLoading}
              defaultValue={initialData?.ad_type}
              errors={errors} 
              rules={{ required: "Ad Placement is required." }}
              name="ad_type"
              children={
                <>
                <option value="ALL">All</option>
                <option value="AGRO">Agro</option>
                <option value="INVESTMENT">Investment</option>
                <option value="CARHIRE">Car hire/Rental</option>
                <option value="REALESTATE">Reals Estate</option>
                <option value="MINERAL">Mineral</option>
                <option value="FUEL">Fuel</option>
                <option value="MININGCONSTRUCTION">Mining/Construction</option>
                <option value="FUEL">Fuel</option>
                </>
              }
            className="form-control text-capitalize"/>
          </div>
          {/* Image Upload */}
          <div className="col-md-6">
            <Form.Group className="mb-2">
            {/* <FormInput<DataFormFields>
              id="image"
              type="file"
              accept="image/*"
              name="image"
              disabled={isLoading}
              label="Ad Title"
              placeholder="Ad Title"
              register={register}
              rules={{ 
                required: initialData != null ? false: "Image is required",
                onChange: handleImageChange,
            }}
              errors={errors}
            /> */}
            <FormInput<DataFormFields>
              id="image"
              type="file"
              accept="image/*"
              name="image"
              disabled={isLoading}
              label="Ad Title"
              placeholder="Ad Title"
              register={register}
              rules={{ 
                required: initialData != null ? false: "Image is required",
                // onChange: handleImageChange,
              }}
              onChange={handleImageChange}  // Add the onChange handler here
              errors={errors}
            />
            </Form.Group>
          </div>
          
          <div className="col-md-12">
            <FormTextarea<DataFormFields>
              id="description"
              name="description"
              disabled={isLoading}
              label="Description (optional)"
              placeholder="Description (optional)"
              register={register}
              rules={{ required: false }}
              errors={errors}
            />
          </div>
          
          <center>
          {imagePreview && <img style={{width: "50%", height: "40vh"}} src={imagePreview} alt="Preview" className="mt-2 object-cover rounded" />}
          </center>
          </div>
          <div className="mt-4 d-flex justify-content-end gap-2">
            <Button variant="secondary" onClick={onClose}>Cancel</Button>
            <Button type="submit" variant="primary">{isLoading ? (
              <>
                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                {initialData ? "Updating..." : "Creating..."}
              </>
            ) : (
              initialData ? "Update" : "Create"
            )}</Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AdModal;
