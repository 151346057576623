import React from "react";
import plusIcon from "../../../../assets/img/icons/plus.svg";
import editIcon from "../../../../assets/img/icons/edit.svg";
import { SubscriptionPackage } from "../../../../redux/types/subscription.type";
import LoadingSpinner from "../../../../components/widgets/spinner";
import { useGetSubscriptionListQuery } from "../../../../redux/slices/subscription_slice";
import SubscriptionPackageModal, { SubscriptionPackageFields } from "./create_subscription";
import BreadCrumbs from "../../../widgets/bread_crumbs";

const SubscriptionList: React.FC = () => {
  const [showModal, setShowModal] = React.useState(false);
  const [selectedPackage, setSelectedPackage] = React.useState<SubscriptionPackageFields | undefined>(undefined);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleShowModal = (packageData?: SubscriptionPackageFields) => {
    setSelectedPackage(packageData);
    setShowModal(true);
  };

  const handleAddOrUpdatePackage = async (data: SubscriptionPackageFields) => {
    setIsLoading(true);
    try {
      // Simulate an API call
      console.log('Saving package:', data);
      // Replace this with your actual API call
      // await api.savePackage(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      throw error;
    }
  };
  
  const {
    data: packageList,
    isLoading: fetchingPackages,
    error,
  }: SubscriptionPackage | any = useGetSubscriptionListQuery({
    status: "",
  });

  if (fetchingPackages) {
    return (
      <div style={{ marginTop: "20%" }} className="text-center">
        <LoadingSpinner />
      </div>
    );
  }
  return (
    <>
    <SubscriptionPackageModal
        show={showModal}
        onHide={() => setShowModal(false)}
        subPackage={selectedPackage}
        onSubmit={handleAddOrUpdatePackage}
        isLoading={isLoading}
      />
      <div className="content">
      <BreadCrumbs 
        title={'Subscription List'} 
        subTitle={`View and Manage Subscriptions`} 
        // button={
        //   <a onClick={() => setShowModal(true)} className="btn btn-added">
        //     <img src={plusIcon} alt="img" className="me-2" />
        //     Add Package
        //   </a>
        // }
        />

        <div className="card">
          <div className="card-body">
            {error ? (
              <div className="alert alert-danger text-center mt-5" role="alert">
                {error.status}
              </div>
            ) : packageList.length === 0 ? (
              <div className="text-center">
                <i style={{ fontSize: "50px" }} className="ti-home"></i>
                <h2>No Data Found</h2>
              </div>
            ) :
              <div className="row">
                {packageList.map((pkg: SubscriptionPackage, index: number) => (
                  <div key={index} className="col-md-6 mb-4">
                    <div className="card h-100">
                      <div className="card-header bg-warning text-white">
                        <h5 className="card-title text-center text-white">
                          {pkg.name} 
                          {/* <a className="me-3" onClick={() => handleShowModal(pkg)}>
                            <img src={editIcon} alt="img" />
                          </a>  */}
                        </h5>
                      </div>
                      <div className="card-body">
                        <p><b>Applicable Modules:</b> {pkg.applicable_modules}</p>
                        <p><b>Duration Options:</b> {pkg.duration_options}</p>
                        <p><b>Description:</b> {pkg.description}</p>
                        <p><b>Normal Ad Placements:</b> {pkg.normal_ad_placements}</p>
                        <p><b>Promotion:</b> {pkg.promotion}</p>
                        <p><b>Features:</b> {pkg.features}</p>
                        <p><b>SMS Notifications:</b> {pkg.sms_notifications ? 'Yes' : 'No'}</p>
                        <p><b>Website/Social Media Link Inclusion:</b> {pkg.website_link_inclusion ? 'Yes' : 'No'}</p>
                        <p><b>Email and Social Media Promotions:</b> {pkg.email_promotions ? 'Yes' : 'No'}</p>
                        <p><b>Dedicated Personal Manager:</b> {pkg.personal_manager ? 'Yes' : 'No'}</p>
                        <p><b>DealiT Insights Report:</b> {pkg.insights_report ? 'Yes' : 'No'}</p>
                        <p><b>Feedback Management Tool:</b> {pkg.feedback_tool ? 'Yes' : 'No'}</p>
                        <p><b>Additional Phone Number:</b> {pkg.additional_phone_number ? 'Yes' : 'No'}</p>
                        <p><b>Display Partner Company Name in Ads:</b> {pkg.display_partner_name ? 'Yes' : 'No'}</p>
                        <h6 className="mt-3">Pricing:</h6>
                        <ul>
                          {Object.entries(pkg.pricing).map(([country, price]) => (
                            <li key={country}>
                              <strong>{country}:</strong> {price}
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="card-footer text-center">
                        <button className={`text-white btn ${pkg.status === "ACTIVE" ? "bg-success": "btn-primary"}`}>{pkg.status}</button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              }
          </div>
        </div>
      </div>
    </>
  );
};

export default SubscriptionList;
