import React from "react";
import BreadCrumbs from "../widgets/bread_crumbs";
import ChangePassword from "../widgets/change_password";

const ChangeUserPassword: React.FC = () => {
  return (
    <div className="content">
      <BreadCrumbs
        title={"Password"}
        subTitle={"Change Your Password"}
      />
      <div className="card">
        <div className="card-body">
        <ChangePassword />
        </div>
      </div>
    </div>
  );
};

export default ChangeUserPassword;
