import React, { useRef } from "react";
import deleteIcon from "../../../../assets/img/icons/delete.svg";
import moment from "moment";
import Filters from "../../../admin/widgets/filters";
import { useAppSelector } from "../../../../hooks/redux-hooks";
import Pagination from "../../../pagination/pagination_items";
import LoadingSpinner from "../../../../components/widgets/spinner";
import { ErrorWidget } from "../../../utils/error_widget";
import DeleteFuelModal from "./delete_fuel_modal";
import { TFuelModel } from "../../../../redux/types/fuel_mode.type";
import AddFuel from "./add_fuel";
import { useGetFuelListQuery } from "../../../../redux/slices/fuel_slice";
import { formatPrice } from "../../../utils/format_price";

const FuelLubesHomePage: React.FC = () => {
  const divRef = useRef<HTMLDivElement>(null);
  const currentUser = useAppSelector((state) => state.auth.user);
  const currentUserSubscription = currentUser?.user_subscription != null && currentUser?.user_subscription?.subscription.name.includes("Free");
  const [showDelete, setShowDelete] = React.useState(false);
  const [deleteFuel, setDeleteFuel] = React.useState<TFuelModel | any>();
  const [query, setQuery] = React.useState("")
  const [currentPage, setCurrentPage] = React.useState(1);
  const [fromDate, setFromDate] = React.useState("");
  const [toDate, setToDate] = React.useState("");
  
  // Handler for date filter change
  const onDateFilter = (fromDate: string, toDate: string) => {
    setFromDate(fromDate);
    setToDate(toDate);
    // Call your API or perform filtering here
    console.log("Filtering from:", fromDate, "to:", toDate);
  };
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setQuery(newValue);
  }

  const handleCloseDelete = () => {
    setShowDelete(false);
  };

  const handleShowDelete = (fuel: TFuelModel) => {
    setDeleteFuel(fuel);
    setShowDelete(true);
  };

  const {
    data: fuelList,
    isLoading,
    refetch,
    error,
  }: TFuelModel | any = useGetFuelListQuery({
    supplier: currentUser?.id, 
    status: "", 
    searchQuery: query, 
    page: currentPage,
    fromDate: fromDate,
    toDate:   toDate,
  });

  if (isLoading) {
    return (
      <div style={{ marginTop: "20%" }} className="text-center">
        <LoadingSpinner />
      </div>
    );
  }
  return (
    <>
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>Fuel & Lube List</h4>
            <h6>Manage your Fuel & Lubes</h6>
          </div>
          <div className="page-btn">
          {currentUser?.status === "INACTIVE" ? <span>
            Your ACCOUNT is <span className="text-danger">inactive</span>. Please contact your administrator to activate your ACCOUNT.
            </span> :currentUserSubscription && fuelList.results.length !== 3 ? 
           <AddFuel fetchData={refetch} />: 
          <span className="alert alert-info text-danger">
          You can't create more than 3 Products with your current subscription plan.
          </span>} 
           
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <Filters divRef={divRef} query={query} onSearchChange={onChange} onDateFilter={onDateFilter}/>
            {error ? (
              <ErrorWidget error={error}/>
            ) : fuelList.results.length === 0 ? (
              <div className="text-center">
                <i style={{ fontSize: "50px" }} className="ti-home"></i>
                <h2>No Data Found</h2>
              </div>
            ) : (
              <div ref={divRef} className="table-responsive">
                <table className="table datanew">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Product</th>
                      <th>Type</th>
                      <th>Unit</th>
                      <th>Product Price</th>
                      <th>Ratings</th>
                      <th>Status</th>
                      <th>Created On</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {fuelList.results.map((item: TFuelModel, index: number) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td className="text-capitalize">
                            <div className="row">
                              <div className="col-md-4 mr-0 pr-0">
                                <a href="">
                                  <img
                                    className="product-imgs rounded"
                                    src={`${item.thumbnail}`}
                                    alt={item.fuel_name}
                                  />
                                </a>
                              </div>
                              <div className="col-md-8 ml-0 pl-0">
                                <a href="">
                                  {item.fuel_name.substring(0, 10)}
                                </a>
                              </div>
                            </div>
                          </td>
                          <td className="text-capitalize">
                            {item.fuel_type}
                          </td>
                          <td className="text-capitalize">{item.unit}</td>
                          <td>{formatPrice({currency: item.supplier.company_currency.currency.currency_code, price: item.fuel_price})}</td>
                          <td><i className="fa fa-star text-warning"></i> {item.rating_avg}</td>
                          <td className="text-capitalize">{item.status}</td>
                          <td>
                            {moment(item.created_at).format("Do MMMM, YYYY")}
                          </td>
                          <td>
                            
                            <a
                              className="me-3 confirm-text"
                              onClick={() => handleShowDelete(item)}
                            >
                              <img src={deleteIcon} alt="img" />
                            </a>
                            <a href={`/zm/account/${currentUser?.user_type}/dashboard/product_details/?product_id=${item.id}`}>
                              <i className="fa fa-arrow-right"></i>
                            </a>
                          </td>
                          <DeleteFuelModal
                            product={
                              deleteFuel === null ? item : deleteFuel
                            }
                            show={showDelete}
                            handleClose={handleCloseDelete}
                            setShow={setShowDelete}
                          />
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <Pagination
                  currentPage={currentPage}
                  lastPage={fuelList.total_pages}
                  maxLength={7}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default FuelLubesHomePage;
