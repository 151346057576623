import React from 'react';
import { useLocation } from 'react-router-dom';
import sales1 from "../../../assets/img/icons/sales1.svg";
import product from "../../../assets/img/icons/product.svg";
import purchase1 from "../../../assets/img/icons/purchase1.svg";
import expense1 from "../../../assets/img/icons/excel.svg";
import user1 from "../../../assets/img/icons/users1.svg";
import settings from "../../../assets/img/icons/settings.svg";
import transfer1 from "../../../assets/img/icons/transfer1.svg";

interface AdminLinksProps {
  assignedSystems: string[];
}
const AdminLinks: React.FC<AdminLinksProps> = ({ assignedSystems }) => {
  const location = useLocation();

  return (
    <>
        {/* CAR RENTAL */}
        <li className="submenu">
          <a href="javascript:void(0);">
            <img src={purchase1} alt="img" />
            <span>Car Rentals</span>
            <span className="menu-arrow"></span>
          </a>
          <ul>
            <li>
              <a className={`${location.pathname === "/zm/dashboard/car_brands" ? "active" : ""}`} href="/zm/dashboard/car_brands">Car Brands</a>
            </li>
            <li>
              <a className={`${location.pathname === "/zm/dashboard/car_list" ? "active" : ""}`} href="/zm/dashboard/car_list">Car List</a>
            </li>
          </ul>
        </li>
        {/* MINERALS */}
        <li className="submenu">
          <a href="javascript:void(0);">
            <img src={product} alt="img" />
            <span>Commodities</span>
            <span className="menu-arrow"></span>
          </a>
          <ul>
            <li>
              <a className={`${location.pathname === "/zm/dashboard/mineral_trading" ? "active" : ""}`} href="/zm/dashboard/mineral_trading">Mineral Trading</a>
            </li>
            <li>
              <a className={`${location.pathname === "/zm/dashboard/agriculture_list" ? "active" : ""}`} href="/zm/dashboard/agriculture_list">Agriculture List</a>
            </li>
            <li>
              <a className={`${location.pathname === "/zm/dashboard/fuel_list" ? "active" : ""}`} href="/zm/dashboard/fuel_list">Fuel List</a>
            </li>
          </ul>
        </li>
        {/* INVESTMENT */}
        <li className="submenu">
          <a href="javascript:void(0);">
            <img src={transfer1} alt="img" />
            <span>Investment</span>
            <span className="menu-arrow"></span>
          </a>
          <ul>
            <li>
            </li>
            <li>
              <a className={`${location.pathname === "/zm/dashboard/investment_list" ? "active" : ""}`} href="/zm/dashboard/investment_list">Investment List</a>
            </li>
          </ul>
        </li>
        {/* REAL ESTATE */}
        <li className="submenu">
          <a href="javascript:void(0);">
            <i className="fa fa-home text-muted"></i>
            <span>Real Estate</span>
            <span className="menu-arrow"></span>
          </a>
          <ul>
            <li>
            </li>
            <li>
              <a className={`${location.pathname === "/zm/dashboard/real_estate_list" ? "active" : ""}`} href="/zm/dashboard/real_estate_list">Real Estate List</a>
            </li>
            
          </ul>
        </li>
        {/* ESCROW */}
        <li className="submenu">
          <a href="javascript:void(0);">
            <img src={expense1} alt="img" />
            <span>DealSecure</span>
            <span className="menu-arrow"></span>
          </a>
          <ul>
            <li>
            </li>
            <li>
              <a className={`${location.pathname === "/zm/dashboard/escrow_list" ? "active" : ""}`} href="/zm/dashboard/escrow_list">DealSecure List</a>
            </li>
            
          </ul>
        </li>
        {/* ADS LINK */}
        <li className="submenu">
          <a href="javascript:void(0);">
            <i className='fa fa-ad text-muted'></i>
            <span>Ads</span>
            <span className="menu-arrow"></span>
          </a>
          <ul>
            <li>
            </li>
            <li>
              <a className={`${location.pathname === "/zm/dashboard/ads_list" ? "active" : ""}`} href="/zm/dashboard/ads_list">Ads List</a>
            </li>
          </ul>
        </li>
        {/* MINING CONSTRUCTION */}
        <li className="submenu">
          <a href="javascript:void(0);">
            <img src={sales1} alt="img" />
            <span>Mining/Construction</span>
            <span className="menu-arrow"></span>
          </a>
          <ul>
            <li>
            </li>
            <li>
              <a className={`${location.pathname === "/zm/dashboard/mining_list" ? "active" : ""}`} href="/zm/dashboard/mining_list">Mining List</a>
            </li>
            <li>
              <a className={`${location.pathname === "/zm/dashboard/construction_list" ? "active" : ""}`} href="/zm/dashboard/construction_list">Construction List</a>
            </li>
          </ul>
        </li>
          <li className="submenu">
          <a href="javascript:void(0);">
          <i className='fa fa-users text-muted'></i>
            <span>Organizations</span>
            <span className="menu-arrow"></span>
          </a>
          <ul>
            <li>
              <a className={`${location.pathname === "/zm/dashboard/onboarding_requests" ? "active" : ""}`} href="/zm/dashboard/onboarding_requests">Onboarding Requests</a>
              <a className={`${location.pathname === "/zm/dashboard/car_organizations" ? "active" : ""}`} href="/zm/dashboard/car_organizations">Car Companies</a>
              <a className={`${location.pathname === "/zm/dashboard/mineral_companies" ? "active" : ""}`} href="/zm/dashboard/mineral_companies">Mineral Companies</a>
              <a className={`${location.pathname === "/zm/dashboard/agro_companies" ? "active" : ""}`} href="/zm/dashboard/agro_companies">Agro Companies</a>
              <a className={`${location.pathname === "/zm/dashboard/investment_companies" ? "active" : ""}`} href="/zm/dashboard/investment_companies">Investment Companies</a>
              <a className={`${location.pathname === "/zm/dashboard/real_estate_companies" ? "active" : ""}`} href="/zm/dashboard/real_estate_companies">Real-Estate Companies</a>
              <a className={`${location.pathname === "/zm/dashboard/fuel_companies" ? "active" : ""}`} href="/zm/dashboard/fuel_companies">Fuel Companies</a>
              <a className={`${location.pathname === "/zm/dashboard/mining_companies" ? "active" : ""}`} href="/zm/dashboard/mining_companies">Mining Companies</a>
              <a className={`${location.pathname === "/zm/dashboard/construction_companies" ? "active" : ""}`} href="/zm/dashboard/construction_companies">Construction Companies</a>
            </li>
          </ul>
        </li>
        <li className="submenu">
          <a href="javascript:void(0);">
            <img src={user1} alt="img" />
            <span>People</span>
            <span className="menu-arrow"></span>
          </a>
          <ul>
            <li>
              <a className={`${location.pathname === "/zm/dashboard/customerlist" ? "active" : ""}`} href="/zm/dashboard/customerlist">Customer List</a>
            </li>
            <li>
              <a className={`${location.pathname === "/zm/dashboard/system_users" ? "active" : ""}`} href="/zm/dashboard/system_users">System Users</a>
            </li>
          </ul>
        </li>
        
        
        <li className="submenu">
          <a href="javascript:void(0);">
            <img src={settings} alt="img" />
            <span>System</span>
            <span className="menu-arrow"></span>
          </a>
          <ul>
            <li>
              <a className={`${location.pathname === "/zm/dashboard/subscription_list" ? "active" : ""}`} href="/zm/dashboard/subscription_list">Subscription List</a>
            </li>
            <li>
              <a className={`${location.pathname === "/zm/dashboard/payment_settings" ? "active" : ""}`} href="/zm/dashboard/payment_settings">Payment Settings</a>
            </li>
            <li>
              <a className={`${location.pathname === "/zm/dashboard/currency_settings" ? "active" : ""}`} href="/zm/dashboard/currency_settings">Currency Settings</a>
            </li>
          </ul>
        </li>
    </>
  );
};

export default AdminLinks;