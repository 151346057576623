import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { FormInput } from "../../../../components/molecules/form_input";
import { FormTextarea } from "../../../../components/molecules/form_textarea";
import { useUpdateInvestmentMutation } from "../../../../redux/slices/investment_slice";
import { TInvestment } from "../../../../redux/types/investment.type";
import { useAppSelector } from "../../../../hooks/redux-hooks";

export type DataFormFields = {
  project_title: string;
  project_amount: string;
  project_description: string;
  project_category: string;
  project_days: Date;
  country: string;
  project_organizer: string;
  project_beneficiary: string;
};

type TEditProps = {
  project: TInvestment;
  show: boolean;
  handleClose: () => void;
  setShow: (show: boolean) => void;
};

const EditProject: React.FC<TEditProps> = ({
  project,
  show,
  handleClose,
  setShow,
}) => {
  const [file, setFile] = useState<File | any>();
  const user = useAppSelector((state) => state.auth.user);
  const [updateInvestment, { isLoading }] = useUpdateInvestmentMutation();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = handleSubmit(async (data) => {
    updateInvestment({
      id: project.id,
      thumbnail: file ?? project.thumbnail,
      ...data,
    })
      .unwrap()
      .then((res) => {
        reset();
        setShow(false);
        toast.success("Project Updated Successfully");
      })
      .catch((err) => {
        toast.error("Error: " + err.data["detail"]);
      });
  });

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        scrollable
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Project</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <form onSubmit={onSubmit} encType="multipart/form-data">
            <div className="row">
              <div className="col-md-4">
                <FormInput
                  id="project_title"
                  type="text"
                  name="project_title"
                  defaultValue={project?.project_title}
                  disabled={isLoading}
                  label="Project Name"
                  placeholder="Project Name"
                  className="mb-2"
                  register={register}
                  rules={{ required: "Project Name is required." }}
                  errors={errors}
                />
              </div>
              <div className="col-md-4">
                <FormInput
                  id="project_organizer"
                  type="text"
                  name="project_organizer"
                  disabled={isLoading}
                  label="Project Organizer"
                  placeholder="Project Organizer"
                  defaultValue={project?.project_organizer}
                  className="mb-2"
                  register={register}
                  rules={{ required: "Project Organizer is required." }}
                  errors={errors}
                />
              </div>
              <div className="col-md-4">
                <FormInput
                  id="project_beneficiary"
                  type="text"
                  name="project_beneficiary"
                  defaultValue={project?.project_beneficiary}
                  disabled={isLoading}
                  label="Project Benefitsiciary"
                  placeholder="Project Benefitsiciary"
                  className="mb-2"
                  register={register}
                  rules={{ required: "Project Beneficiary is required." }}
                  errors={errors}
                />
              </div>
              <div className="col-md-4">
                <FormInput
                  id="thumbnail"
                  type="file"
                  name="thumbnail"
                  label="Image"
                  disabled={isLoading}
                  accept="image/*"
                  placeholder="Image"
                  className="mb-2"
                  register={register}
                  rules={{
                    required: false,
                    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                      if (event.target.files && event.target.files[0]) {
                        setFile(event.target.files[0]);
                      }
                    },
                  }}
                  errors={errors}
                />
              </div>
              <div className="col-md-4">
                <FormInput
                  id="country"
                  type="text"
                  name="country"
                  defaultValue={project?.country}
                  disabled={isLoading}
                  label="country"
                  placeholder="country"
                  className="mb-2"
                  register={register}
                  rules={{ required: "country is required." }}
                  errors={errors}
                />
              </div>
              <div className="col-md-4">
                <div className="input-group">
                  <div className="input-group-prepend" style={{marginTop:"37px"}}>
                    <span className="input-group-text">{user?.company_currency.currency.currency_code}</span> {/* Currency sign */}
                  </div>
                  <FormInput
                    id="project_amount"
                    type="number"
                    step="0.01"
                    name="project_amount"
                    defaultValue={project?.project_amount}
                    disabled={isLoading}
                    label="Project Asking Amount"
                    placeholder="Project Asking Amount"
                    className="mb-2 form-control"
                    register={register}
                    rules={{ required: "Project Asking Amount is required." }}
                    errors={errors}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <label htmlFor="categroy">
                  Project Category <span className="text-danger">*</span>
                </label>
                <select
                  id="project_category"
                  defaultValue={project?.project_category}
                  disabled={isLoading}
                  {...register("project_category", { required: true })}
                  className={`form-control mt-3 text-capitalize ${
                    errors.project_category ? "border-danger" : ""
                  }`}
                >
                  <option value="agriculture">Agriculture</option>
                  <option value="education">Education</option>
                  <option value="healthy">Healthy</option>
                  <option value="hospitality">Hospitality</option>
                  <option value="ict">ICT</option>
                  <option value="manufacuring">Manufacturing</option>
                  <option value="mining">Mining</option>
                  <option value="real_estate">Real Estate</option>
                  <option value="transport">Transport</option>
                  <option value="energy">Energy</option>
                  <option value="water and sanitation">
                    Water And Sanitation
                  </option>
                  <option value="food process">
                    Food Processing And Packaging
                  </option>
                </select>
                {errors.project_category && (
                  <p className="text-danger">Project Category is required.</p>
                )}
              </div>
              <div className="col-md-6">
                <FormInput
                  id="project_days"
                  type="date"
                  defaultValue={project?.project_days}
                  name="project_days"
                  disabled={isLoading}
                  label="Project Days"
                  placeholder="Number of Days (Max 35 days)"
                  className="mb-2"
                  max={35}
                  register={register}
                  rules={{ required: "Project Days is required." }}
                  errors={errors}
                />
              </div>

              <div className="col-md-12">
                <FormTextarea
                  id="project_description"
                  name="project_description"
                  disabled={isLoading}
                  defaultValue={project?.project_description}
                  minLength={200}
                  label="description"
                  placeholder="Say something about this project"
                  rows={5}
                  cols={12}
                  className="form-control"
                  register={register}
                  rules={{ required: "Project Description is required." }}
                  errors={errors}
                />
              </div>
            </div>

            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button disabled={isLoading} variant="primary" type="submit">
                {isLoading && <Spinner size="sm" />}{" "}
                <i className="fa fa-plus"></i> Update
              </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditProject;
