import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import plusIcon from "../../../../assets/img/icons/plus.svg";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { FormInput } from "../../../../components/molecules/form_input";
import { FormTextarea } from "../../../../components/molecules/form_textarea";
import httpDashboardServices from "../../../services/http.category.services";
import { useAppSelector } from "../../../../hooks/redux-hooks";

export type DataFormFields = {
  user: string;
  project_title: string;
  project_amount: string;
  thumbnail: File;
  project_description: string;
  project_category: string;
  project_days: Date;
  country: string;
  project_organizer: string;
  project_beneficiary: string;
};

interface IProps{
  fetchData: () => void;
}
const AddProject: React.FC<IProps> = ({fetchData}) => {
  const user = useAppSelector((state) => state.auth.user);
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [file, setFile] = useState<File | any>();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // const [createInvestmentProject, { isLoading }] = useCreateInvestmentMutation();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<DataFormFields>();

  const onSubmit = handleSubmit(async (data) => {
    setIsLoading(true);
    await httpDashboardServices
      .createInvestmentProject({
        user: user?.id,
        project_title: data.project_title,
        project_amount: data.project_amount,
        thumbnail: file,
        project_description: data.project_description,
        project_category: data.project_category,
        project_days: data.project_days,
        country: data.country,
        project_beneficiary: data.project_beneficiary,
        project_organizer: data.project_organizer,
      })
      .then(() => {
        setIsLoading(false);
        reset();
        fetchData();
        setShow(false);
        toast.success("Product Created Successfully and has been submitted to the admin for review.");
      })
      .catch((err: any) => {
        setIsLoading(false);
        console.error(err.message);
        toast.error("Error :" + err.message);
      });
    // createInvestmentProject({
    //   user: user?.id,
    // project_title: data.project_title,
    // project_amount: data.project_amount,
    // thumbnail: file,
    // project_description: data.project_description,
    // project_category: data.project_category,
    // project_days: data.project_days,
    // })
    //   .unwrap()
    //   .then((res) => {
    //     reset();
    //     setShow(false);
    //     toast.success("Product Created Successfully");
    //   })
    //   .catch((err) => {
    //     toast.error("Error: " + err.data["detail"]);
    //   });
  });

  return (
    <>
      <button onClick={handleShow} className="btn btn-added">
        <img src={plusIcon} alt="img" />
        Add New Project
      </button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        scrollable
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add New Project</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={onSubmit} encType="multipart/form-data">
            <div className="row">
              <div className="col-md-4">
                <FormInput<DataFormFields>
                  id="project_title"
                  type="text"
                  name="project_title"
                  disabled={isLoading}
                  label="Project Name"
                  placeholder="Project Name"
                  className="mb-2"
                  register={register}
                  rules={{ required: "Project Name is required." }}
                  errors={errors}
                />
              </div>
              <div className="col-md-4">
                <FormInput<DataFormFields>
                  id="project_organizer"
                  type="text"
                  name="project_organizer"
                  disabled={isLoading}
                  label="Project Organizer"
                  placeholder="Project Organizer"
                  className="mb-2"
                  register={register}
                  rules={{ required: "Project Organizer is required." }}
                  errors={errors}
                />
              </div>
              <div className="col-md-4">
                <FormInput<DataFormFields>
                  id="project_beneficiary"
                  type="text"
                  name="project_beneficiary"
                  disabled={isLoading}
                  label="Project Beneficiary"
                  placeholder="Project Beneficiary"
                  className="mb-2"
                  register={register}
                  rules={{ required: "Project Beneficiary is required." }}
                  errors={errors}
                />
              </div>
              <div className="col-md-4">
                <FormInput<DataFormFields>
                  id="thumbnail"
                  type="file"
                  name="thumbnail"
                  label="Image"
                  disabled={isLoading}
                  accept="image/*"
                  placeholder="Image"
                  className="mb-2"
                  register={register}
                  rules={{
                    required: "Image is required.",
                    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                      if (event.target.files && event.target.files[0]) {
                        setFile(event.target.files[0]);
                      }
                    },
                  }}
                  errors={errors}
                />
              </div>
              <div className="col-md-4">
                <FormInput<DataFormFields>
                  id="country"
                  type="text"
                  name="country"
                  disabled={isLoading}
                  label="country"
                  placeholder="country"
                  className="mb-2"
                  register={register}
                  rules={{ required: "country is required." }}
                  errors={errors}
                />
              </div>
              <div className="col-md-4">
                <div className="input-group">
                  <div className="input-group-prepend" style={{marginTop: "37px"}}>
                    <span className="input-group-text">{user?.company_currency.currency.currency_code}</span> {/* Currency sign */}
                  </div>
                  <FormInput<DataFormFields>
                    id="project_amount"
                    type="number"
                    step="0.01"
                    name="project_amount"
                    disabled={isLoading}
                    label="Project Asking Amount"
                    placeholder="Project Asking Amount"
                    className="mb-2 form-control"
                    register={register}
                    rules={{ required: "Project Asking Amount is required." }}
                    errors={errors}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <label htmlFor="categroy mb-5">
                  Project Category <span className="text-danger">*</span>
                </label>
                <select
                  id="project_category"
                  disabled={isLoading}
                  {...register("project_category", { required: true })}
                  className={`form-control mt-3 text-capitalize ${
                    errors.project_category ? "border-danger" : ""
                  }`}
                >
                  <option value="">Select Your Project Category</option>
                  <option value="agriculture">Agriculture</option>
                  <option value="education">Education</option>
                  <option value="healthy">Healthy</option>
                  <option value="hospitality">Hospitality</option>
                  <option value="ict">ICT</option>
                  <option value="manufacuring">Manufacturing</option>
                  <option value="mining">Mining</option>
                  <option value="real_estate">Real Estate</option>
                  <option value="transport">Transport</option>
                  <option value="energy">Energy</option>
                  <option value="water and sanitation">
                    Water And Sanitation
                  </option>
                  <option value="food process">
                    Food Processing And Packaging
                  </option>
                </select>
                {errors.project_category && (
                  <p className="text-danger">Project Category is required.</p>
                )}
              </div>
              <div className="col-md-6">
                <FormInput<DataFormFields>
                  id="project_days"
                  type="date"
                  name="project_days"
                  disabled={isLoading}
                  label="Project Days"
                  placeholder="Number of Days (Max 35 days)"
                  className="mb-2"
                  max={32}
                  register={register}
                  rules={{ required: "Project Days is required." }}
                  errors={errors}
                />
              </div>

              <div className="col-md-12">
                <FormTextarea<DataFormFields>
                  id="project_description"
                  name="project_description"
                  disabled={isLoading}
                  minLength={200}
                  label="description"
                  placeholder="Say something about this project"
                  rows={5}
                  cols={12}
                  className="form-control"
                  register={register}
                  rules={{ required: "Project Description is required." }}
                  errors={errors}
                />
              </div>
            </div>

            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button disabled={isLoading} variant="primary" type="submit">
                {isLoading && <Spinner size="sm" />}{" "}
                <i className="fa fa-plus"></i> Create
              </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddProject;
